import {
  ApiSharedStyleProperties,
  ApiSharedTextStyleProperties,
} from '@overlay-plugin/types/lib/ApiType';
import { SketchGeneralLayer } from '@overlay-plugin/types/lib/SketchType';

export const addSharedTextStyleProperties = (
  sketchLayer: SketchGeneralLayer,
): ApiSharedTextStyleProperties => {
  let sharedTextStyleProperties: ApiSharedTextStyleProperties = {
    typographyStyles: [],
  };

  if (!sketchLayer.textSharedStyle) {
    return sharedTextStyleProperties;
  }

  const textSharedStyle = sketchLayer.textSharedStyle;
  sharedTextStyleProperties.typographyStyles.push({
    id: textSharedStyle.id,
    name: textSharedStyle.name,
    size: textSharedStyle.size,
    family: textSharedStyle.family,
    weight: textSharedStyle.weight,
    lineHeight: textSharedStyle.lineHeight,
  });

  return sharedTextStyleProperties;
};

export const addSharedColorStyleProperties = (
  sketchLayer: SketchGeneralLayer,
): ApiSharedStyleProperties => {
  let sharedStyleProperties: ApiSharedStyleProperties = {
    colorStyles: [],
  };

  if (sketchLayer.colorSharedStyles.length === 0) {
    return sharedStyleProperties;
  }

  for (const colorStyle of sketchLayer.colorSharedStyles) {
    sharedStyleProperties.colorStyles.push({
      id: colorStyle.id,
      name: colorStyle.name,
      value: colorStyle.value,
    });
  }

  return sharedStyleProperties;
};
