import { ApiLayer } from '@overlay-plugin/types/lib/ApiType';
import { ApiWarningTypeEnum, Warning } from 'types/warning';

export const validateAPILayer = (layer: ApiLayer) => {
  let warnings: Warning[] = [];

  const isInvalidBorder =
    layer.style.borderPosition === 'CENTER' || layer.style.borderPosition === 'OUTSIDE';

  if (isInvalidBorder) {
    warnings.push({
      id: layer.sketchId,
      data: [layer.sketchId],
      layers: [],
      message: ApiWarningTypeEnum.INVALID_BORDER,
    });
  }

  if (layer.children && layer.children.length > 0) {
    const childrenErrors = layer.children.reduce((errors: any[], child) => {
      errors = [...errors, ...validateAPILayer(child)];

      return errors;
    }, []);

    warnings = [...warnings, ...childrenErrors];
  }

  return warnings;
};
