import { ApiLayer } from '@overlay-plugin/types/lib/ApiType';

// We recompute api layer position if border are placed
export const recomputeChildrenPositionAccordingToBorder = (layer: ApiLayer): void => {
  if (!layer.children) return;

  if (layer.children.length === 0) return;

  const borderTopThickness = layer.style.borderTopThickness ? layer.style.borderTopThickness : 0;
  const borderLeftThickness = layer.style.borderLeftThickness ? layer.style.borderLeftThickness : 0;
  const borderBottomThickness = layer.style.borderBottomThickness
    ? layer.style.borderBottomThickness
    : 0;
  const borderRightThickness = layer.style.borderRightThickness
    ? layer.style.borderRightThickness
    : 0;

  layer.children.forEach((child: ApiLayer) => {
    const cropAdjustment = {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    };

    if (borderTopThickness !== 0) {
      if (0 <= child.style.top && child.style.top < borderTopThickness) {
        const topCrop = borderTopThickness - child.style.top;
        child.style.height -= topCrop;
        child.style.top = 0;
        cropAdjustment.top = topCrop;
      } else {
        child.style.top -= borderTopThickness;
      }
    }
    if (borderLeftThickness !== 0) {
      if (0 <= child.style.left && child.style.left < borderLeftThickness) {
        const leftCrop = borderLeftThickness - child.style.left;
        child.style.width -= leftCrop;
        child.style.left = 0;
        cropAdjustment.left = leftCrop;
      } else {
        child.style.left -= borderLeftThickness;
      }
    }
    if (borderBottomThickness !== 0) {
      const childBottomPosition = child.style.top + child.style.height;
      if (
        layer.style.height < childBottomPosition &&
        childBottomPosition <= layer.style.height + borderBottomThickness
      ) {
        const bottomCrop = childBottomPosition - layer.style.height;
        child.style.height -= bottomCrop;
        cropAdjustment.bottom = bottomCrop;
      }
    }
    if (borderRightThickness !== 0) {
      const childRightPosition = child.style.left + child.style.width;
      if (
        layer.style.width < childRightPosition &&
        childRightPosition <= layer.style.width + borderRightThickness
      ) {
        const rightCrop = childRightPosition - layer.style.width;
        child.style.width -= rightCrop;
        cropAdjustment.right = rightCrop;
      }
    }

    if (
      cropAdjustment.top !== 0 ||
      cropAdjustment.right !== 0 ||
      cropAdjustment.bottom !== 0 ||
      cropAdjustment.left !== 0
    ) {
      recomputeChildrenPositionAccordingToCropAdjustment(child, cropAdjustment);
    }
  });

  return;
};

export const recomputeChildrenPositionAccordingToCropAdjustment = (
  layer: ApiLayer,
  cropAdjustment: any,
): void => {
  if (!layer.children) return;

  if (layer.children.length === 0) return;

  layer.children.forEach((child: ApiLayer) => {
    if (cropAdjustment.top !== 0) {
      if (0 <= child.style.top && child.style.top < cropAdjustment.top) {
        child.style.height -= cropAdjustment.top;
        child.style.top = 0;
      } else {
        child.style.top -= cropAdjustment.top;
      }
    }
    if (cropAdjustment.left !== 0) {
      if (0 <= child.style.left && child.style.left < cropAdjustment.left) {
        child.style.width -= cropAdjustment.left;
        child.style.left = 0;
      } else {
        child.style.left -= cropAdjustment.left;
      }
    }
    if (cropAdjustment.bottom !== 0) {
      const childBottomPosition = child.style.top + child.style.height;
      if (
        layer.style.height < childBottomPosition &&
        childBottomPosition <= layer.style.height + cropAdjustment.bottom
      ) {
        child.style.height -= cropAdjustment.bottom;
      }
    }
    if (cropAdjustment.right !== 0) {
      const childRightPosition = child.style.left + child.style.width;
      if (
        layer.style.width < childRightPosition &&
        childRightPosition <= layer.style.width + cropAdjustment.right
      ) {
        child.style.width -= cropAdjustment.right;
      }
    }
    if (
      cropAdjustment.top !== 0 ||
      cropAdjustment.right !== 0 ||
      cropAdjustment.bottom !== 0 ||
      cropAdjustment.left !== 0
    ) {
      recomputeChildrenPositionAccordingToCropAdjustment(child, cropAdjustment);
    }
  });

  return;
};

export const recomputePaddingAccordingToBorder = (layer: ApiLayer): void => {
  if (!layer.children) return;

  if (layer.children.length === 0) return;

  const borderTopThickness = layer.style.borderTopThickness ? layer.style.borderTopThickness : 0;
  const borderLeftThickness = layer.style.borderLeftThickness ? layer.style.borderLeftThickness : 0;
  const borderBottomThickness = layer.style.borderBottomThickness
    ? layer.style.borderBottomThickness
    : 0;
  const borderRightThickness = layer.style.borderRightThickness
    ? layer.style.borderRightThickness
    : 0;

  if (layer.style.paddingTop) {
    layer.style.paddingTop -= borderTopThickness;
  }

  if (layer.style.paddingLeft) {
    layer.style.paddingLeft -= borderLeftThickness;
  }

  if (layer.style.paddingRight) {
    layer.style.paddingRight -= borderRightThickness;
  }

  if (layer.style.paddingBottom) {
    layer.style.paddingBottom -= borderBottomThickness;
  }

  return;
};
