import superagent from 'superagent';
import {
  ApiComponentSet,
  ApiDesignToolEnum,
  ApiVariableColorType,
  ApiVariableTypographyType,
} from '@overlay-plugin/types/lib/ApiType';
import { TargetTechnologyEnum, UIFrameworkEnum } from 'modules/projects/types';
import { Base64Image, DesignToolEnum } from '@overlay-plugin/types/lib/NativeClientInterface';

let urlBase = 'http://localhost:8080/api';

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_API_URL)
  urlBase = process.env.REACT_APP_API_URL;

export const getMyTeams = (token: string) =>
  superagent
    .get(`${urlBase}/teams`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const getProjects = (token: string) =>
  superagent
    .get(`${urlBase}/projects`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const createProject = (
  token: string,
  name: string,
  targetTechnology: TargetTechnologyEnum,
  uiFramework: UIFrameworkEnum,
  designTool: DesignToolEnum,
  teamUuid: string,
) =>
  superagent
    .post(`${urlBase}/projects`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      name,
      targetTechnology,
      uiFramework,
      designTool,
      teamUuid,
    });

export const uploadComponent = (
  componentSet: ApiComponentSet,
  designToolId: string,
  projectUuid: string,
  name: string,
  designTool: ApiDesignToolEnum,
  token: string,
  previewImage: string,
) =>
  superagent
    .post(`${urlBase}/compile/new`)
    .send({
      projectUuid,
      ...componentSet,
      name,
      designToolId,
      designTool,
      previewImage,
    })
    .accept('application/json')
    .set('Authorization', `Bearer ${token}`);

export const getComponentByDesignToolIdAndProjectId = (
  token: string,
  designToolId: string,
  projectUuid: string,
) =>
  superagent
    .get(`${urlBase}/component-sets`)
    .query({ designToolId })
    .query({ projectId: projectUuid })
    .set('Authorization', `Bearer ${token}`)
    .accept('application/json');

export const getUserInfo = (token: string) =>
  superagent
    .get(`${urlBase}/me`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const finishOnBoarding = (token: string) =>
  superagent
    .post(`${urlBase}/me/finish-on-boarding`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const exportVariant = (token: string) =>
  superagent
    .post(`${urlBase}/me/export-variant`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const getPlayground = (token: string) =>
  superagent
    .post(`${urlBase}/me/get-playground`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const openPlugin = (token: string) =>
  superagent
    .post(`${urlBase}/me/open-plugin`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const postValidateStylesheet = (
  token: string,
  projectUuid: string,
  componentSet: ApiComponentSet,
) =>
  superagent
    .post(`${urlBase}/validate-stylesheet`)
    .send({
      projectUuid,
      ...componentSet,
    })
    .accept('application/json')
    .set('Authorization', `Bearer ${token}`);

export const postValidateComponentSet = (
  token: string,
  projectUuid: string,
  componentSet: ApiComponentSet,
) =>
  superagent
    .post(`${urlBase}/validate-component`)
    .send({
      projectUuid,
      ...componentSet,
    })
    .accept('application/json')
    .set('Authorization', `Bearer ${token}`);

export const updateComponent = (
  componentUuid: string,
  componentSet: ApiComponentSet,
  projectUuid: string,
  name: string,
  designTool: ApiDesignToolEnum,
  token: string,
  componentPreview: string,
) =>
  superagent
    .put(`${urlBase}/compile/${componentUuid}`)
    .send({
      ...componentSet,
      name,
      designTool,
      projectUuid,
      previewImage: componentPreview,
    })
    .accept('application/json')
    .set('Authorization', `Bearer ${token}`);

export const getProjectStylesheet = (token: string, id: string) =>
  superagent
    .get(`${urlBase}/projects/${id}/stylesheet`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`);

export const updateStylesheet = (
  projectUuid: string,
  token: string,
  { colors, typos }: { colors: ApiVariableColorType[]; typos: ApiVariableTypographyType[] },
) =>
  superagent
    .put(`${urlBase}/projects/${projectUuid}/stylesheet`)
    .set('Accept', 'application/json')
    .set('Authorization', `Bearer ${token}`)
    .send({
      stylesheet: {
        typographies: typos.map((typo: ApiVariableTypographyType) => ({
          ...typo,
        })),
        colors: colors.map((color: ApiVariableColorType) => ({
          ...color,
        })),
      },
      compile: false,
    });

export const refreshToken = (refreshToken: string) =>
  superagent.post(`${urlBase}/token/refresh`).send(`refresh_token=${refreshToken}`);

export const startPairing = () => superagent.post(`${urlBase}/start/pair`);

export const checkPairing = (pairingToken: string) =>
  superagent.get(`${urlBase}/check/pair/${pairingToken}`);

export const uploadAssets = (
  assets: Record<string, Base64Image> | Record<string, null>,
  projectUuid: string,
  token: string,
) =>
  superagent
    .post(`${urlBase}/assets/${projectUuid}/upload-assets`)
    .set('Authorization', `Bearer ${token}`)
    .send(assets);

export const uploadPreview = (preview: Base64Image, projectUuid: string, token: string) =>
  superagent
    .post(`${urlBase}/assets/${projectUuid}/upload-preview`)
    .set('Authorization', `Bearer ${token}`)
    .send(preview);
