import { INativeClientInterface } from 'services/Interfaces/NativeClientInterface';
import {
  NativeClientMessageTypeEnum,
  ExportCommand,
  PluginImageResponse,
} from '@overlay-plugin/types/lib/NativeClientInterface';

const selectLayer = (layerId: string) => {
  window.parent.postMessage(
    {
      type: NativeClientMessageTypeEnum.SELECT_LAYER_REQUEST,
      layerId,
    },
    '*',
  );
};

const changeLayerName = (layerId: string, newName: string) => {
  window.parent.postMessage(
    {
      type: NativeClientMessageTypeEnum.CHANGE_LAYER_NAME_REQUEST,
      layerId,
      newName,
    },
    '*',
  );
};

const makeExportableLayer = (layerId: string) => {
  // Don't do nothing as figma don't explicitly show exportable layers
  window.parent.postMessage(
    {
      type: NativeClientMessageTypeEnum.MAKE_EXPORTABLE_LAYER_REQUEST,
      layerId,
    },
    '*',
  );

  return new Promise(resolve => {
    const makeExportableLayerDone = (event: any) => {
      if (event.data.type === NativeClientMessageTypeEnum.MAKE_EXPORTABLE_LAYER_RESPONSE)
        resolve({ ...event.data.data });
    };

    window.addEventListener('message', makeExportableLayerDone, false);
  });
};

const generateTemplateComponent = () => {
  window.parent.postMessage(
    {
      type: NativeClientMessageTypeEnum.GENERATE_TEMPLATE_REQUEST,
    },
    '*',
  );

  return new Promise(resolve => {
    const componentGeneratedDone = (event: any) => {
      if (event.data.type === NativeClientMessageTypeEnum.GENERATE_TEMPLATE_RESPONSE)
        resolve({ ...event.data.data });
    };

    window.addEventListener('message', componentGeneratedDone, false);
  });
};

const openExternalLink = (link: string) => window.open(link, '_blank');

const closePlugin = () => {
  window.parent.postMessage(
    {
      type: NativeClientMessageTypeEnum.CLOSE_PLUGIN_REQUEST,
    },
    '*',
  );
};

const getBase64Images = (commands: ExportCommand[]) => {
  window.parent.postMessage(
    {
      type: NativeClientMessageTypeEnum.GET_BASE_64_IMAGES_REQUEST,
      commands,
    },
    '*',
  );

  return new Promise(resolve => {
    const versionSentByDesignerTool = (event: any) => {
      if (event.data.type === NativeClientMessageTypeEnum.GET_BASE_64_IMAGES_RESPONSE)
        resolve({ ...event.data.data.imageMap });
    };

    window.addEventListener('message', versionSentByDesignerTool, false);
  }) as Promise<PluginImageResponse[]>;
};

const requestExportComponent = (componentId: string) =>
  window.parent.postMessage(
    {
      type: NativeClientMessageTypeEnum.EXPORT_COMPONENT_REQUEST,
      componentId,
    },
    '*',
  );

const requestTranslateLayer = (layerId: string) =>
  window.parent.postMessage(
    {
      type: NativeClientMessageTypeEnum.TRANSLATE_LAYER_REQUEST,
      layerId,
    },
    '*',
  );

const figmaClient: INativeClientInterface = {
  selectLayer,
  closePlugin,
  openExternalLink,
  requestTranslateLayer,
  requestExportComponent,
  getBase64Images,
  changeLayerName,
  generateTemplateComponent,
  makeExportableLayer,
};

export default figmaClient;
