import { NormalizedApiLayer } from '@overlay-plugin/types/lib/ApiType';

export enum ApiWarningTypeEnum {
  DEFAULT_NAME = 'WARNING.DEFAULT_NAME',
  INVALID_BORDER = 'ERROR.INVALID_BORDER',
  MISSING_DEPENDENCY = 'ERROR.MISSING_DEPENDENCY',
}

export type Warning = {
  id: string;
  data: string[];
  layers: NormalizedApiLayer[];
  message: ApiWarningTypeEnum;
};

export type WarningCollection = {
  [type: string]: Warning[];
};
