import {
  ApiValidationErrorColor,
  ApiValidationErrorTypography,
} from '@overlay-plugin/types/lib/ApiType';

export enum FrontVariableErrorEnum {
  NOT_MODIFIED = 'NOT_MODIFIED',
  SAVED = 'SAVED',
  IGNORED = 'IGNORED',
  VALID = 'VALID',
  DUPLICATE_NAME_ERROR = 'DUPLICATE_NAME_ERROR',
  INVALID_NAME_ERROR = 'INVALID_NAME_ERROR',
}

export enum FrontVariableTypeErrorEnum {
  TYPO = 'TYPOGRAPHY',
  COLOR = 'COLOR',
}

export interface IFrontTypographyValidationError extends ApiValidationErrorTypography {
  validationState: FrontVariableErrorEnum;
}

export interface IFrontColorValidationError extends ApiValidationErrorColor {
  validationState: FrontVariableErrorEnum;
}

export type StylesheetErrorStateType = {
  colors: Record<string, IFrontColorValidationError>;
  typos: Record<string, IFrontTypographyValidationError>;
  variableNames: string[];
};
