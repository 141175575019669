import { SketchShape } from '@overlay-plugin/types/lib/SketchType';
import { ApiLayer } from '@overlay-plugin/types/lib/ApiType';
import { createApiLayer } from 'services/Sketch/Mapper/sketchMapper';
import {
  addOpacityProperties,
  addBorderProperties,
  addBorderRadiusProperties,
  addShadowProperties,
  addBlurProperties,
  addBackgroundsProperties,
} from 'services/Sketch/Mapper/styleMapper';
import { addSharedColorStyleProperties } from 'services/Sketch/Mapper/sharedStyleMapper';

export const mapSketchContentToAPILayer = (sketchLayer: SketchShape): ApiLayer => {
  let layer: ApiLayer = createApiLayer(sketchLayer);

  layer = {
    ...layer,
    ...addSharedColorStyleProperties(sketchLayer),
  };

  layer.style = {
    ...layer.style,
    ...addOpacityProperties(sketchLayer.style),
    ...addBackgroundsProperties(sketchLayer.style, sketchLayer.frame),
    ...addBorderProperties(sketchLayer.style, layer.style),
    ...addBorderRadiusProperties(sketchLayer.style),
    ...addShadowProperties(sketchLayer.style),
    ...addBlurProperties(sketchLayer.style),
  };

  if (sketchLayer.hasClippingMask) {
    layer.style.backgrounds = [];
  }

  return layer;
};
