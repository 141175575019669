import { connect } from 'react-redux';
import Preview from './Preview.component';
import { OverlayPluginStateType } from 'redux/type';
import { selectSelectedProject } from 'modules/projects/projects';
import { selectComponentUuidInDb } from 'modules/component';
import { openExternalLinkCreator, selectDesignTool } from 'modules/abstractDesignTool';

const mapStateToProps = (state: OverlayPluginStateType) => ({
  selectedProject: selectSelectedProject(state),
  componentId: selectComponentUuidInDb(state),
  designTool: selectDesignTool(state),
});

const mapDispatchToProps = {
  openExternalLink: openExternalLinkCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
