import React from 'react';
import styles from './Playground.module.scss';
import { ReactComponent as CommunityFile } from 'assets/community-file.svg';
import Button from 'components/Atoms/Button';
import { DesignToolEnum } from '@overlay-plugin/types/lib/NativeClientInterface';

interface IProps {
  openExternalLink: (link: string) => void;
  designTool: DesignToolEnum;
}

const Playground: React.FunctionComponent<IProps> = ({ openExternalLink, designTool }) => {
  const openFigmaPlaygroundFile = () => {
    openExternalLink('https://www.figma.com/community/file/921066411285098081');
  };

  const openSketchPlaygroundFile = () => {
    openExternalLink('https://overlay-tech.com/onboarding');
  };

  return (
    <div className={styles.playgroundContainer}>
      <CommunityFile className={styles.overlayLogo} />
      <div className={styles.title}>See more perfect components</div>
      <div className={styles.description}>
        Not sure you can get to a perfect component on your own ? We made a Playground file with a
        bunch of “Ready for Overlay” components !
        <br />
        <br />
        Even real inputs components 😍
      </div>
      <div className={styles.slideBottomContainer}>
        <div className={styles.slideBottomInnerContainer}>
          {designTool === DesignToolEnum.SKETCH && (
            <Button onClick={openSketchPlaygroundFile}>Get the playground file</Button>
          )}
          {designTool === DesignToolEnum.FIGMA && (
            <Button onClick={openFigmaPlaygroundFile}>Get the playground file</Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Playground;
