"use strict";

exports.__esModule = true;
exports.hasConstraints = exports.hasEffects = exports.hasBorders = exports.hasOpacity = exports.isBlur = exports.isShadow = exports.hasPaint = exports.isLineHeightValue = exports.isAnImagePaint = exports.isAnSolidPaint = exports.isTextCase = exports.isLetterSpacing = exports.isFontSize = exports.isFontName = exports.isAnGradientPaint = exports.isAGeometryMixin = exports.isAFlexContainer = exports.isAComponentSetNode = exports.isAFrame = exports.isAContainer = exports.isEllipse = exports.isRectangleNode = exports.isFrameNode = exports.isLineNode = exports.isBooleanOperationNode = exports.isPolygonNode = exports.isStarNode = exports.isVectorNode = exports.isSliceNode = exports.isGroupNode = exports.isComponentInstanceNode = exports.isComponentNode = void 0; // Type predicates

exports.isComponentNode = function (node) {
  return node.type === 'COMPONENT';
};

exports.isComponentInstanceNode = function (node) {
  return node.type === 'INSTANCE';
};

exports.isGroupNode = function (node) {
  return node.type === 'GROUP';
};

exports.isSliceNode = function (node) {
  return node.type === 'SLICE';
};

exports.isVectorNode = function (node) {
  return node.type === 'VECTOR';
};

exports.isStarNode = function (node) {
  return node.type === 'STAR';
};

exports.isPolygonNode = function (node) {
  return node.type === 'POLYGON';
};

exports.isBooleanOperationNode = function (node) {
  return node.type === 'BOOLEAN_OPERATION';
};

exports.isLineNode = function (node) {
  return node.type === 'LINE';
};

exports.isFrameNode = function (node) {
  return node.type === 'FRAME' || node.type === 'GROUP';
};

exports.isRectangleNode = function (node) {
  return node.type === 'RECTANGLE';
};

exports.isEllipse = function (node) {
  return node.type === 'ELLIPSE';
};

exports.isAContainer = function (node) {
  return exports.isAFrame(node) || exports.isComponentNode(node) || node.type === 'GROUP';
};

exports.isAFrame = function (node) {
  return node.type === 'FRAME';
};

exports.isAComponentSetNode = function (node) {
  return node.type === 'COMPONENT_SET';
};

exports.isAFlexContainer = function (figmaNode) {
  return !exports.isGroupNode(figmaNode) && figmaNode.layoutMode !== 'NONE';
};

exports.isAGeometryMixin = function (component) {
  return component.type !== 'SLICE';
};

exports.isAnGradientPaint = function (paint) {
  return 'GRADIENT_LINEAR' === paint.type && !!paint.visible;
};

exports.isFontName = function (layerFontName) {
  return layerFontName.family !== undefined;
};

exports.isFontSize = function (layerFontSize) {
  return typeof layerFontSize === 'number';
};

exports.isLetterSpacing = function (layerLetterSpacing) {
  return layerLetterSpacing.value !== undefined;
};

exports.isTextCase = function (layerTextCase) {
  return layerTextCase !== undefined;
};

exports.isAnSolidPaint = function (paint) {
  return paint.type === 'SOLID' && !!paint.visible;
};

exports.isAnImagePaint = function (paint) {
  return paint.type === 'IMAGE' && !!paint.visible;
};

exports.isLineHeightValue = function (lineHeight) {
  return lineHeight.unit === 'PERCENT' || lineHeight.unit === 'PIXELS';
};

exports.hasPaint = function (componentFills) {
  return Array.isArray(componentFills) && componentFills.length > 0;
};

exports.isShadow = function (effect) {
  return effect.type === 'INNER_SHADOW' || effect.type === 'DROP_SHADOW';
};

exports.isBlur = function (effect) {
  return effect.type === 'LAYER_BLUR' || effect.type === 'BACKGROUND_BLUR';
};

exports.hasOpacity = function (node) {
  return node.type !== 'SLICE';
};

exports.hasBorders = function (node) {
  return node.type !== 'SLICE' && node.type !== 'INSTANCE' && node.type !== 'GROUP';
};

exports.hasEffects = function (node) {
  return node.type !== 'SLICE';
};

exports.hasConstraints = function (node) {
  return node.type !== 'SLICE' && node.type !== 'BOOLEAN_OPERATION' && node.type !== 'GROUP';
};