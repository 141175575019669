import { fork } from 'redux-saga/effects';
import { watchComponentSagas } from 'modules/component';
import { watchProjectSagas } from 'modules/projects';
import { watchSelectedComponentSagas } from 'modules/componentSet';
import { watchAuthenticationSagas } from 'modules/authentication';
import { watchStylesheetSagas } from 'modules/stylesheetError';
import { watchDesignToolClientSagas } from 'modules/abstractDesignTool';
import { watchTeamSagas } from 'modules/teams';

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield fork(watchComponentSagas);
  yield fork(watchStylesheetSagas);
  yield fork(watchSelectedComponentSagas);
  yield fork(watchAuthenticationSagas);
  yield fork(watchDesignToolClientSagas);
  yield fork(watchProjectSagas);
  yield fork(watchTeamSagas);
}
