import React from 'react';
import { Route, Redirect } from 'react-router-dom';

type Props = {
  isAuthenticated: boolean;
  component: React.Component<any>;
};

class PrivateRoute extends React.Component<Props> {
  render() {
    const { component: Component, isAuthenticated, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            // @ts-ignore
            <Component />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
}

export default PrivateRoute;
