// according to https://developer.mozilla.org/fr/docs/Web/CSS/font-weigh
import { ApiTextTransformEnum } from '@overlay-plugin/types/lib/ApiType';
import { FigmaTextCase } from '@overlay-plugin/types/lib/FigmaType';

export const FONT_STYLE_ITALIC = 'italic';

export const TEXT_CASE: Record<FigmaTextCase, ApiTextTransformEnum | null> = {
  ORIGINAL: null, // No property to add
  TITLE: null, // Property not supported for now
  UPPER: ApiTextTransformEnum.UPPERCASE,
  LOWER: ApiTextTransformEnum.LOWERCASE,
};
