import { INativeClientInterface } from 'services/Interfaces/NativeClientInterface';
import {
  NativeClientMessageTypeEnum,
  ExportCommand,
  PluginImageResponse,
} from '@overlay-plugin/types/lib/NativeClientInterface';
import { SketchGeneralLayer } from '@overlay-plugin/types/lib/SketchType';

const selectLayer = (layerId: string) => {
  window.parent.postMessage(NativeClientMessageTypeEnum.SELECT_LAYER_REQUEST, layerId);
};

const makeExportableLayer = (layerId: string) => {
  window.parent.postMessage(NativeClientMessageTypeEnum.MAKE_EXPORTABLE_LAYER_REQUEST, layerId);

  return new Promise(resolve => {
    // @ts-ignore This function is called in the sketch side
    window.makeExportableLayerResponse = (layer: SketchGeneralLayer) => {
      resolve({ layer });
    };
  });
};

const generateTemplateComponent = () => {
  window.parent.postMessage(NativeClientMessageTypeEnum.GENERATE_TEMPLATE_REQUEST, '*');

  return new Promise(resolve => {
    // @ts-ignore This function is call in the sketch side
    window.generateTemplateComponentResponse = (componentId: string) => {
      resolve({ componentId });
    };
  });
};

const changeLayerName = (sketchId: string, newName: string) => {
  window.parent.postMessage(
    NativeClientMessageTypeEnum.CHANGE_LAYER_NAME_REQUEST,
    JSON.stringify({
      sketchId,
      newName,
    }),
  );
};

const openExternalLink = (link: string) => {
  window.parent.postMessage('external-link-clicked', link);
  window.parent.postMessage(NativeClientMessageTypeEnum.OPEN_LINK_REQUEST, link);
};

const closePlugin = () =>
  window.parent.postMessage(NativeClientMessageTypeEnum.CLOSE_PLUGIN_REQUEST, '*');

const getBase64Images = (commands: ExportCommand[]) => {
  window.parent.postMessage(
    NativeClientMessageTypeEnum.GET_BASE_64_IMAGES_REQUEST,
    JSON.stringify(commands),
  );

  return new Promise(resolve => {
    // @ts-ignore This function is call in the sketch side
    window.sketchImageSources = (base64Images: PluginImageResponse[]) => {
      resolve(base64Images);
    };
  }) as Promise<PluginImageResponse[]>;
};

const requestExportComponent = (componentId: string) =>
  window.parent.postMessage(NativeClientMessageTypeEnum.EXPORT_COMPONENT_REQUEST, componentId);

const requestTranslateLayer = (layerId: string) => {
  window.parent.postMessage(NativeClientMessageTypeEnum.TRANSLATE_LAYER_REQUEST, layerId);

  return new Promise(resolve => {
    // @ts-ignore This function is call in the sketch side
    window.translateLayerResponse = (sketchLayer: SketchGeneralLayer) => {
      resolve({ sketchLayer });
    };
  });
};

const sketchClient: INativeClientInterface = {
  selectLayer,
  closePlugin,
  openExternalLink,
  requestTranslateLayer,
  requestExportComponent,
  getBase64Images,
  changeLayerName,
  generateTemplateComponent,
  makeExportableLayer,
};

export default sketchClient;
