import { optimize as iconOptimize } from './iconOptimizer';
import { optimize as componentExportableOptimize } from './componentExportableOptimizer';
import { optimize as maskOptimize } from './maskOptimizer';
import { FigmaNode } from '@overlay-plugin/types/lib/FigmaType';

export const optimize = (figmaNode: FigmaNode): FigmaNode => {
  figmaNode = maskOptimize(figmaNode);
  figmaNode = componentExportableOptimize(figmaNode);
  return iconOptimize(figmaNode);
};
