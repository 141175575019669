import {
  SketchConstraints,
  SketchExportFormat,
  SketchFrame,
  SketchImage,
  SketchStyle,
  SketchTransform,
  SketchTypeEnum,
} from '@overlay-plugin/types/lib/SketchType';

export const createSketchImage = (
  id: string,
  name: string,
  frame: SketchFrame,
  constraints: SketchConstraints,
  style: SketchStyle,
  exportFormats: SketchExportFormat[],
  transform: SketchTransform,
  assetId?: number,
): SketchImage => ({
  id,
  name,
  type: SketchTypeEnum.IMAGE,
  colorSharedStyles: [],
  textSharedStyle: null,
  exportFormats,
  constraints,
  frame,
  style,
  assetId,
  transform,
});
