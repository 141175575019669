import { ApiLayer, ApiLayerTypeEnum, ApiStyle } from '@overlay-plugin/types/lib/ApiType';

export const borderType = {
  BORDER_LEFT: 'border-left',
  BORDER_RIGHT: 'border-right',
  BORDER_TOP: 'border-top',
  BORDER_BOTTOM: 'border-bottom',
};

export const isSingleBorder = (layer: ApiLayer, parentLayer: ApiLayer): string | null => {
  if (layer.type !== ApiLayerTypeEnum.SHAPE) return null;

  const layerStyle = layer.style;
  const parentStyle = parentLayer.style;

  const maxBorderSize = 5;
  const isLayerWidthSmallerThanMaxBorderSize = layerStyle.width <= maxBorderSize;
  const isLayerHeightSmallerThanMaxBorderSize = layerStyle.height <= maxBorderSize;

  const hasLayerSameTopAsHisParentLayer = 0 === layerStyle.top;
  const hasLayerSameLeftAsHisParentLayer = 0 === layerStyle.left;
  const hasLayerSameHeightAsHisParentLayer = layerStyle.height === parentStyle.height;
  const hasLayerSameWidthAsHisParentLayer = layerStyle.width === parentStyle.width;

  // Avoid to merge background
  if (
    hasLayerSameTopAsHisParentLayer &&
    hasLayerSameLeftAsHisParentLayer &&
    hasLayerSameHeightAsHisParentLayer &&
    hasLayerSameWidthAsHisParentLayer
  ) {
    return null;
  }

  if (
    hasLayerSameTopAsHisParentLayer &&
    hasLayerSameLeftAsHisParentLayer &&
    hasLayerSameHeightAsHisParentLayer &&
    isLayerWidthSmallerThanMaxBorderSize
  ) {
    return borderType.BORDER_LEFT;
  }

  const hasLayerSameRightAsHisParentLayer =
    layerStyle.left + layerStyle.width === parentStyle.width;

  if (
    hasLayerSameTopAsHisParentLayer &&
    hasLayerSameRightAsHisParentLayer &&
    hasLayerSameHeightAsHisParentLayer &&
    isLayerWidthSmallerThanMaxBorderSize
  ) {
    return borderType.BORDER_RIGHT;
  }

  if (
    hasLayerSameTopAsHisParentLayer &&
    hasLayerSameLeftAsHisParentLayer &&
    hasLayerSameWidthAsHisParentLayer &&
    isLayerHeightSmallerThanMaxBorderSize
  ) {
    return borderType.BORDER_TOP;
  }

  const hasLayerSameBottomAsHisParentLayer =
    layerStyle.top + layerStyle.height === parentStyle.height;

  if (
    hasLayerSameBottomAsHisParentLayer &&
    hasLayerSameLeftAsHisParentLayer &&
    hasLayerSameWidthAsHisParentLayer &&
    isLayerHeightSmallerThanMaxBorderSize
  ) {
    return borderType.BORDER_BOTTOM;
  }

  return null;
};

export const setSingleBorder = (
  childStyle: ApiStyle,
  parentStyle: ApiLayer,
  borderToSet: string,
) => {
  let widthToRemove = 0;
  let heightToRemove = 0;

  switch (borderToSet) {
    case borderType.BORDER_LEFT:
      parentStyle.style.borderLeftColor =
        childStyle.backgrounds &&
        childStyle.backgrounds.length > 0 &&
        childStyle.backgrounds[0].type === 'color'
          ? childStyle.backgrounds[0].color
          : undefined;
      parentStyle.style.borderLeftThickness = Math.round(childStyle.width);
      widthToRemove += Math.round(childStyle.width);
      break;

    case borderType.BORDER_RIGHT:
      parentStyle.style.borderRightColor =
        childStyle.backgrounds &&
        childStyle.backgrounds.length > 0 &&
        childStyle.backgrounds[0].type === 'color'
          ? childStyle.backgrounds[0].color
          : undefined;
      parentStyle.style.borderRightThickness = Math.round(childStyle.width);
      widthToRemove += Math.round(childStyle.width);
      break;

    case borderType.BORDER_TOP:
      parentStyle.style.borderTopColor =
        childStyle.backgrounds &&
        childStyle.backgrounds.length > 0 &&
        childStyle.backgrounds[0].type === 'color'
          ? childStyle.backgrounds[0].color
          : undefined;
      parentStyle.style.borderTopThickness = Math.round(childStyle.height);
      heightToRemove += Math.round(childStyle.height);
      break;

    case borderType.BORDER_BOTTOM:
      parentStyle.style.borderBottomColor =
        childStyle.backgrounds &&
        childStyle.backgrounds.length > 0 &&
        childStyle.backgrounds[0].type === 'color'
          ? childStyle.backgrounds[0].color
          : undefined;
      parentStyle.style.borderBottomThickness = Math.round(childStyle.height);
      heightToRemove += Math.round(childStyle.height);
      break;

    default:
      break;
  }

  return {
    widthToRemove,
    heightToRemove,
  };
};
