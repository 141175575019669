import { Reducer } from 'redux';
import { OverlayPluginStateType } from 'redux/type';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';

export type PairingStateType = {
  pairingError: string | null;
  pairingUrl: string | null;
};

export const loginErrorsMapper: Record<string, string> = {
  default: 'Unknown error. Try later.',
  timeout: 'Your pairing request has expired. Try again.',
};

const initialState: PairingStateType = {
  pairingError: null,
  pairingUrl: null,
};

// Actions Creators
export const pairingErrorCreator = createStandardAction('PAIRING/PAIRING.ERROR')<{
  pairingError: string;
}>();

export const cleanPairingErrorRequestCreator = createStandardAction('PAIRING/CLEAN_ERROR.REQUEST')<{
  pairingUrl: string;
}>();

declare type PairingActions = ActionType<
  typeof pairingErrorCreator | typeof cleanPairingErrorRequestCreator
>;

// Selectors
export const selectPairingError = (store: OverlayPluginStateType) => store.pairing.pairingError;
export const selectPairingUrl = (store: OverlayPluginStateType) => store.pairing.pairingUrl;

// Reducer
export const pairingReducer: Reducer<PairingStateType, PairingActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getType(pairingErrorCreator):
      return {
        ...state,
        pairingError: action.payload.pairingError,
        pairingUrl: null,
      };
    case getType(cleanPairingErrorRequestCreator):
      return {
        ...state,
        pairingError: '',
        pairingUrl: action.payload.pairingUrl,
      };
    default:
      return state;
  }
};
