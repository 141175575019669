import {
  FigmaNode,
  isComponentInstanceNode,
  isComponentNode,
  isFrameNode,
  isGroupNode,
  isSliceNode,
} from '@overlay-plugin/types/lib/FigmaType';

export const optimize = (figmaNode: FigmaNode): FigmaNode => {
  if (
    !isFrameNode(figmaNode) &&
    !isComponentNode(figmaNode) &&
    !isComponentInstanceNode(figmaNode) &&
    !isGroupNode(figmaNode)
  )
    return figmaNode;

  if (figmaNode.children.length === 0) return figmaNode;

  figmaNode.children = figmaNode.children.map(child => optimize(child));

  const sketchLayerHasChildrenWithMask = figmaNode.children.some(layer => isChildrenHasMask(layer));

  if (!sketchLayerHasChildrenWithMask) return figmaNode;

  let newChildren: FigmaNode[] = [];
  let maskFound = false;
  figmaNode.children.forEach(layer => {
    if (isChildrenHasMask(layer)) {
      maskFound = true;
      newChildren.push(layer);
    } else {
      if (!maskFound) {
        newChildren.push(layer);
      }
    }
  });

  figmaNode.children = newChildren;

  return figmaNode;
};

export const isChildrenHasMask = (figmaNode: FigmaNode) =>
  !isSliceNode(figmaNode) && figmaNode.isMask;
