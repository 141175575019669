import matrixInverse from 'matrix-inverse';
import {
  getLetterSpacingStyle,
  getPaintBackgroundColor,
  getTextFontColorStyle,
  getTextFontWeightStyle,
  getTextLineHeightStyle,
} from '../Helpers/styleHelpers';
import { TEXT_CASE } from '../Helpers/constants';
import {
  ApiStyle,
  ApiTextDecorationEnum,
  ApiBorderProperties,
  ApiBorderRadiusProperties,
  ApiConstraintProperties,
  ApiFontProperties,
  ApiOpacityProperties,
  ApiShadow,
  ApiShadowProperties,
  ApiTextShadowProperties,
  ApiTransformProperties,
  ApiFlexProperties,
  ApiPaddingProperties,
  ApiBlurProperties,
  ApiBackgroundProperties,
  ApiBackground,
  ApiExportFormatEnum,
  ApiOverflowProperties,
} from '@overlay-plugin/types/lib/ApiType';
import { colorToHex } from 'services/Figma/Helpers/conversion';
import { computeLinearGradient } from 'services/API/linearGradientComputer';
import {
  isFontName,
  isAnSolidPaint,
  isFontSize,
  isShadow,
  isBlur,
  hasPaint,
  isTextCase,
  FigmaBlendMixin,
  FigmaConstraintMixin,
  FigmaCornerMixin,
  FigmaDefaultFrameMixin,
  FigmaDefaultShapeMixin,
  FigmaGeometryMixin,
  FigmaLayoutMixin,
  FigmaTextAttribute,
  FigmaTextNode,
  FigmaShadowEffect,
  FigmaComponentNode,
  FigmaFrameNode,
  FigmaBlurEffect,
  FigmaGroupNode,
  isAnGradientPaint,
  FigmaInstanceNode,
  isAnImagePaint,
  FigmaImagePaint,
  FigmaSolidPaint,
  FigmaGradientPaint,
  FigmaEffect,
  FigmaPaint,
  isAFlexContainer,
} from '@overlay-plugin/types/lib/FigmaType';
import {
  ApiColorBackground,
  ApiGradientBackground,
  ApiImageBackground,
} from '@overlay-plugin/types/lib/ApiType';

export const addFontProperties = (
  textNode: FigmaTextNode | FigmaTextAttribute,
): ApiFontProperties => {
  let fontProperties: ApiFontProperties = {};

  fontProperties.fontFamily = isFontName(textNode.fontName) ? textNode.fontName.family : undefined;
  fontProperties.fontSize = isFontSize(textNode.fontSize)
    ? Math.round(textNode.fontSize)
    : undefined;
  fontProperties.color = getTextFontColorStyle(textNode);
  fontProperties.lineHeight = getTextLineHeightStyle(textNode);
  fontProperties.letterSpacing = getLetterSpacingStyle(textNode);
  fontProperties.textTransform = isTextCase(textNode.textCase)
    ? TEXT_CASE[textNode.textCase]
    : null;

  fontProperties = { ...fontProperties, ...getTextFontWeightStyle(textNode) };

  if (textNode.textDecoration) {
    switch (textNode.textDecoration) {
      case 'STRIKETHROUGH':
        fontProperties.textDecoration = ApiTextDecorationEnum.LINE_THROUGH;
        break;
      case 'UNDERLINE':
        fontProperties.textDecoration = ApiTextDecorationEnum.UNDERLINE;
        break;
    }
  }

  return fontProperties;
};

export const addTransformProperties = (figmaElement: FigmaLayoutMixin): ApiTransformProperties => {
  return {
    flippedHorizontally: false,
    flippedVertically: false,
  };
};

export const addOpacityProperties = (figmaElement: FigmaBlendMixin): ApiOpacityProperties => {
  let opacityProperties: ApiOpacityProperties = {};
  if (!figmaElement.opacity) return opacityProperties;

  opacityProperties.opacity = Number.parseFloat(figmaElement.opacity.toPrecision(2));

  return opacityProperties;
};

export const addTextShadowProperties = (figmaNode: FigmaBlendMixin): ApiTextShadowProperties => {
  let textShadowProperties: ApiTextShadowProperties = {};

  if (figmaNode.effects.length === 0) return textShadowProperties;

  const visibleShadows: FigmaShadowEffect[] = figmaNode.effects.filter(
    (effect: FigmaEffect): effect is FigmaShadowEffect =>
      isShadow(effect) && effect.type === 'DROP_SHADOW' && effect.visible,
  );

  if (!visibleShadows || !visibleShadows[0]) return textShadowProperties;
  const textShadow = visibleShadows[0];

  return {
    textShadowColor: colorToHex(textShadow.color, textShadow.color.a),
    textShadowBlur: Math.round(textShadow.radius),
    textShadowVerticalOffset: Math.round(textShadow.offset.y),
    textShadowHorizontalOffset: Math.round(textShadow.offset.x),
  };
};

export const addBlurProperties = (figmaNode: FigmaBlendMixin): ApiBlurProperties => {
  let blurProperties: ApiBlurProperties = {};

  if (figmaNode.effects.length === 0) return blurProperties;

  const backgroundBlurEffects: FigmaBlurEffect[] = figmaNode.effects.filter(
    (effect: FigmaEffect): effect is FigmaBlurEffect =>
      isBlur(effect) && effect.type === 'BACKGROUND_BLUR' && effect.visible,
  );

  const layerBlurEffects: FigmaBlurEffect[] = figmaNode.effects.filter(
    (effect: FigmaEffect): effect is FigmaBlurEffect =>
      isBlur(effect) && effect.type === 'LAYER_BLUR' && effect.visible,
  );

  if (backgroundBlurEffects && backgroundBlurEffects.length > 0) {
    blurProperties.backgroundBlur = backgroundBlurEffects[0].radius;
  }

  if (layerBlurEffects && layerBlurEffects.length > 0) {
    blurProperties.layerBlur = layerBlurEffects[0].radius;
  }

  return blurProperties;
};

export const addBorderRadiusProperties = (
  figmaNode: FigmaCornerMixin,
): ApiBorderRadiusProperties => {
  let borderRadiusProperties: ApiBorderRadiusProperties = {};

  if (
    !figmaNode.bottomLeftRadius &&
    !figmaNode.bottomRightRadius &&
    !figmaNode.topRightRadius &&
    !figmaNode.topLeftRadius
  )
    return borderRadiusProperties;

  borderRadiusProperties.borderRadiusBottomLeft = Math.round(figmaNode.bottomLeftRadius) + 'px';
  borderRadiusProperties.borderRadiusBottomRight = Math.round(figmaNode.bottomRightRadius) + 'px';
  borderRadiusProperties.borderRadiusTopRight = Math.round(figmaNode.topRightRadius) + 'px';
  borderRadiusProperties.borderRadiusTopLeft = Math.round(figmaNode.topLeftRadius) + 'px';

  return borderRadiusProperties;
};

export const addOverflowProperties = (figmaNode: FigmaDefaultFrameMixin): ApiOverflowProperties => {
  if (!figmaNode.clipsContent) return {};

  return {
    overflow: 'HIDDEN',
  };
};

export const addShadowProperties = (figmaNode: FigmaBlendMixin): ApiShadowProperties => {
  let shadowProperties: ApiShadowProperties = {};
  let shadows: ApiShadow[] = [];
  let innerShadows: ApiShadow[] = [];

  if (!figmaNode.hasOwnProperty('effects')) return {};

  if (figmaNode.effects.length === 0) return shadowProperties;

  const visibleShadows: FigmaShadowEffect[] = figmaNode.effects.filter(
    (effect: FigmaEffect): effect is FigmaShadowEffect =>
      isShadow(effect) && effect.type === 'DROP_SHADOW' && effect.visible,
  );
  const visibleInnerShadows: FigmaShadowEffect[] = figmaNode.effects.filter(
    (effect: FigmaEffect): effect is FigmaShadowEffect =>
      isShadow(effect) && effect.type === 'INNER_SHADOW' && effect.visible,
  );
  if (visibleShadows.length === 0 && visibleInnerShadows.length === 0) return {};

  shadows = visibleShadows.map(shadow => {
    return {
      type: 'default',
      shadowBlur: Math.round(shadow.radius),
      shadowColor: colorToHex(shadow.color, shadow.color.a),
      shadowSpread: shadow.spread ? Math.round(shadow.spread) : 0,
      shadowHorizontalOffset: Math.round(shadow.offset.x),
      shadowVerticalOffset: Math.round(shadow.offset.y),
    };
  });

  innerShadows = visibleInnerShadows.map(shadow => {
    return {
      type: 'inset',
      shadowBlur: Math.round(shadow.radius),
      shadowColor: colorToHex(shadow.color, shadow.color.a),
      shadowSpread: shadow.spread ? Math.round(shadow.spread) : 0,
      shadowHorizontalOffset: Math.round(shadow.offset.x),
      shadowVerticalOffset: Math.round(shadow.offset.y),
    };
  });

  shadowProperties.boxShadows = [...shadows, ...innerShadows];
  return shadowProperties;
};

export const addBorderProperties = (
  figmaNode: FigmaDefaultFrameMixin | FigmaDefaultShapeMixin,
  figmaBaseStyle: ApiStyle,
): ApiBorderProperties => {
  let borderProperties: ApiBorderProperties = {};

  if (!figmaNode.strokes || figmaNode.strokes.length === 0 || !figmaNode.strokes[0].visible)
    return borderProperties;

  const paint = figmaNode.strokes[0];
  // Only compute border if this is border inside
  if (paint.type !== 'SOLID') return borderProperties;

  const style: Record<string, any> = {};

  borderProperties.borderPosition = figmaNode.strokeAlign;

  if (figmaNode.strokeAlign !== 'INSIDE') return borderProperties;

  const strokeWeight = Math.round(figmaNode.strokeWeight);
  const borderColor = colorToHex(paint.color, paint.opacity);

  borderProperties.borderTopThickness = strokeWeight;
  borderProperties.borderBottomThickness = strokeWeight;
  borderProperties.borderLeftThickness = strokeWeight;
  borderProperties.borderRightThickness = strokeWeight;
  borderProperties.borderTopColor = borderColor;
  borderProperties.borderLeftColor = borderColor;
  borderProperties.borderBottomColor = borderColor;
  borderProperties.borderRightColor = borderColor;
  style.width = Math.max(figmaBaseStyle.width - 2 * strokeWeight, 0);
  style.height = Math.max(figmaBaseStyle.height - 2 * strokeWeight, 0);

  return {
    ...style,
    ...borderProperties,
  };
};

const createBackgroundImageProperties = (
  figmaFill: FigmaImagePaint,
  order: number,
): ApiImageBackground | null => {
  if (!isAnImagePaint(figmaFill) || !figmaFill.imageHash) return null;

  return {
    type: 'image',
    backgroundImage: null,
    backgroundImageNativeId: figmaFill.imageHash,
    order,
    exportSetting: {
      format: ApiExportFormatEnum.PNG,
      snapshotIsWithStyleProperties: false,
      snapshotWithChildren: false,
    },
  };
};

const createBackgroundColorProperties = (
  figmaFill: FigmaSolidPaint,
  order: number,
): ApiColorBackground | null => {
  if (!isAnSolidPaint(figmaFill)) return null;

  return {
    type: 'color',
    order,
    color: getPaintBackgroundColor(figmaFill),
  };
};

export const addFlexContainerProperties = (
  figmaNode: FigmaComponentNode | FigmaFrameNode | FigmaInstanceNode | FigmaGroupNode,
  parentContainer?: FigmaComponentNode | FigmaFrameNode | FigmaGroupNode | FigmaInstanceNode,
): ApiFlexProperties => {
  let flexProperties: ApiFlexProperties = {
    primaryAxisSizingMode: 'AUTO',
    counterAxisSizingMode: 'AUTO',
  };

  // The top layer is for now always in auto mode
  const isInsideAFlexContainer = !!parentContainer && isAFlexContainer(parentContainer);
  const parentHasTheSameFlexDirection =
    !!parentContainer &&
    isAFlexContainer(parentContainer) &&
    isAFlexContainer(figmaNode) &&
    figmaNode.layoutMode === parentContainer.layoutMode;

  if (isInsideAFlexContainer && isAFlexContainer(figmaNode) && parentHasTheSameFlexDirection) {
    flexProperties.counterAxisSizingMode =
      figmaNode.layoutAlign === 'STRETCH' ? 'FILL' : figmaNode.counterAxisSizingMode;
    flexProperties.primaryAxisSizingMode =
      figmaNode.layoutGrow === 1 ? 'FILL' : figmaNode.primaryAxisSizingMode;
  }

  if (isInsideAFlexContainer && !isAFlexContainer(figmaNode)) {
    flexProperties.primaryAxisSizingMode = figmaNode.layoutGrow === 1 ? 'FILL' : 'FIXED';
    flexProperties.counterAxisSizingMode = figmaNode.layoutAlign === 'STRETCH' ? 'FILL' : 'FIXED';
  }

  if (isInsideAFlexContainer && isAFlexContainer(figmaNode) && !parentHasTheSameFlexDirection) {
    flexProperties.counterAxisSizingMode =
      figmaNode.layoutAlign === 'STRETCH' ? 'FILL' : figmaNode.primaryAxisSizingMode;
    flexProperties.primaryAxisSizingMode =
      figmaNode.layoutGrow === 1 ? 'FILL' : figmaNode.counterAxisSizingMode;
  }

  if (!isAFlexContainer(figmaNode)) {
    return flexProperties;
  }

  switch (figmaNode.layoutMode) {
    case 'HORIZONTAL':
      flexProperties.flexDirection = 'row';
      break;
    case 'VERTICAL':
      flexProperties.flexDirection = 'column';
      break;
  }

  flexProperties.primaryAxisAlignItems = figmaNode.primaryAxisAlignItems;
  flexProperties.counterAxisAlignItems = figmaNode.counterAxisAlignItems;
  flexProperties.itemSpacing = Math.round((figmaNode.itemSpacing + Number.EPSILON) * 100) / 100;

  return flexProperties;
};

export const addPaddingProperties = (figmaNode: FigmaDefaultFrameMixin): ApiPaddingProperties => {
  let paddingProperties: ApiPaddingProperties = {};

  if (figmaNode.layoutMode === 'NONE') return paddingProperties;

  paddingProperties.paddingTop = Math.round((figmaNode.paddingTop + Number.EPSILON) * 100) / 100;
  paddingProperties.paddingBottom =
    Math.round((figmaNode.paddingBottom + Number.EPSILON) * 100) / 100;
  paddingProperties.paddingLeft = Math.round((figmaNode.paddingLeft + Number.EPSILON) * 100) / 100;
  paddingProperties.paddingRight =
    Math.round((figmaNode.paddingRight + Number.EPSILON) * 100) / 100;

  return paddingProperties;
};

export const addFlexChildProperties = (figmaNode: FigmaLayoutMixin): ApiFlexProperties => {
  let flexProperties: ApiFlexProperties = {};
  flexProperties.primaryAxisSizingMode = figmaNode.layoutGrow === 1 ? 'FILL' : 'AUTO';

  flexProperties.counterAxisSizingMode = figmaNode.layoutAlign === 'STRETCH' ? 'FILL' : 'AUTO';

  return flexProperties;
};

export const addConstraintProperties = (
  figmaNode: FigmaConstraintMixin,
  parentContainer?: FigmaComponentNode | FigmaFrameNode | FigmaGroupNode | FigmaInstanceNode,
): ApiConstraintProperties => {
  const isInsideAFlexContainer = !!parentContainer && isAFlexContainer(parentContainer);

  let constraintProperties: ApiConstraintProperties = {
    fixedHeight: false,
    fixedWidth: false,
    verticalConstraint: 'MIN',
    horizontalConstraint: 'MIN',
  };

  if (!figmaNode.constraints || isInsideAFlexContainer) return constraintProperties;

  constraintProperties.verticalConstraint = figmaNode.constraints.vertical;
  constraintProperties.horizontalConstraint = figmaNode.constraints.horizontal;

  return constraintProperties;
};

export const addBackgroundsProperties = (
  figmaNode: FigmaGeometryMixin,
  figmaFrame: Pick<FigmaLayoutMixin, 'width' | 'height'>,
) => {
  if (!hasPaint(figmaNode.fills)) return {};

  let backgroundProperties: ApiBackgroundProperties = {};

  const filteredFills = figmaNode.fills.filter(
    (fill: FigmaPaint) =>
      (fill.type === 'GRADIENT_LINEAR' || fill.type === 'SOLID' || fill.type === 'IMAGE') &&
      fill.visible,
  );

  if (0 === filteredFills.length) {
    return {};
  }

  backgroundProperties.backgrounds = filteredFills
    .map((fill: FigmaPaint, index) => {
      switch (fill.type) {
        case 'GRADIENT_LINEAR':
          return createLinearGradientProperties(fill, index, figmaFrame);
        case 'IMAGE':
          return createBackgroundImageProperties(fill, index);
        case 'SOLID':
          return createBackgroundColorProperties(fill, index);
        default:
          return null;
      }
    })
    .filter((background: ApiBackground | null): background is ApiBackground => null !== background);

  return backgroundProperties;
};

const createLinearGradientProperties = (
  figmaFill: FigmaGradientPaint,
  order: number,
  figmaFrame: Pick<FigmaLayoutMixin, 'width' | 'height'>,
): ApiGradientBackground | null => {
  if (!isAnGradientPaint(figmaFill)) return null;

  const transform =
    figmaFill.gradientTransform.length === 2
      ? [...figmaFill.gradientTransform, [0, 0, 1]]
      : [...figmaFill.gradientTransform];
  const mxInv = matrixInverse(transform);
  const startEnd = [
    [0, 0.5],
    [1, 0.5],
  ].map(p => applyMatrixToPoint(mxInv, p));

  const startingPoint = {
    x: Math.round(startEnd[0][0] * 100) / 100,
    y: Math.round(startEnd[0][1] * 100) / 100,
  };
  const endingPoint = {
    x: Math.round(startEnd[1][0] * 100) / 100,
    y: Math.round(startEnd[1][1] * 100) / 100,
  };

  const fillOpacity = undefined === figmaFill.opacity ? 1 : figmaFill.opacity;
  const apiGradientStop = figmaFill.gradientStops.map((gradientStop, index) => ({
    position: gradientStop.position,
    color: colorToHex(gradientStop.color, gradientStop.color.a * fillOpacity),
    order: index,
  }));

  return {
    order,
    ...computeLinearGradient(
      startingPoint,
      endingPoint,
      figmaFrame.width,
      figmaFrame.height,
      apiGradientStop,
    ),
  };
};

function applyMatrixToPoint(matrix: number[][], point: number[]) {
  return [
    point[0] * matrix[0][0] + point[1] * matrix[0][1] + matrix[0][2],
    point[0] * matrix[1][0] + point[1] * matrix[1][1] + matrix[1][2],
  ];
}
