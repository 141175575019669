import {
  ApiValidationErrorColor,
  ApiValidationErrorTypography,
} from '@overlay-plugin/types/lib/ApiType';
import { ActionType, createStandardAction } from 'typesafe-actions';
import { FrontVariableErrorEnum, FrontVariableTypeErrorEnum } from './types';

// Action Creators
export const saveStylesheetErrorCreator = createStandardAction(
  'STYLESHEET_ERROR/SAVE_COLORS_AND_TYPOS.SUCCESS',
)<{
  colors: ApiValidationErrorColor[];
  typos: ApiValidationErrorTypography[];
}>();

export const changeColorNameCreator = createStandardAction('STYLESHEET_ERROR/CHANGE_COLOR_NAME')<{
  uid: string;
  newName: string;
}>();

export const ignoreTypoErrorCreator = createStandardAction('STYLESHEET_ERROR/IGNORE_TYPO_ERROR')<{
  uid: string;
}>();

export const ignoreAllTypoErrorsCreator = createStandardAction(
  'STYLESHEET_ERROR/IGNORE_ALL_TYPO_ERRORS',
)();

export const ignoreColorErrorCreator = createStandardAction('STYLESHEET_ERROR/IGNORE_COLOR_ERROR')<{
  uid: string;
}>();

export const ignoreAllColorErrorsCreator = createStandardAction(
  'STYLESHEET_ERROR/IGNORE_ALL_COLOR_ERRORS',
)();

export const changeTypoNameCreator = createStandardAction('STYLESHEET_ERROR/CHANGE_TYPO_NAME')<{
  uid: string;
  newName: string;
}>();

export const updateStylesheetCreator = createStandardAction('STYLESHEET_ERROR/UPDATE_STYLESHEET')();

export const submitStylesheetVariableCreator = createStandardAction(
  'STYLESHEET_ERROR/SUBMIT_STYLESHEET_VARIABLE',
)<{
  variableType: FrontVariableTypeErrorEnum;
  uids: string[];
}>();

export const getStylesheetRequestCreator = createStandardAction(
  'STYLESHEET_ERROR/GET_STYLESHEET.REQUEST',
)();

export const getStylesheetSuccessCreator = createStandardAction(
  'STYLESHEET_ERROR/GET_STYLESHEET.SUCCESS',
)<{
  variableNames: string[];
}>();

export const updateTyposValidationStateCreator = createStandardAction(
  'STYLESHEET_ERROR/TYPOS_UPDATE_VALIDATION_STATE',
)<{
  validationStateMap: Record<string, FrontVariableErrorEnum>;
}>();

export const updateColorsValidationStateCreator = createStandardAction(
  'STYLESHEET_ERROR/COLORS_UPDATE_VALIDATION_STATE',
)<{
  validationStateMap: Record<string, FrontVariableErrorEnum>;
}>();

export type StylesheetErrorActions = ActionType<
  | typeof getStylesheetSuccessCreator
  | typeof getStylesheetRequestCreator
  | typeof updateStylesheetCreator
  | typeof changeTypoNameCreator
  | typeof changeColorNameCreator
  | typeof saveStylesheetErrorCreator
  | typeof updateTyposValidationStateCreator
  | typeof updateColorsValidationStateCreator
  | typeof ignoreTypoErrorCreator
  | typeof ignoreColorErrorCreator
  | typeof ignoreAllTypoErrorsCreator
  | typeof ignoreAllColorErrorsCreator
>;
