import {
  ApiVariableColorType,
  ApiVariableTypographyType,
  ApiVariableValidationType,
  ApiVariableType,
} from '@overlay-plugin/types/lib/ApiType';
import { getType } from 'typesafe-actions';
import { actionChannel, call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { getProjectStylesheet, updateStylesheet } from 'services/Interfaces/apiClient';
import { selectToken, withAuthentication } from 'modules/authentication';
import { selectSelectedProject } from 'modules/projects';
import { compileComponentRequestCreator } from 'modules/component';
import { withLoader, withNestedLoader } from 'modules/loading';
import { NestedLoadingKeysEnum, SimpleLoadingKeysEnum } from 'modules/loading/types';
import * as Sentry from '@sentry/react';
import { displayErrorToaster } from 'modules/apiError';
import {
  FrontVariableErrorEnum,
  FrontVariableTypeErrorEnum,
  IFrontColorValidationError,
  IFrontTypographyValidationError,
} from './types';
import {
  getStylesheetRequestCreator,
  getStylesheetSuccessCreator,
  submitStylesheetVariableCreator,
  updateColorsValidationStateCreator,
  updateStylesheetCreator,
  updateTyposValidationStateCreator,
} from 'modules/stylesheetError/actions';
import {
  selectStylesheetColorError,
  selectStylesheetTypoError,
} from 'modules/stylesheetError/selectors';
import { DialogKeys, dialogOpenCreator } from 'modules/dialogs';

const backErrorValidationMapping: Record<string, FrontVariableErrorEnum> = {
  INVALID_NAME: FrontVariableErrorEnum.INVALID_NAME_ERROR,
  DUPLICATE_NAME: FrontVariableErrorEnum.DUPLICATE_NAME_ERROR,
};

// Sagas
const updateStylesheetSaga = function* createVariableSaga() {
  yield put(compileComponentRequestCreator());
};

const submitStylesheetColorSaga = function* createVariableSaga(
  action: ReturnType<typeof submitStylesheetVariableCreator>,
) {
  const token = selectToken(yield select());
  const selectedProject = selectSelectedProject(yield select());

  if (!token || !selectedProject) return;

  let validColors: ApiVariableColorType[] = [];
  for (const uid of action.payload.uids) {
    const color: IFrontColorValidationError | null = yield select(selectStylesheetColorError, uid);

    if (color && color.validationState === FrontVariableErrorEnum.VALID) {
      validColors.push({
        validationId: color.uid,
        name: color.data.color.name,
        value: color.data.color.value,
        type: color.data.color.type,
      });
    }
  }

  if (validColors.length === 0) return;

  try {
    yield call(updateStylesheet, selectedProject.uuid, token, {
      colors: validColors,
      typos: [],
    });
    yield put(
      updateColorsValidationStateCreator({
        validationStateMap: transformUpdateStylesheetSuccessToStatusUpdate(validColors),
      }),
    );
  } catch (error) {
    if (403 === error.status) {
      Sentry.captureEvent({
        message: 'No more style variable available',
      });
      yield put(dialogOpenCreator(DialogKeys.STYLE_LIMITATION_VARIABLE)());
      return;
    }

    if (400 !== error.status) {
      Sentry.captureException(error);
      yield put(displayErrorToaster({ errorMessage: 'Could not submit your color' }));
      return;
    }

    const validationErrors = error.response.body;
    const colorsErrors = transformUpdateStylesheetErrorToStatusUpdate(
      validationErrors.colors.errors,
    );
    yield put(
      updateColorsValidationStateCreator({
        validationStateMap: colorsErrors,
      }),
    );
  }
};

const submitStylesheetTypoSaga = function* createVariableSaga(
  action: ReturnType<typeof submitStylesheetVariableCreator>,
) {
  const token = selectToken(yield select());
  const selectedProject = selectSelectedProject(yield select());

  if (!token || !selectedProject) {
    return;
  }

  let validTypos: ApiVariableTypographyType[] = [];
  for (const uid of action.payload.uids) {
    const typo: IFrontTypographyValidationError | null = yield select(
      selectStylesheetTypoError,
      uid,
    );

    if (typo && typo.validationState === FrontVariableErrorEnum.VALID) {
      validTypos.push({
        validationId: typo.uid,
        name: typo.data.typography.name,
        size: typo.data.typography.size,
        weight: typo.data.typography.weight,
        family: typo.data.typography.family,
        lineHeight: typo.data.typography.lineHeight,
      });
    }
  }

  if (validTypos.length === 0) return;

  try {
    yield call(updateStylesheet, selectedProject.uuid, token, {
      colors: [],
      typos: validTypos,
    });
    yield put(
      updateTyposValidationStateCreator({
        validationStateMap: transformUpdateStylesheetSuccessToStatusUpdate(validTypos),
      }),
    );
  } catch (error) {
    if (403 === error.status) {
      Sentry.captureEvent({
        message: 'No more style variable available',
      });
      yield put(dialogOpenCreator(DialogKeys.STYLE_LIMITATION_VARIABLE)());
      return;
    }

    if (400 !== error.status) {
      Sentry.captureException(error);
      yield put(displayErrorToaster({ errorMessage: 'Could not submit your typo' }));
      return;
    }

    const validationErrors = error.response.body;
    const typographyErrors = transformUpdateStylesheetErrorToStatusUpdate(
      validationErrors.typographies.errors,
    );
    yield put(
      updateTyposValidationStateCreator({
        validationStateMap: typographyErrors,
      }),
    );
  }
};

const transformUpdateStylesheetErrorToStatusUpdate = (errors: ApiVariableValidationType[]) => {
  return errors.reduce(
    (map: Record<string, FrontVariableErrorEnum>, error: ApiVariableValidationType) => {
      map[error.data.validationId] = backErrorValidationMapping[error.message];
      return map;
    },
    {},
  );
};

const transformUpdateStylesheetSuccessToStatusUpdate = (success: ApiVariableType[]) => {
  return success.reduce((map: Record<string, FrontVariableErrorEnum>, success: ApiVariableType) => {
    map[success.validationId] = FrontVariableErrorEnum.SAVED;
    return map;
  }, {});
};

const getStylesheetSaga = function* createVariableSaga() {
  try {
    const token = selectToken(yield select());
    const selectedProject = selectSelectedProject(yield select());

    if (!token || !selectedProject) return;

    const { body: stylesheet } = yield call(getProjectStylesheet, token, selectedProject.uuid);
    const colorNames = stylesheet.colors.map((color: ApiVariableColorType) => color.name);
    const typoNames = stylesheet.typographies.map((typo: ApiVariableTypographyType) => typo.name);

    yield put(getStylesheetSuccessCreator({ variableNames: [...colorNames, ...typoNames] }));
  } catch (e) {
    Sentry.captureException(e);
    yield put(displayErrorToaster({ errorMessage: 'Could not get your stylesheet' }));
  }
};

// Saga Watchers
function* watchUpdateStylesheet() {
  yield takeLatest(
    getType(updateStylesheetCreator),
    withLoader(withAuthentication(updateStylesheetSaga), SimpleLoadingKeysEnum.updateStylesheet),
  );
}

function* watchGetStylesheet() {
  yield takeLatest(
    getType(getStylesheetRequestCreator),
    withLoader(withAuthentication(getStylesheetSaga), SimpleLoadingKeysEnum.getStylesheet),
  );
}

function* watchSubmitStylesheetVariables() {
  // 1- Create a channel for variable actions
  const variableChan = yield actionChannel(getType(submitStylesheetVariableCreator));
  while (true) {
    // 2- take from the channel
    const action = yield take(variableChan);

    // 3- Note that we're using a blocking call
    if (action.payload.variableType === FrontVariableTypeErrorEnum.COLOR) {
      yield call(
        withNestedLoader(
          withAuthentication(submitStylesheetColorSaga),
          NestedLoadingKeysEnum.addVariableToStylesheet,
          action.payload.uids,
        ),
        action,
      );
    }

    if (action.payload.variableType === FrontVariableTypeErrorEnum.TYPO) {
      yield call(
        withNestedLoader(
          withAuthentication(submitStylesheetTypoSaga),
          NestedLoadingKeysEnum.addVariableToStylesheet,
          action.payload.uids,
        ),
        action,
      );
    }
  }
}

// Saga export
export function* watchStylesheetSagas() {
  yield fork(watchUpdateStylesheet);
  yield fork(watchSubmitStylesheetVariables);
  yield fork(watchGetStylesheet);
}
