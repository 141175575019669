import { OverlayPluginStateType } from 'redux/type';
import { FrontVariableErrorEnum } from './types';

export const selectStylesheetColorErrors = (store: OverlayPluginStateType) => {
  const colors = Object.values(store.stylesheetError.colors);
  // We sort the array to have always a consistent order, Object.values is not consistent on Safari :/
  colors.sort((a, b) => a.uid.localeCompare(b.uid));
  return colors;
};

export const selectStylesheetTyposErrors = (store: OverlayPluginStateType) => {
  const typos = Object.values(store.stylesheetError.typos);
  // We sort the array to have always a consistent order, Object.values is not consistent on Safari :/
  typos.sort((a, b) => a.uid.localeCompare(b.uid));
  return typos;
};

export const selectStylesheetTypoError = (store: OverlayPluginStateType, typoUuid: string) => {
  return store.stylesheetError.typos[typoUuid];
};

export const selectStylesheetColorError = (store: OverlayPluginStateType, colorUuid: string) => {
  return store.stylesheetError.colors[colorUuid];
};

export const selectIsStylesheetHasInvalidValue = (store: OverlayPluginStateType) => {
  const invalidColors = selectStylesheetColorErrors(store).filter(
    validationError =>
      validationError.validationState === FrontVariableErrorEnum.DUPLICATE_NAME_ERROR ||
      validationError.validationState === FrontVariableErrorEnum.INVALID_NAME_ERROR,
  );
  const invalidTypos = selectStylesheetTyposErrors(store).filter(
    validationError =>
      validationError.validationState === FrontVariableErrorEnum.DUPLICATE_NAME_ERROR ||
      validationError.validationState === FrontVariableErrorEnum.INVALID_NAME_ERROR,
  );

  return invalidTypos.length + invalidColors.length > 0;
};
