import { decorate } from 'services/Sketch/Decorator/elementDecorator';
import { SketchGeneralLayer } from '@overlay-plugin/types/lib/SketchType';
import { FigmaNode, isAComponentSetNode } from '@overlay-plugin/types/lib/FigmaType';
import { DesignToolEnum } from '@overlay-plugin/types/lib/NativeClientInterface';
import { optimize as optimizeSketchLayer } from 'services/Sketch/Optimizer/sketchOptimizer';
import { optimize as optimizeFigmaNode } from 'services/Figma/Optimizer/figmaOptimizer';
import { mapSketchLayerToAPILayer } from 'services/Sketch/Mapper/sketchMapper';
import { normalizedAPIComponentSet, normalizedAPILayer } from 'services/API/apiNormalizer';
import { mapFigmaLayerToAPILayer } from 'services/Figma/Mapper/figmaMapper';
import { TranslationContextStateType } from 'modules/translationContext/types';
import {
  ApiComponent,
  ApiComponentSet,
  NormalizedApiComponent,
  NormalizedApiComponentSet,
  NormalizedApiLayer,
} from '@overlay-plugin/types/lib/ApiType';

const translationContext: TranslationContextStateType = {
  isAComponentMarkedExportable: false,
};

export const getTranslationContext = () => translationContext;

export const flagComponentMarkedExportable = () => {
  translationContext.isAComponentMarkedExportable = true;
};

const mapSketchComponent = (sketchNode: SketchGeneralLayer) => {
  const decoratedComponent = decorate(sketchNode);
  const optimizedLayer = optimizeSketchLayer(decoratedComponent);
  const apiLayer = mapSketchLayerToAPILayer(optimizedLayer);

  const component: ApiComponent = {
    sketchId: sketchNode.id,
    name: sketchNode.name,
    rootLayer: apiLayer,
    designTool: 'SKETCH',
    variantKey: null,
    isDefaultComponent: true,
  };

  let componentSet: ApiComponentSet = {
    type: 'SINGLE',
    designToolId: sketchNode.id,
    designTool: 'SKETCH',
    name: sketchNode.name,
    children: [component],
  };

  const normalizedComponentSet = normalizedAPIComponentSet(componentSet);

  return {
    entities: {
      layer: normalizedAPILayer(apiLayer).entities.layer,
      component: normalizedComponentSet.entities.component,
      componentSet: normalizedComponentSet.entities.componentSet,
    },
  };
};

const mapFigmaComponent = (figmaNode: FigmaNode) => {
  let layers: Record<string, NormalizedApiLayer> = {};

  let componentSet: ApiComponentSet = {
    type: 'SINGLE',
    designToolId: figmaNode.id,
    name: figmaNode.name,
    designTool: 'FIGMA',
    children: [],
  };

  let variantKeyMap: Record<string, string> = {};

  if (isAComponentSetNode(figmaNode)) {
    componentSet.type = 'VARIANT';
    figmaNode.children.forEach(child => {
      if (null !== child.variantKey && variantKeyMap[child.variantKey]) {
        return;
      }

      if (null !== child.variantKey) {
        variantKeyMap[child.variantKey] = child.variantKey;
      }

      const optimizedNode = optimizeFigmaNode(child);
      const apiRootLayer = mapFigmaLayerToAPILayer(optimizedNode, { left: 0, top: 0 });
      const normalizedNode = normalizedAPILayer(apiRootLayer);
      const childComponent: ApiComponent = {
        sketchId: child.id,
        name: child.name,
        rootLayer: apiRootLayer,
        variantKey: child.variantKey,
        designTool: 'FIGMA',
        isDefaultComponent: false,
      };
      if (child.id === figmaNode.defaultComponent) {
        childComponent.isDefaultComponent = true;
      }
      componentSet.children.push(childComponent);
      layers = {
        ...layers,
        ...normalizedNode.entities.layer,
      };
    });
  } else {
    const optimizedNode = optimizeFigmaNode(figmaNode);
    const apiLayer = mapFigmaLayerToAPILayer(optimizedNode, { left: 0, top: 0 });
    layers = {
      ...layers,
      ...normalizedAPILayer(apiLayer).entities.layer,
    };
    const component: ApiComponent = {
      sketchId: figmaNode.id,
      name: figmaNode.name,
      rootLayer: apiLayer,
      designTool: 'FIGMA',
      variantKey: null,
      isDefaultComponent: true,
    };

    componentSet.children.push(component);
  }

  const normalizedComponentSet = normalizedAPIComponentSet(componentSet);

  return {
    entities: {
      layer: layers,
      component: normalizedComponentSet.entities.component,
      componentSet: normalizedComponentSet.entities.componentSet,
    },
  };
};

export const mapAndNormalizedDesignToolNode = (
  designToolEnum: DesignToolEnum,
  designToolNode: FigmaNode | SketchGeneralLayer,
): {
  entities: {
    layer: Record<string, NormalizedApiLayer>;
    component: Record<string, NormalizedApiComponent>;
    componentSet: Record<string, NormalizedApiComponentSet>;
  };
} => {
  if (DesignToolEnum.FIGMA === designToolEnum)
    return mapFigmaComponent(designToolNode as FigmaNode);

  return mapSketchComponent(designToolNode as SketchGeneralLayer);
};
