import { createSketchImage } from '../imageFactory';
import {
  SketchGeneralLayer,
  isGroup,
  isSymbolMaster,
  isShapePath,
  isShape,
} from '@overlay-plugin/types/lib/SketchType';

export const optimize = (sketchLayer: SketchGeneralLayer): SketchGeneralLayer => {
  if (!isGroup(sketchLayer) && !isSymbolMaster(sketchLayer)) return sketchLayer;

  if (sketchLayer.layers.length === 0) return sketchLayer;

  sketchLayer.layers = sketchLayer.layers.map(child => optimize(child));
  const sketchLayerHasChildrenWithMask = sketchLayer.layers.some(layer =>
    isChildrenHasMask(layer, sketchLayer),
  );

  if (!sketchLayerHasChildrenWithMask) return sketchLayer;

  let isMaskActive = false;
  let maskFound = false;
  sketchLayer.layers.map(layer => {
    if (!isChildrenHasMask(layer, sketchLayer) && maskFound) {
      isMaskActive = true;
    }
    if (isChildrenHasMask(layer, sketchLayer)) {
      maskFound = true;
    }
  });

  if (!isMaskActive) {
    return sketchLayer;
  }

  const childrenWithMask = sketchLayer.layers.filter(layer =>
    isChildrenHasMask(layer, sketchLayer),
  );

  let imageFrame = {
    x: 0,
    y: 0,
    width: sketchLayer.frame.width,
    height: sketchLayer.frame.height,
  };

  childrenWithMask.map(mask => {
    if (sketchLayer.frame.x > imageFrame.x) {
      imageFrame.x = sketchLayer.frame.x;
    }
    if (sketchLayer.frame.y > imageFrame.y) {
      imageFrame.y = sketchLayer.frame.y;
    }
  });

  const name = sketchLayer.name;

  // Replace the layer by the image with the id, name and style of the first mask
  sketchLayer = createSketchImage(
    sketchLayer.id,
    name,
    imageFrame,
    sketchLayer.constraints,
    childrenWithMask[0].style,
    [],
    childrenWithMask[0].transform,
    sketchLayer.layers[1].assetId,
  );

  return sketchLayer;
};

export const isChildrenHasMask = (layer: SketchGeneralLayer, parentLayer: SketchGeneralLayer) =>
  (isShape(layer) || isShapePath(layer)) && layer.hasClippingMask && isGroup(parentLayer);
