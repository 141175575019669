import React from 'react';

import { ReactComponent as ComponentLogo } from 'assets/figma-component.svg';
import { ReactComponent as MultiExportLogo } from 'assets/multi-export.svg';
import { ReactComponent as ReadyLogo } from 'assets/ready.svg';

import Step from './Step.component';
import styles from './Variant.module.scss';

type goToNextTypeFunction = () => void;

export const VariantStepOne = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={'Figma variants'}
    description={
      <>
        Create multi-states components using Figma Variants.
        {'\n\n'}
        Here is how to export them correctly.
      </>
    }
    stepNumber={1}
    gotToNextStep={goToNextStep}
    logo={<ComponentLogo className={styles.componentLogo} />}
  />
);

export const VariantStepTwo = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={'Multi-exports'}
    description={
      <>
        Exporting a Variant means you will export several components at once.
        {'\n\n'}
        Multi-export also means potentially multi-warnings to get clean code. Make sure your Variant
        is clean like spring water :)
      </>
    }
    stepNumber={2}
    gotToNextStep={goToNextStep}
    logo={<MultiExportLogo className={styles.symbolLogo} />}
  />
);

export const VariantStepThree = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={'Don’t forget, clean code means'}
    description={
      <div className={styles.leftAlign}>
        - No duplicate layers name{'\n'}- Every state has clean layer names{'\n'}- Your layout is
        good (Tips : use Auto-Layout){'\n'}
      </div>
    }
    stepNumber={3}
    gotToNextStep={goToNextStep}
    logo={<ReadyLogo className={styles.symbolLogo} />}
  />
);
