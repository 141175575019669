import React from 'react';
import styles from './Tips.module.scss';
import { ClassValue } from 'classnames/types';
import classnames from 'classnames';

declare type PropsTips = {
  className?: ClassValue;
  title?: string;
};

const Tips: React.FunctionComponent<PropsTips> = ({ children, title = 'PRO TIPS', className }) => {
  return (
    <div className={classnames(styles.tips, className)}>
      <div className={styles.titleContainer}>
        <img
          alt=""
          className={styles.tipsIcon}
          src="https://static.overlay-tech.com/assets/e2cfba46-3de5-482a-acdb-1a6ff57b8ae1.svg"
        />
        <p className={styles.title}>{title}</p>
      </div>
      <p className={styles.tipsDescription}>{children}</p>
    </div>
  );
};

export default Tips;
