import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from './components/HightOrderComponents/PrivateRoute';
import AnonymousRoute from './components/HightOrderComponents/AnonymousRoute';
import PreviewPage from './pages/Preview';
import PlaygroundPage from './pages/Playground';
import OnboardingPage from './pages/Onboarding';
import VariantPage from './pages/Variant';

const Loading = () => <div />;

const lazyComponent = (name: string) => lazy(() => import(`./pages/${name}`));

const routes = () => (
  <Suspense fallback={<Loading />}>
    <Switch>
      <PrivateRoute exact path="/" component={lazyComponent('Home')} />
      <PrivateRoute path="/onboarding" component={OnboardingPage} />
      <PrivateRoute path="/variant" component={VariantPage} />
      <AnonymousRoute
        exact
        path="/linter"
        component={lazyComponent('Linter')}
        notAccessibleWhenAuthenticated={false}
      />
      <PrivateRoute exact path="/preview" component={PreviewPage} />
      <PrivateRoute exact path="/playground" component={PlaygroundPage} />
      <AnonymousRoute
        notAccessibleWhenAuthenticated={false}
        exact
        path="/update-plugin"
        component={lazyComponent('UpdatePlugin')}
      />
      <AnonymousRoute
        notAccessibleWhenAuthenticated
        exact
        path="/login"
        component={lazyComponent('Login')}
      />
    </Switch>
  </Suspense>
);

export default routes;
