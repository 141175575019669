import React from 'react';
import { selectComponentCreator } from 'modules/componentSet/componentSet';
import { DesignToolEnum } from '@overlay-plugin/types/lib/NativeClientInterface';
import * as Sentry from '@sentry/react';

declare var window: {
  onmessage: any;
};

type Props = {
  selectComponent: typeof selectComponentCreator;
};

class FigmaBridge extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    window.onmessage = async (event: any) => {
      if (!event.data || !event.data.data) return;

      // Export command
      if (event.data.type === 'export-component') {
        // tslint:disable no-console
        console.log(`
          const data = ${JSON.stringify(event.data.data)}
          window.postMessage({type: '${event.data.type}', data})
      `);
        const component = event.data.data.component;

        Sentry.addBreadcrumb({
          category: 'Select figma component',
          message: 'Select figma component',
          data: {
            component,
            componentNativeId: component.key,
          },
          level: Sentry.Severity.Info,
        });

        return this.props.selectComponent({
          component,
          designTool: DesignToolEnum.FIGMA,
          componentNativeId: component.key,
        });
      }
    };
  }

  render = () => null; // This component is a high order component, it renders nothing
}

export default FigmaBridge;
