import { ApiGradientStop, ApiGradientBackground } from '@overlay-plugin/types/lib/ApiType';

export type Point = {
  x: number;
  y: number;
};

// Be careful staring and ending point must be in percentage
// For example { x: 0, y: 0.5 }
export const computeLinearGradient = (
  startingPoint: Point,
  endingPoint: Point,
  shapeWidth: number,
  shapeHeight: number,
  gradientStop: ApiGradientStop[],
): Omit<ApiGradientBackground, 'order'> => {
  const sketchGradientToRefLinearGradient = changePointToLayerCenterReferential(
    {
      x: endingPoint.x * shapeWidth,
      y: endingPoint.y * shapeHeight,
    },
    shapeWidth,
    shapeHeight,
  );

  const sketchGradientFromRefLinearGradient = changePointToLayerCenterReferential(
    {
      x: startingPoint.x * shapeWidth,
      y: startingPoint.y * shapeHeight,
    },
    shapeWidth,
    shapeHeight,
  );

  let vector = {
    x: sketchGradientToRefLinearGradient.x - sketchGradientFromRefLinearGradient.x,
    y: sketchGradientToRefLinearGradient.y - sketchGradientFromRefLinearGradient.y,
  };

  const vectorNorm = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
  let angle = Math.round((Math.acos(vector.y / vectorNorm) * 180) / Math.PI);
  angle = vector.x < 0 ? 360 - angle : angle;
  const linearAngle = angle;

  // Change layer frame referential
  const layerTopLeftCornerRefLinearGradient = changePointToLayerCenterReferential(
    { x: 0, y: 0 },
    shapeWidth,
    shapeHeight,
  );
  const layerBottomRightCornerRefLinearGradient = changePointToLayerCenterReferential(
    { x: shapeWidth, y: shapeHeight },
    shapeWidth,
    shapeHeight,
  );
  const layerTopRightCornerRefLinearGradient = changePointToLayerCenterReferential(
    { x: shapeWidth, y: 0 },
    shapeWidth,
    shapeHeight,
  );
  const layerBottomLeftCornerRefLinearGradient = changePointToLayerCenterReferential(
    { x: 0, y: shapeHeight },
    shapeWidth,
    shapeHeight,
  );

  const gradientLineLeadingCoefficient = getLeadingCoefficientFromTwoPoints(
    sketchGradientFromRefLinearGradient,
    sketchGradientToRefLinearGradient,
  );

  let gradientLineStartRefPoint = layerBottomLeftCornerRefLinearGradient;
  let gradientLineEndRefPoint = layerTopRightCornerRefLinearGradient;
  if (angle > 90 && angle <= 180) {
    gradientLineStartRefPoint = layerTopLeftCornerRefLinearGradient;
    gradientLineEndRefPoint = layerBottomRightCornerRefLinearGradient;
  }
  if (angle > 180 && angle <= 270) {
    gradientLineStartRefPoint = layerTopRightCornerRefLinearGradient;
    gradientLineEndRefPoint = layerBottomLeftCornerRefLinearGradient;
  }
  if (angle > 270 && angle <= 360) {
    gradientLineStartRefPoint = layerBottomRightCornerRefLinearGradient;
    gradientLineEndRefPoint = layerTopLeftCornerRefLinearGradient;
  }

  const gradientLineStartingPoint = getIntersectionPointForPerpendicularLine(
    gradientLineLeadingCoefficient,
    gradientLineStartRefPoint,
  );
  const gradientLineEndingPoint = getIntersectionPointForPerpendicularLine(
    gradientLineLeadingCoefficient,
    gradientLineEndRefPoint,
  );
  const lineGradientLength = getDistanceBetweenPoints(
    gradientLineStartingPoint,
    gradientLineEndingPoint,
  );

  const gradientLineYSlope =
    sketchGradientToRefLinearGradient.y - sketchGradientFromRefLinearGradient.y;
  const gradientLineXSlope =
    sketchGradientToRefLinearGradient.x - sketchGradientFromRefLinearGradient.x;

  const gradientStops = gradientStop.map(stop => {
    let stopPoint = {
      x: sketchGradientFromRefLinearGradient.x + stop.position * gradientLineXSlope,
      y: sketchGradientFromRefLinearGradient.y + stop.position * gradientLineYSlope,
    };

    const stopPointProjection = getIntersectionPointForPerpendicularLine(
      gradientLineLeadingCoefficient,
      stopPoint,
    );
    const lineGradientProjectionLength = getDistanceBetweenPoints(
      gradientLineStartingPoint,
      stopPointProjection,
    );

    const scalarProduct =
      (stopPointProjection.x - gradientLineStartingPoint.x) *
        (gradientLineEndingPoint.x - gradientLineStartingPoint.x) +
      (stopPointProjection.y - gradientLineStartingPoint.y) *
        (gradientLineEndingPoint.y - gradientLineStartingPoint.y);

    return {
      color: stop.color,
      position:
        scalarProduct < 0
          ? -lineGradientProjectionLength / lineGradientLength
          : lineGradientProjectionLength / lineGradientLength,
      order: stop.order,
    };
  });

  return {
    type: 'gradient',
    linearAngle,
    gradientStops,
  };
};

const getIntersectionPointForPerpendicularLine = (
  leadingCoefficient: number,
  point: Point,
): Point => {
  if (Math.abs(leadingCoefficient) === Infinity) {
    return {
      x: 0,
      y: point.y,
    };
  }

  if (Math.abs(leadingCoefficient) === 0) {
    return {
      x: point.x,
      y: 0,
    };
  }

  const intercept = point.y + (1 / leadingCoefficient) * point.x;
  return {
    x: intercept / (leadingCoefficient + 1 / leadingCoefficient),
    y: (intercept * leadingCoefficient) / (leadingCoefficient + 1 / leadingCoefficient),
  };
};

const getDistanceBetweenPoints = (pointA: Point, pointB: Point) => {
  return Math.sqrt(Math.pow(pointA.x - pointB.x, 2) + Math.pow(pointA.y - pointB.y, 2));
};

const getLeadingCoefficientFromTwoPoints = (start: Point, end: Point) => {
  return (end.y - start.y) / (end.x - start.x);
};

const changePointToLayerCenterReferential = (
  point: Point,
  shapeWidth: number,
  shapeHeight: number,
) => {
  return {
    x: point.x - shapeWidth / 2,
    y: shapeHeight / 2 - point.y,
  };
};
