import { ApiLayer } from '@overlay-plugin/types/lib/ApiType';
import { createApiLayer } from 'services/Figma/Mapper/figmaMapper';
import {
  addOpacityProperties,
  addBorderProperties,
  addBorderRadiusProperties,
  addShadowProperties,
  addConstraintProperties,
  addFlexChildProperties,
  addBlurProperties,
  addBackgroundsProperties,
} from 'services/Figma/Mapper/styleMapper';
import { addSharedColorStyleProperties } from 'services/Figma/Mapper/sharedStyleMapper';
import { mapFigmaImageWithoutStylePropertiesToApiLayer } from 'services/Figma/Mapper/imageMapper';
import {
  FigmaComponentNode,
  FigmaFrameNode,
  FigmaGroupNode,
  FigmaInstanceNode,
  FigmaLineNode,
  isRectangleNode,
} from '@overlay-plugin/types/lib/FigmaType';

export const mapFigmaLineToAPILayer = (
  lineNode: FigmaLineNode,
  previousGroupPosition: { left: number; top: number },
  parentContainer?: FigmaComponentNode | FigmaFrameNode | FigmaGroupNode | FigmaInstanceNode,
): ApiLayer => {
  lineNode.rotation = Math.round(lineNode.rotation * 10) / 10;
  const isAVerticalLine = lineNode.rotation === -90 || lineNode.rotation === 90;
  const isAHorizontalLine = lineNode.rotation === 180 || lineNode.rotation === 0;

  if (!isAVerticalLine && !isAHorizontalLine) {
    return mapFigmaImageWithoutStylePropertiesToApiLayer(lineNode, previousGroupPosition);
  }

  // Vertical line
  if (isAVerticalLine) {
    const lineWidth = lineNode.width;
    lineNode.width = lineNode.strokeWeight;
    lineNode.height = lineWidth;
  } else {
    lineNode.height = lineNode.strokeWeight;
  }

  lineNode.fills = lineNode.strokes;
  lineNode.strokes = [];

  let layer = createApiLayer(lineNode, previousGroupPosition);

  layer = {
    ...layer,
    ...addSharedColorStyleProperties(lineNode),
  };

  layer.style = {
    ...layer.style,
    ...addOpacityProperties(lineNode),
    ...addBackgroundsProperties(lineNode, {
      width: layer.style.width,
      height: layer.style.height,
    }),
    ...addFlexChildProperties(lineNode),
    ...addBorderProperties(lineNode, layer.style),
    ...(isRectangleNode(lineNode) ? addBorderRadiusProperties(lineNode) : {}),
    ...addShadowProperties(lineNode),
    ...addBlurProperties(lineNode),
    ...addConstraintProperties(lineNode, parentContainer),
  };

  return layer;
};
