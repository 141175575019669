import { SketchFrame, SketchText, SketchTextAttribute } from '@overlay-plugin/types/lib/SketchType';
import { ApiLayer, ApiLayerTypeEnum } from '@overlay-plugin/types/lib/ApiType';
import { createApiLayer, mapFrameToStyle } from 'services/Sketch/Mapper/sketchMapper';
import {
  addBlurProperties,
  addFontProperties,
  addOpacityProperties,
  addTextAttributeFontProperties,
  addTextShadowProperties,
} from 'services/Sketch/Mapper/styleMapper';
import {
  addSharedTextStyleProperties,
  addSharedColorStyleProperties,
} from 'services/Sketch/Mapper/sharedStyleMapper';

const TEXT_ALIGN_HORIZONTAL: Record<string, 'left' | 'center' | 'right' | 'justify'> = {
  left: 'left',
  center: 'center',
  right: 'right',
  justified: 'justify',
};

const TEXT_ALIGN_VERTICAL: Record<string, 'top' | 'center' | 'bottom'> = {
  TOP: 'top',
  CENTER: 'center',
  BOTTOM: 'bottom',
};

export const mapSketchTextToAPILayer = (sketchText: SketchText): ApiLayer => {
  let text: ApiLayer = createApiLayer(sketchText);

  text = {
    ...text,
    ...addSharedTextStyleProperties(sketchText),
    ...addSharedColorStyleProperties(sketchText),
  };

  text.text = sketchText.text;
  text.style = {
    ...text.style,
    ...addFontProperties(sketchText.style),
    ...addTextShadowProperties(sketchText.style),
    ...addOpacityProperties(sketchText.style),
    ...addBlurProperties(sketchText.style),
    textBehaviour: sketchText.textBehaviour,
  };

  const alignment = sketchText.alignment || sketchText.style.alignment || 'default';
  text.style.textAlign = TEXT_ALIGN_HORIZONTAL[alignment] || 'left';
  text.style.textAlignVertical =
    TEXT_ALIGN_VERTICAL[sketchText.style.verticalAlignment] || 'center';

  return text;
};

export const mapSketchTextWithAttributesToAPILayer = (
  sketchTextWithAttributes: SketchText,
): ApiLayer => {
  if (!sketchTextWithAttributes.textAttributes) {
    throw new Error("The text with attributes doesn't have any attribute");
  }

  let textWithAttributes: ApiLayer = createApiLayer(sketchTextWithAttributes);

  textWithAttributes = {
    ...textWithAttributes,
    ...addSharedTextStyleProperties(sketchTextWithAttributes),
    ...addSharedColorStyleProperties(sketchTextWithAttributes),
  };

  textWithAttributes.style = {
    ...textWithAttributes.style,
    ...addFontProperties(sketchTextWithAttributes.style),
    ...addOpacityProperties(sketchTextWithAttributes.style),
    ...addBlurProperties(sketchTextWithAttributes.style),
    textBehaviour: sketchTextWithAttributes.textBehaviour,
  };

  const alignment =
    sketchTextWithAttributes.alignment || sketchTextWithAttributes.style.alignment || 'default';
  textWithAttributes.style.textAlign = TEXT_ALIGN_HORIZONTAL[alignment] || 'left';
  textWithAttributes.style.textAlignVertical =
    TEXT_ALIGN_VERTICAL[sketchTextWithAttributes.style.verticalAlignment] || 'center';

  const apiTextAttributes: ApiLayer[] = [];
  sketchTextWithAttributes.textAttributes.forEach(textAttribute => {
    apiTextAttributes.push(
      mappedSketchTextAttributesToApiTextAttribute(
        sketchTextWithAttributes,
        textAttribute,
        sketchTextWithAttributes.frame,
      ),
    );
  });
  textWithAttributes.children = apiTextAttributes;

  return textWithAttributes;
};

export const mappedSketchTextAttributesToApiTextAttribute = (
  sketchTextWithAttributes: SketchText,
  sketchTextAttribute: SketchTextAttribute,
  sketchTextFrame: SketchFrame,
): ApiLayer => ({
  name: sketchTextAttribute.name,
  text: sketchTextAttribute.text,
  type: ApiLayerTypeEnum.TEXT_ATTRIBUTES,
  style: {
    ...mapFrameToStyle(sketchTextFrame),
    ...addTextAttributeFontProperties(sketchTextAttribute.style),
  },
  sketchId: sketchTextAttribute.name + '_' + sketchTextWithAttributes.id,
  asset: null,
  colorStyles: [],
  typographyStyles: [],
});
