import {
  FigmaNode,
  isBooleanOperationNode,
  isComponentInstanceNode,
  isComponentNode,
  isFrameNode,
  isGroupNode,
  isLineNode,
  isVectorNode,
  isPolygonNode,
  isStarNode,
} from '@overlay-plugin/types/lib/FigmaType';

export const optimize = (figmaNode: FigmaNode): FigmaNode => {
  if (
    !isFrameNode(figmaNode) &&
    !isGroupNode(figmaNode) &&
    !isComponentNode(figmaNode) &&
    !isComponentInstanceNode(figmaNode)
  ) {
    return figmaNode;
  }

  if (
    !figmaNode.children ||
    figmaNode.children.length === 0 ||
    figmaNode.exportSettings.length > 0
  ) {
    return figmaNode;
  }

  const vectorChildren = figmaNode.children.filter(child => {
    const isChildExportable = child.exportSettings.length > 0;
    return (
      (isLineNode(child) ||
        isVectorNode(child) ||
        isStarNode(child) ||
        isPolygonNode(child) ||
        isBooleanOperationNode(child)) &&
      !isChildExportable
    );
  });

  // Make exportable
  const allTheChildrenAreVectors =
    vectorChildren.length === figmaNode.children.length && 1 !== vectorChildren.length;

  if (allTheChildrenAreVectors) {
    figmaNode.exportSettings = [
      {
        format: 'SVG',
      },
    ];
  }

  let optimizedChildren = figmaNode.children.map(child => optimize(child));

  figmaNode.children = optimizedChildren;

  return figmaNode;
};
