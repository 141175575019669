import { ApiLayer } from '@overlay-plugin/types/lib/ApiType';
import { createApiLayer, mapFigmaLayerToAPILayer } from 'services/Figma/Mapper/figmaMapper';
import {
  addBackgroundsProperties,
  addBlurProperties,
  addBorderProperties,
  addBorderRadiusProperties,
  addConstraintProperties,
  addFlexContainerProperties,
  addOpacityProperties,
  addOverflowProperties,
  addPaddingProperties,
  addShadowProperties,
} from 'services/Figma/Mapper/styleMapper';
import { isSingleBorder, setSingleBorder } from 'services/API/singleBorderOptimizer';
import { addSharedColorStyleProperties } from 'services/Figma/Mapper/sharedStyleMapper';
import {
  recomputeChildrenPositionAccordingToBorder,
  recomputePaddingAccordingToBorder,
} from 'services/API/borderOptimizer';
import {
  FigmaComponentNode,
  FigmaFrameNode,
  FigmaGroupNode,
  FigmaInstanceNode,
  isComponentInstanceNode,
  isComponentNode,
} from '@overlay-plugin/types/lib/FigmaType';

export const mapFigmaContainerToAPILayer = (
  figmaContainer: FigmaComponentNode | FigmaFrameNode | FigmaInstanceNode,
  previousGroupPosition: { left: number; top: number },
  parentContainer?: FigmaComponentNode | FigmaFrameNode | FigmaGroupNode | FigmaInstanceNode,
): ApiLayer => {
  let layer = isComponentNode(figmaContainer)
    ? createApiLayer(figmaContainer, { left: figmaContainer.x, top: figmaContainer.y })
    : createApiLayer(figmaContainer, previousGroupPosition);

  if (isComponentInstanceNode(figmaContainer)) {
    layer.componentDependencyId = figmaContainer.mainComponent.key;
  }

  layer = {
    ...layer,
    ...addSharedColorStyleProperties(figmaContainer),
  };

  layer = {
    ...layer,
    style: {
      ...layer.style,
      ...addFlexContainerProperties(figmaContainer, parentContainer),
      ...addOverflowProperties(figmaContainer),
      ...addPaddingProperties(figmaContainer),
      ...addBorderRadiusProperties(figmaContainer),
      ...addBorderProperties(figmaContainer, layer.style),
      ...addConstraintProperties(figmaContainer, parentContainer),
      ...addOpacityProperties(figmaContainer),
      ...addShadowProperties(figmaContainer),
      ...addBlurProperties(figmaContainer),
      ...addBackgroundsProperties(figmaContainer, {
        width: layer.style.width,
        height: layer.style.height,
      }),
    },
  };

  if (!(figmaContainer.children.length > 0)) {
    return layer;
  }

  let totalWidthToRemove = 0;
  let totalHeightToRemove = 0;

  // Only group keep relative position from the component
  const previousPosition =
    figmaContainer.type !== 'COMPONENT'
      ? {
          left: figmaContainer.x,
          top: figmaContainer.y,
        }
      : {
          left: 0,
          top: 0,
        };

  const layerChildren = figmaContainer.children.reduce((layerChildren: ApiLayer[], child) => {
    if (!child.visible) {
      return layerChildren;
    }
    const childLayer = mapFigmaLayerToAPILayer(child, previousPosition, figmaContainer);
    const borderToSet = isSingleBorder(childLayer, layer);

    if (borderToSet) {
      let { widthToRemove, heightToRemove } = setSingleBorder(childLayer.style, layer, borderToSet);
      totalWidthToRemove += widthToRemove;
      totalHeightToRemove += heightToRemove;
    } else {
      layerChildren.push(childLayer);
    }

    return layerChildren;
  }, []);

  // We remove width after to allow multiple single borders
  layer.style.width -= totalWidthToRemove;
  layer.style.height -= totalHeightToRemove;

  if (layerChildren) {
    layer.children = layerChildren;
  }

  // We re-compute top / left / height / width
  recomputeChildrenPositionAccordingToBorder(layer);
  recomputePaddingAccordingToBorder(layer);

  return layer;
};
