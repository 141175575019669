"use strict";

exports.__esModule = true;
exports.NativeClientMessageTypeEnum = exports.DesignToolEnum = void 0;
var DesignToolEnum;

(function (DesignToolEnum) {
  DesignToolEnum["FIGMA"] = "figma";
  DesignToolEnum["SKETCH"] = "sketch";
})(DesignToolEnum = exports.DesignToolEnum || (exports.DesignToolEnum = {}));

var NativeClientMessageTypeEnum;

(function (NativeClientMessageTypeEnum) {
  NativeClientMessageTypeEnum["SELECT_LAYER_REQUEST"] = "REQUEST/SELECT_LAYER";
  NativeClientMessageTypeEnum["MAKE_EXPORTABLE_LAYER_REQUEST"] = "REQUEST/MAKE_EXPORTABLE_LAYER";
  NativeClientMessageTypeEnum["CHANGE_LAYER_NAME_REQUEST"] = "REQUEST/CHANGE_LAYER_NAME";
  NativeClientMessageTypeEnum["MAKE_EXPORTABLE_REQUEST"] = "REQUEST/MAKE_EXPORTABLE";
  NativeClientMessageTypeEnum["GENERATE_TEMPLATE_REQUEST"] = "REQUEST/GENERATE_TEMPLATE";
  NativeClientMessageTypeEnum["CLOSE_PLUGIN_REQUEST"] = "REQUEST/CLOSE_PLUGIN";
  NativeClientMessageTypeEnum["GET_BASE_64_IMAGES_REQUEST"] = "REQUEST/GET_BASE_64_IMAGES";
  NativeClientMessageTypeEnum["EXPORT_COMPONENT_REQUEST"] = "REQUEST/EXPORT_COMPONENT";
  NativeClientMessageTypeEnum["TRANSLATE_LAYER_REQUEST"] = "REQUEST/TRANSLATE_REQUEST";
  NativeClientMessageTypeEnum["OPEN_LINK_REQUEST"] = "REQUEST/OPEN_LINK";
  NativeClientMessageTypeEnum["CHECK_VERSION_REQUEST"] = "REQUEST/CHECK_VERSION"; // Figma specific - For sketch we use window interface

  NativeClientMessageTypeEnum["MAKE_EXPORTABLE_LAYER_RESPONSE"] = "RESPONSE/MAKE_EXPORTABLE_LAYER";
  NativeClientMessageTypeEnum["GENERATE_TEMPLATE_RESPONSE"] = "RESPONSE/GENERATE_TEMPLATE";
  NativeClientMessageTypeEnum["GET_BASE_64_IMAGES_RESPONSE"] = "RESPONSE/GET_BASE_64_IMAGES";
  NativeClientMessageTypeEnum["NATIVE_ERROR"] = "NATIVE_ERROR";
})(NativeClientMessageTypeEnum = exports.NativeClientMessageTypeEnum || (exports.NativeClientMessageTypeEnum = {}));