import { FigmaNode, isComponentNode } from '@overlay-plugin/types/lib/FigmaType';
import { flagComponentMarkedExportable } from 'services/API/apiMapper';

export const optimize = (figmaNode: FigmaNode): FigmaNode => {
  if (isComponentNode(figmaNode) && figmaNode.exportSettings.length > 0) {
    flagComponentMarkedExportable();
  }

  return figmaNode;
};
