import { connect } from 'react-redux';
import Welcome from './Onboarding.component';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import { finishOnboardingCreator, openExternalLinkCreator } from 'modules/abstractDesignTool';
import { OverlayPluginStateType } from 'redux/type';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { isLoading } from 'modules/loading';

const mapStateToProps = (state: OverlayPluginStateType) => ({
  isGeneratingTemplate: isLoading(state, SimpleLoadingKeysEnum.generateTemplateComponent),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  goToPage: (pageName: string) => () => dispatch(push(pageName)),
  openExternalLink: (link: string) => dispatch(openExternalLinkCreator({ link })),
  generateTemplateComponent: () => dispatch(finishOnboardingCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
