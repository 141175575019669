import {
  isContainer,
  isShapePath,
  SketchGeneralLayer,
  SketchShapeTypeEnum,
} from '@overlay-plugin/types/lib/SketchType';
import { createSketchShapePath } from 'services/Sketch/divFactory';
import cloneDeep from 'lodash/cloneDeep';

declare type DecoratorType = {
  match: (layer: SketchGeneralLayer) => boolean;
  decorate: (layer: SketchGeneralLayer) => SketchGeneralLayer;
};
export const horizontalLineDecorator: DecoratorType = {
  match: (layer: SketchGeneralLayer) => {
    return (
      isShapePath(layer) &&
      layer.shapeType === SketchShapeTypeEnum.CUSTOM &&
      layer.frame.height === 1
    );
  },
  decorate: (layer: SketchGeneralLayer) => {
    if (!isShapePath(layer)) {
      return layer;
    }

    const layerClone = cloneDeep(layer);

    const newSketchShape = createSketchShapePath(
      layerClone.id,
      layerClone.name,
      layerClone.frame,
      SketchShapeTypeEnum.RECTANGLE,
      layerClone.constraints,
      layerClone.exportFormats,
      layerClone.hasClippingMask,
      layerClone.style,
    );

    // We migrate border to background color and apply border size to height
    const { position, thickness, ...fillsParam } = layerClone.style.borders[0];
    newSketchShape.style.fills[0] = fillsParam;
    newSketchShape.frame.height = thickness;
    newSketchShape.style.borders = [];

    return newSketchShape;
  },
};

export const verticalLineDecorator: DecoratorType = {
  match: (layer: SketchGeneralLayer) => {
    return (
      isShapePath(layer) &&
      layer.shapeType === SketchShapeTypeEnum.CUSTOM &&
      layer.frame.width === 1
    );
  },
  decorate: (layer: SketchGeneralLayer) => {
    if (!isShapePath(layer)) {
      return layer;
    }

    const layerClone = cloneDeep(layer);

    const newSketchShape = createSketchShapePath(
      layerClone.id,
      layerClone.name,
      layerClone.frame,
      SketchShapeTypeEnum.RECTANGLE,
      layerClone.constraints,
      layerClone.exportFormats,
      layerClone.hasClippingMask,
      layerClone.style,
    );

    // We migrate border to background color and apply border size to width
    const { position, thickness, ...fillsParam } = layerClone.style.borders[0];
    newSketchShape.style.fills[0] = fillsParam;
    newSketchShape.frame.width = thickness;
    newSketchShape.style.borders = [];

    return newSketchShape;
  },
};

const overlayDecorators: DecoratorType[] = [horizontalLineDecorator, verticalLineDecorator];

export const decorate = (layer: SketchGeneralLayer): SketchGeneralLayer => {
  let decoratedLayer = layer;
  overlayDecorators.forEach(decorator => {
    if (decorator.match(layer)) decoratedLayer = decorator.decorate(layer);
  });

  if (isContainer(decoratedLayer) && decoratedLayer.layers.length > 0) {
    decoratedLayer.layers = decoratedLayer.layers.map(layer => decorate(layer));
  }

  return decoratedLayer;
};
