import sketchClient from 'services/Interfaces/sketchClient';
import figmaClient from 'services/Interfaces/figmaClient';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';
import { call, fork, put, race, select, takeLatest } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { Reducer } from 'redux';
import { OverlayPluginStateType } from 'redux/type';
import { selectComponentCreator } from 'modules/componentSet';
import { INativeClientInterface } from 'services/Interfaces/NativeClientInterface';
import { delay } from 'redux-saga';
import { withLoader } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { finishOnBoardingSaga } from 'modules/user';
import {
  DesignToolEnum,
  NativeClientMessageTypeEnum,
} from '@overlay-plugin/types/lib/NativeClientInterface';
import * as Sentry from '@sentry/react';

export type AbstractDesignToolState = {
  nativeVersion: string;
  webVersion: string;
  designTool: DesignToolEnum;
};

const initialState: AbstractDesignToolState = {
  designTool: DesignToolEnum.SKETCH,
  nativeVersion: '0',
  webVersion: process.env.REACT_APP_WEB_VERSION ? process.env.REACT_APP_WEB_VERSION : '0',
};

// Action Creators
export const openExternalLinkCreator = createStandardAction(
  'DESIGN_TOOL_CLIENT/OPEN_EXTERNAL_LINK',
)<{
  link: string;
}>();

export const closePluginCreator = createStandardAction('DESIGN_TOOL/CLOSE_PLUGIN')();

export const checkVersionCreator = createStandardAction('DESIGN_TOOL/CHECK_VERSION_REQUEST')();

export const finishOnboardingCreator = createStandardAction('DESIGN_TOOL/FINISH_ONBOARDING')();

export const selectLayerCreator = createStandardAction('DESIGN_TOOL/SELECT_LAYER_REQUEST')<{
  layerId: string;
}>();

export const changeLayerNameInDesignToolCreator = createStandardAction(
  'DESIGN_TOOL/CHANGE_LAYER_NAME_REQUEST',
)<{
  layerId: string;
  newName: string;
}>();

export const exportAsAComponentCreator = createStandardAction('DESIGN_TOOL/EXPORT_AS_A_COMPONENT')<{
  componentNativeId: string | undefined;
}>();

export const exportAsABackgroundCreator = createStandardAction(
  'DESIGN_TOOL/EXPORT_AS_A_BACKGROUND',
)<{
  layerId: string;
  componentNativeId: string | undefined;
}>();

export const exportAsAnImageCreator = createStandardAction('DESIGN_TOOL/EXPORT_AS_AN_IMAGE')<{
  layerId: string;
}>();

export const checkVersionResponseCreator = createStandardAction(
  'DESIGN_TOOL/CHECK_VERSION_RESPONSE',
)<{
  nativeVersion: string;
  designTool: DesignToolEnum;
}>();

type AbstractDesignToolActions = ActionType<
  typeof checkVersionResponseCreator | typeof selectComponentCreator
>;

// Selectors
export const selectDesignTool = (state: OverlayPluginStateType) => state.designTool.designTool;

// Reducer
export const abstractDesignToolReducer: Reducer<
  AbstractDesignToolState,
  AbstractDesignToolActions
> = (state = initialState, action) => {
  switch (action.type) {
    case getType(checkVersionResponseCreator):
      return {
        ...state,
        designTool: action.payload.designTool,
        nativeVersion: action.payload.nativeVersion,
      };
    case getType(selectComponentCreator):
      return {
        ...state,
        designTool: action.payload.designTool,
      };
    default:
      return state;
  }
};

export function* getTheDesignToolClient() {
  const designTool = yield select(selectDesignTool);
  switch (designTool) {
    case DesignToolEnum.FIGMA:
      return figmaClient;
    case DesignToolEnum.SKETCH:
      return sketchClient;
  }
}

function* openExternalLinkSaga(action: ReturnType<typeof openExternalLinkCreator>) {
  const nativeClient: INativeClientInterface = yield call(getTheDesignToolClient);
  nativeClient.openExternalLink(action.payload.link);
}

function* closePluginSaga() {
  Sentry.captureMessage('Close plugin');
  const nativeClient: INativeClientInterface = yield call(getTheDesignToolClient);
  nativeClient.closePlugin();
}

function* generateTemplateComponentSaga() {
  yield call(finishOnBoardingSaga);
  yield put(replace('/'));
}

function* selectLayerSaga(action: ReturnType<typeof selectLayerCreator>) {
  const nativeClient: INativeClientInterface = yield call(getTheDesignToolClient);
  nativeClient.selectLayer(action.payload.layerId);
}

function* changeLayerNameSaga(action: ReturnType<typeof changeLayerNameInDesignToolCreator>) {
  const nativeClient: INativeClientInterface = yield call(getTheDesignToolClient);
  nativeClient.changeLayerName(action.payload.layerId, action.payload.newName);
}

function* checkVersionSaga() {
  window.parent.postMessage(NativeClientMessageTypeEnum.CHECK_VERSION_REQUEST, '*');

  const getFigmaPluginVersion = () =>
    new Promise(resolve => {
      const versionSentByDesignerTool = (event: any) => {
        if (event.data.type === 'check-version-response') resolve({ ...event.data.data });
      };

      window.addEventListener('message', versionSentByDesignerTool, false);
    });

  const getSketchPluginVersion = () =>
    new Promise(resolve => {
      // @ts-ignore This function is call in the sketch side
      window.checkVersion = (version: string, designTool: DesignToolEnum) => {
        resolve({
          version,
          designTool,
        });
      };
    });

  const raceResult = yield race({
    sketchVersion: call(getSketchPluginVersion),
    figmaVersion: call(getFigmaPluginVersion),
    timeout: delay(1000),
  });

  if (!raceResult.hasOwnProperty('sketchVersion') && !raceResult.hasOwnProperty('figmaVersion')) {
    yield put(replace('/update-plugin'));
    return;
  }

  const isSketchPlugin = !!raceResult.sketchVersion;
  const result = isSketchPlugin ? raceResult.sketchVersion : raceResult.figmaVersion;

  // 0 is for dev env
  if (
    process.env.REACT_APP_WEB_VERSION !== result.version &&
    result.version !== '0' &&
    isSketchPlugin
  ) {
    yield put(replace('/update-plugin'));
  } else {
    Sentry.addBreadcrumb({
      category: 'Get plugin version',
      message: 'Plugin used: ' + result.designTool + ' v' + result.version,
      level: Sentry.Severity.Info,
    });
    yield put(
      checkVersionResponseCreator({
        nativeVersion: result.version,
        designTool: result.designTool,
      }),
    );
  }
}

// Saga Watchers
function* watchOpenExternalLink() {
  yield takeLatest(getType(openExternalLinkCreator), openExternalLinkSaga);
}

function* watchClosePlugin() {
  yield takeLatest(getType(closePluginCreator), closePluginSaga);
}

function* watchCheckVersion() {
  yield takeLatest(getType(checkVersionCreator), checkVersionSaga);
}

function* watchSelectLayer() {
  yield takeLatest(getType(selectLayerCreator), selectLayerSaga);
}

function* watchChangeLayerName() {
  yield takeLatest(getType(changeLayerNameInDesignToolCreator), changeLayerNameSaga);
}

function* watchGenerateTemplateComponent() {
  yield takeLatest(
    getType(finishOnboardingCreator),
    withLoader(generateTemplateComponentSaga, SimpleLoadingKeysEnum.generateTemplateComponent),
  );
}

export function* watchDesignToolClientSagas() {
  yield fork(watchOpenExternalLink);
  yield fork(watchSelectLayer);
  yield fork(watchGenerateTemplateComponent);
  yield fork(watchCheckVersion);
  yield fork(watchChangeLayerName);
  yield fork(watchClosePlugin);
}
