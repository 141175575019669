import React from 'react';
import { Location } from 'history';
import { Route, Redirect } from 'react-router-dom';

type Props = {
  location: Location;
  isAuthenticated: boolean;
  notAccessibleWhenAuthenticated: boolean;
  component: React.Component<any>;
};

class AnonymousRoute extends React.Component<Props> {
  render() {
    const {
      component: Component,
      isAuthenticated,
      notAccessibleWhenAuthenticated,
      ...rest
    } = this.props;
    // @ts-ignore
    const { from } = this.props.location.state || {
      from: { pathname: '/' },
    };

    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated && notAccessibleWhenAuthenticated ? (
            <Redirect to={from} />
          ) : (
            // @ts-ignore
            <Component {...props} />
          )
        }
      />
    );
  }
}

export default AnonymousRoute;
