import { connect } from 'react-redux';
import ErrorToasterController from './ErrorToasterController.component';
import { resetErrorsRequest, selectErrorsToDisplay } from 'modules/apiError';
import { OverlayPluginStateType } from 'redux/type';

const mapStateToProps = (state: OverlayPluginStateType) => ({
  errorsToDisplay: selectErrorsToDisplay(state),
});

const mapDispatchToProps = {
  resetErrors: resetErrorsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorToasterController);
