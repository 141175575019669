import { Reducer } from 'redux';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';
import { call, fork, put, race, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  getPlayground,
  postValidateStylesheet,
  updateComponent,
  uploadComponent,
  uploadPreview,
} from 'services/Interfaces/apiClient';
import {
  ApiColorShareStyle,
  ApiComponentSet,
  ApiDesignToolEnum,
  ApiExportFormatEnum,
  ApiStylesheetValidationErrorEnum,
  ApiTextShareStyle,
  ApiValidationErrorColor,
  ApiValidationErrorTypography,
  ApiVariableTypographyType,
  NormalizedApiComponent,
  NormalizedApiLayer,
} from '@overlay-plugin/types/lib/ApiType';
import { dialogCloseCreator, DialogKeys, dialogOpenCreator } from './dialogs';
import { withLoader } from './loading';
import {
  getExistingComponentSaga,
  mapComponentSuccessCreator,
  selectComponentName,
  selectComponentNativeId,
  selectSelectedComponentSet,
} from './componentSet';
import { selectLayersMap } from './layers';
import { isAuthenticated, selectToken, withAuthentication } from './authentication';
import { selectSelectedProject } from './projects';
import { OverlayPluginStateType } from 'redux/type';
import { saveStylesheetErrorCreator } from 'modules/stylesheetError';
import groupBy from 'lodash/groupBy';
import {
  getTheDesignToolClient,
  openExternalLinkCreator,
  selectDesignTool,
} from 'modules/abstractDesignTool';
import { findNativeImageLayerAndUploadThemOnServer } from 'services/API/imageExtractor';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import {
  DesignToolEnum,
  ExportCommand,
  PluginImageResponse,
} from '@overlay-plugin/types/lib/NativeClientInterface';
import { delay } from 'redux-saga';
import * as Sentry from '@sentry/react';
import { displayErrorToaster } from 'modules/apiError';
import { selectCurrentUser } from 'modules/user';
import { User } from 'modules/user/types';
import { rgba2hexWithAlpha } from 'components/Molecules/StylesheetValidationDialog/utils';

export type ComponentStateType = {
  map: Record<string, NormalizedApiComponent>;
  componentUuidInDb: string | null;
};

export const mapDesignToolToApiDesignTool = (designTool: DesignToolEnum): ApiDesignToolEnum => {
  if (designTool === DesignToolEnum.FIGMA) return ApiDesignToolEnum.FIGMA;
  if (designTool === DesignToolEnum.SKETCH) return ApiDesignToolEnum.SKETCH;
  return ApiDesignToolEnum.SKETCH;
};

const initialState: ComponentStateType = {
  map: {},
  componentUuidInDb: null,
};

// Actions Creators
export const uploadComponentInitCreator = createStandardAction(
  'COMPONENTS/UPLOAD_COMPONENT_INIT',
)();

export const setComponentIdInDbCreator = createStandardAction('COMPONENTS/SET_COMPONENT_ID_IN_DB')<{
  componentUuidInDb: string | null;
}>();

export const validateStylesheetCreator = createStandardAction('COMPONENTS/VALIDATE_STYLESHEET')();

export const compileComponentRequestCreator = createStandardAction('COMPONENTS/COMPILE.REQUEST')();

type ComponentActions = ActionType<
  typeof setComponentIdInDbCreator | typeof mapComponentSuccessCreator
>;

// Selector
export const selectComponentUuidInDb = (state: OverlayPluginStateType) =>
  state.components.componentUuidInDb;

// Reducer
export const componentReducer: Reducer<ComponentStateType, ComponentActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getType(mapComponentSuccessCreator):
      return {
        ...state,
        map: {
          ...state.map,
          ...action.payload.component,
        },
      };
    case getType(setComponentIdInDbCreator):
      return {
        ...state,
        componentUuidInDb: action.payload.componentUuidInDb,
      };
    default:
      return state;
  }
};

// Sagas
function* uploadComponentInitSaga(action: ReturnType<typeof uploadComponentInitCreator>) {
  Sentry.addBreadcrumb({
    category: 'upload component',
    message: 'Start upload component',
    level: Sentry.Severity.Info,
  });

  try {
    yield call(getExistingComponentSaga);
    const componentUuidInDb = yield select(selectComponentUuidInDb);

    if (componentUuidInDb) {
      yield put(dialogOpenCreator(DialogKeys.UPDATE)());
    } else {
      yield put(validateStylesheetCreator());
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(displayErrorToaster({ errorMessage: 'Could not validate stylesheet' }));
  }
}

function* validateStylesheetSaga(action: ReturnType<typeof validateStylesheetCreator>) {
  const isUserAuthenticated: boolean = yield select(isAuthenticated);
  const componentSet: ApiComponentSet = yield select(selectSelectedComponentSet);
  const selectedProject = selectSelectedProject(yield select());
  const token = selectToken(yield select());

  if (!isUserAuthenticated || !componentSet || !selectedProject || !token) {
    return null;
  }

  try {
    const { body: validationErrors } = yield call(
      postValidateStylesheet,
      token,
      selectedProject.uuid,
      componentSet,
    );
    if (validationErrors.length === 0) {
      yield put(compileComponentRequestCreator());
    } else {
      const LayersMap: Record<string, NormalizedApiLayer> = yield select(selectLayersMap);

      const validationErrorsGroupByMessage = groupBy(validationErrors, 'message');
      const unknownTypos =
        validationErrorsGroupByMessage[ApiStylesheetValidationErrorEnum.UNKNOWN_TYPOGRAPHY] || [];
      const unknownColors =
        validationErrorsGroupByMessage[ApiStylesheetValidationErrorEnum.UNKNOWN_COLOR] || [];

      const unknownColorsWithDesignToolName: ApiValidationErrorColor[] = unknownColors.map(
        (color: ApiValidationErrorColor) => {
          const designToolColorName = getDesignToolColorName(
            LayersMap,
            color.data.layers,
            rgba2hexWithAlpha(color.data.color.value),
          );

          return {
            designToolColorName,
            ...color,
          };
        },
      );

      const unknownTyposWithDesignToolName: ApiValidationErrorTypography[] = unknownTypos.map(
        (typo: ApiValidationErrorTypography) => {
          const designToolTypoName = getTypographyStyleWithLayerIds(
            LayersMap,
            typo.data.layers,
            typo.data.typography,
          );

          return {
            designToolTypoName,
            ...typo,
          };
        },
      );

      yield put(
        saveStylesheetErrorCreator({
          typos: unknownTyposWithDesignToolName,
          colors: unknownColorsWithDesignToolName,
        }),
      );
      yield put(dialogOpenCreator(DialogKeys.VALIDATION)());
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put(displayErrorToaster({ errorMessage: 'Could not validate stylesheet' }));
  }
}

export const getTypographyStyleWithLayerIds = (
  layerMap: Record<string, NormalizedApiLayer>,
  layerIds: string[],
  typography: ApiVariableTypographyType,
): ApiTextShareStyle | null => {
  let typographyStyle = null;

  for (let layerId of layerIds) {
    const layer = layerMap[layerId];
    if (!layer || layer.typographyStyles.length === 0) continue;

    const typoStyleMatching = layer.typographyStyles.filter(
      typoStyle =>
        typoStyle.lineHeight === typography.lineHeight &&
        typoStyle.size === typography.size &&
        typoStyle.family === typography.family &&
        typoStyle.weight === typography.weight,
    );
    if (typoStyleMatching.length === 0) continue;

    typographyStyle = typoStyleMatching[0];
    break;
  }
  return typographyStyle;
};

const getDesignToolColorName = (
  layerMap: Record<string, NormalizedApiLayer>,
  layerIds: string[],
  hexadecimalColor: string,
): ApiColorShareStyle | null => {
  let colorStyle = null;
  for (let layerId of layerIds) {
    const layer = layerMap[layerId];
    if (!layer || layer.colorStyles.length === 0) continue;

    const colorStyleMatching = layer.colorStyles.filter(
      colorStyle => colorStyle.value === hexadecimalColor,
    );
    if (colorStyleMatching.length === 0) continue;

    colorStyle = colorStyleMatching[0];
    break;
  }

  return colorStyle;
};

function* compileComponentSaga() {
  yield put(dialogCloseCreator(DialogKeys.VALIDATION)());
  const isUserAuthenticated = isAuthenticated(yield select());
  const componentSet = selectSelectedComponentSet(yield select());
  const selectedProject = selectSelectedProject(yield select());
  const componentNameInput = selectComponentName(yield select());
  const componentUuidInDb = selectComponentUuidInDb(yield select());
  const componentNativeId = selectComponentNativeId(yield select());
  const designTool = selectDesignTool(yield select());
  const nativeClient = yield call(getTheDesignToolClient);
  const token = selectToken(yield select());

  if (!token || !componentNativeId || !isUserAuthenticated || !componentSet || !selectedProject) {
    return null;
  }

  const defaultComponents = componentSet.children.filter(child => child.isDefaultComponent);

  if (!defaultComponents || defaultComponents.length !== 1) {
    return null;
  }

  const defaultComponent = defaultComponents[0];

  const componentName = '' !== componentNameInput ? componentNameInput : defaultComponent.name;
  const selectedProjectId = selectedProject.uuid;
  let componentPreviewUrl = '';

  try {
    const getAssetsRace = yield race({
      getAssetsRequest: call(
        findNativeImageLayerAndUploadThemOnServer,
        componentSet.children,
        selectedProjectId,
        token,
        nativeClient.getBase64Images,
      ),
      timeout: delay(15000),
    });

    if (getAssetsRace.timeout) {
      Sentry.captureEvent({
        message: 'Failed to upload assets',
      });
    }

    const rootLayerId = defaultComponent.rootLayer.sketchId;
    const previewExportCommand: ExportCommand = {
      uuid: 'preview',
      layerId: rootLayerId,
      type: 'image',
      exportSetting: {
        format: ApiExportFormatEnum.PNG,
        snapshotIsWithStyleProperties: true,
        snapshotWithChildren: true,
      },
    };
    const getBase64PreviewRace: {
      getBase64PreviewRequest: Record<number, PluginImageResponse>;
      timeout: boolean;
    } = yield race({
      getBase64PreviewRequest: call(nativeClient.getBase64Images, [previewExportCommand]),
      timeout: delay(5000),
    });

    if (
      getBase64PreviewRace.getBase64PreviewRequest &&
      Object.values(getBase64PreviewRace.getBase64PreviewRequest).length === 1
    ) {
      const componentPreview = Object.values(getBase64PreviewRace.getBase64PreviewRequest)[0].image;
      const uploadResponse = yield call(uploadPreview, componentPreview, selectedProjectId, token);
      componentPreviewUrl = uploadResponse.body;
    } else {
      Sentry.captureEvent({
        message: 'Failed to upload preview',
      });
    }
  } catch (e) {
    Sentry.captureEvent({
      message: 'Failed to upload component assets',
    });
  }

  try {
    let componentUuid;
    const { body } = !componentUuidInDb
      ? yield call(
          uploadComponent,
          componentSet,
          componentNativeId,
          selectedProjectId,
          componentName,
          mapDesignToolToApiDesignTool(designTool),
          token,
          componentPreviewUrl,
        )
      : yield call(
          updateComponent,
          componentUuidInDb,
          componentSet,
          selectedProjectId,
          componentName,
          mapDesignToolToApiDesignTool(designTool),
          token,
          componentPreviewUrl,
        );

    componentUuid = body.uuid;
    yield put(setComponentIdInDbCreator({ componentUuidInDb: componentUuid }));

    const currentUser: User = yield select(selectCurrentUser);
    if (!currentUser.hasAlreadyGetThePlayground) {
      yield put(
        openExternalLinkCreator({
          link: `${process.env.REACT_APP_WEB_URL}/project/${selectedProjectId}/component-sets/${componentUuid}`,
        }),
      );
      yield put(push('/playground'));
      yield call(getPlayground, token);
      return;
    }

    yield put(push('/preview'));
  } catch (e) {
    if (e.status === 403) {
      Sentry.captureEvent({
        message: 'No more credit available',
      });
      yield put(dialogOpenCreator(DialogKeys.SUBSCRIBE)());
      return;
    }

    Sentry.captureException(e);
    yield put(displayErrorToaster({ errorMessage: 'Failed to upload component' }));
  }
}

// Saga Watchers
function* watchUploadComponentInit() {
  yield takeLatest(
    getType(uploadComponentInitCreator),
    withLoader(withAuthentication(uploadComponentInitSaga), SimpleLoadingKeysEnum.uploadComponent),
  );
}

function* watchUploadValidation() {
  yield takeLatest(
    getType(validateStylesheetCreator),
    withLoader(validateStylesheetSaga, SimpleLoadingKeysEnum.validateStylesheet),
  );
}

function* watchCompileComponent() {
  yield takeLatest(
    getType(compileComponentRequestCreator),
    withLoader(withAuthentication(compileComponentSaga), SimpleLoadingKeysEnum.uploadComponent),
  );
}

export function* watchComponentSagas() {
  yield fork(watchUploadComponentInit);
  yield fork(watchUploadValidation);
  yield fork(watchCompileComponent);
}
