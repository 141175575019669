import React from 'react';
import styles from './Onboarding.module.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './welcomeTransition.scss';
import PrivateRoute from 'components/HightOrderComponents/PrivateRoute';
import { withRouter, RouteComponentProps, Switch } from 'react-router-dom';
import {
  SketchStepOne,
  SketchStepTwo,
  SketchStepThree,
  SketchStepFour,
  SketchStepFive,
  SketchStepSix,
  SketchStepSeven,
  SketchStepOnBoardingFailed,
} from './SketchSteps';

import {
  FigmaStepOne,
  FigmaStepTwo,
  FigmaStepThree,
  FigmaStepFour,
  FigmaStepFive,
  FigmaStepSix,
  FigmaStepSeven,
  FigmaStepOnBoardingFailed,
} from './FigmaSteps';

interface IProps extends RouteComponentProps<any, any, any> {
  goToPage: (path: string) => () => void;
  isGeneratingTemplate: boolean;
  generateTemplateComponent: () => void;
  openExternalLink: (link: string) => void;
}

const OnBoarding: React.FunctionComponent<IProps> = ({
  goToPage,
  location,
  isGeneratingTemplate,
  generateTemplateComponent,
  openExternalLink,
}) => {
  const handleTryYourself = () => {
    generateTemplateComponent();
  };

  const openOnBoardingFigmaFile = () => {
    openExternalLink('https://www.figma.com/community/file/921066411285098081');
  };

  const openOnBoardingSketchFile = () => {
    openExternalLink('https://overlay-tech.com/onboarding');
  };

  return (
    <div className={styles.welcomeContainer}>
      <TransitionGroup className="transition-group">
        <CSSTransition key={location.key} timeout={{ enter: 800, exit: 10 }} classNames="fade">
          <section className={styles.transitionSection}>
            <Switch>
              <PrivateRoute
                path="/onboarding/sketch/welcome"
                component={SketchStepOne(goToPage('/onboarding/sketch/symbols'))}
              />
              <PrivateRoute
                path="/onboarding/sketch/symbols"
                component={SketchStepTwo(goToPage('/onboarding/sketch/naming'))}
              />
              <PrivateRoute
                path="/onboarding/sketch/naming"
                component={SketchStepThree(goToPage('/onboarding/sketch/layout'))}
              />
              <PrivateRoute
                path="/onboarding/sketch/layout"
                component={SketchStepFour(goToPage('/onboarding/sketch/stylesheet'))}
              />
              <PrivateRoute
                path="/onboarding/sketch/stylesheet"
                component={SketchStepFive(goToPage('/onboarding/sketch/assets'))}
              />
              <PrivateRoute
                path="/onboarding/sketch/assets"
                component={SketchStepSix(goToPage('/onboarding/sketch/try'))}
              />
              <PrivateRoute
                path="/onboarding/sketch/try"
                component={SketchStepSeven(handleTryYourself, isGeneratingTemplate)}
              />
              <PrivateRoute
                path="/onboarding/sketch/failed-import"
                component={SketchStepOnBoardingFailed(openOnBoardingSketchFile)}
              />
              <PrivateRoute
                path="/onboarding/figma/welcome"
                component={FigmaStepOne(goToPage('/onboarding/figma/symbols'))}
              />
              <PrivateRoute
                path="/onboarding/figma/symbols"
                component={FigmaStepTwo(goToPage('/onboarding/figma/naming'))}
              />
              <PrivateRoute
                path="/onboarding/figma/naming"
                component={FigmaStepThree(goToPage('/onboarding/figma/layout'))}
              />
              <PrivateRoute
                path="/onboarding/figma/layout"
                component={FigmaStepFour(goToPage('/onboarding/figma/stylesheet'))}
              />
              <PrivateRoute
                path="/onboarding/figma/stylesheet"
                component={FigmaStepFive(goToPage('/onboarding/figma/assets'))}
              />
              <PrivateRoute
                path="/onboarding/figma/assets"
                component={FigmaStepSix(goToPage('/onboarding/figma/try'))}
              />
              <PrivateRoute
                path="/onboarding/figma/try"
                component={FigmaStepSeven(handleTryYourself, isGeneratingTemplate)}
              />
              <PrivateRoute
                path="/onboarding/figma/failed-import"
                component={FigmaStepOnBoardingFailed(openOnBoardingFigmaFile)}
              />
            </Switch>
          </section>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default withRouter(OnBoarding);
