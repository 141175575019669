import { ApiLayer } from '@overlay-plugin/types/lib/ApiType';
import { createApiLayer } from 'services/Figma/Mapper/figmaMapper';
import {
  addOpacityProperties,
  addBorderProperties,
  addBorderRadiusProperties,
  addShadowProperties,
  addConstraintProperties,
  addFlexChildProperties,
  addBlurProperties,
  addBackgroundsProperties,
} from 'services/Figma/Mapper/styleMapper';
import { addSharedColorStyleProperties } from 'services/Figma/Mapper/sharedStyleMapper';
import {
  FigmaComponentNode,
  FigmaEllipseNode,
  FigmaFrameNode,
  FigmaGroupNode,
  FigmaInstanceNode,
  FigmaRectangleNode,
  isRectangleNode,
} from '@overlay-plugin/types/lib/FigmaType';

export const mapFigmaShapeToAPILayer = (
  rectangleNode: FigmaRectangleNode | FigmaEllipseNode,
  previousGroupPosition: { left: number; top: number },
  parentContainer?: FigmaComponentNode | FigmaFrameNode | FigmaGroupNode | FigmaInstanceNode,
): ApiLayer => {
  let layer = createApiLayer(rectangleNode, previousGroupPosition);

  layer = {
    ...layer,
    ...addSharedColorStyleProperties(rectangleNode),
  };

  layer.style = {
    ...layer.style,
    ...addOpacityProperties(rectangleNode),
    ...addBackgroundsProperties(rectangleNode, {
      width: layer.style.width,
      height: layer.style.height,
    }),
    ...addFlexChildProperties(rectangleNode),
    ...addBorderProperties(rectangleNode, layer.style),
    ...(isRectangleNode(rectangleNode) ? addBorderRadiusProperties(rectangleNode) : {}),
    ...addFlexChildProperties(rectangleNode),
    ...addShadowProperties(rectangleNode),
    ...addBlurProperties(rectangleNode),
    ...addConstraintProperties(rectangleNode, parentContainer),
  };

  return layer;
};
