import { FigmaRGB } from '@overlay-plugin/types/lib/FigmaType';

function intToHex(c: number) {
  let hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

export function colorToHex(color: FigmaRGB, opacity?: number) {
  let rgb =
    '#' +
    intToHex(Math.round(color.r * 255)) +
    intToHex(Math.round(color.g * 255)) +
    intToHex(Math.round(color.b * 255));

  if (undefined !== opacity) {
    rgb += intToHex(Math.round(opacity * 255));
  }

  return rgb;
}
