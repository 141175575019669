import { takeLatest, call, put, select, fork } from 'redux-saga/effects';
import { withLoader } from '../loading';
import { selectToken, withAuthentication } from '../authentication';
import { getProjects, createProject } from 'services/Interfaces/apiClient';
import { normalizedProjects } from 'services/API/apiNormalizer';
import { Reducer } from 'redux';
import { OverlayPluginStateType } from 'redux/type';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import {
  ProjectsStateType,
  ProjectType,
  TargetTechnologyEnum,
  UIFrameworkEnum,
} from 'modules/projects/types';
import { dialogCloseCreator, DialogKeys } from 'modules/dialogs';
import { DesignToolEnum } from '@overlay-plugin/types/lib/NativeClientInterface';
import * as Sentry from '@sentry/react';
import { displayErrorToaster } from 'modules/apiError';

const initialState: ProjectsStateType = {
  map: {},
  projectSelected: null,
  isProjectsLoaded: false,
};

// Actions Creators
export const getProjectsSuccessCreator = createStandardAction('PROJECT/GET_PROJECT.SUCCESS')<{
  projects: Record<string, ProjectType>;
}>();

export const createProjectsSuccessCreator = createStandardAction('PROJECT/CREATE_PROJECT.SUCCESS')<{
  project: ProjectType;
}>();

export const createProjectRequestCreator = createStandardAction('PROJECT/CREATE_PROJECT.REQUEST')<{
  name: string;
  teamUuid: string;
  targetTechnology: TargetTechnologyEnum;
  uiFramework: UIFrameworkEnum;
  designTool: DesignToolEnum;
}>();

export const selectProjectCreator = createStandardAction('PROJECT/SELECT_PROJECT')<{
  projectUuid: string;
}>();

// Selectors
export const selectProjects = (state: OverlayPluginStateType) => state.projects.map;

export const selectIsProjectsLoaded = (state: OverlayPluginStateType) =>
  state.projects.isProjectsLoaded;

export const selectSelectedProject = (state: OverlayPluginStateType) => {
  if (
    !state.projects.projectSelected ||
    !state.projects.map.hasOwnProperty(state.projects.projectSelected)
  ) {
    return null;
  }

  return state.projects.map[state.projects.projectSelected];
};

declare type ProjectsActions = ActionType<
  | typeof getProjectsSuccessCreator
  | typeof selectProjectCreator
  | typeof createProjectsSuccessCreator
>;

// Reducer
export const projectsReducer: Reducer<any, ProjectsActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(getProjectsSuccessCreator):
      const newState = {
        ...state,
        map: {
          ...action.payload.projects,
        },
        isProjectsLoaded: true,
      };
      if (Object.values(action.payload.projects).length > 0) {
        newState.projectSelected =
          state.projectSelected &&
          Object.keys(action.payload.projects).includes(state.projectSelected.toString())
            ? state.projectSelected
            : action.payload.projects[Object.keys(action.payload.projects)[0]].uuid;
      } else {
        newState.projectSelected = null;
      }
      return newState;
    case getType(selectProjectCreator):
      return {
        ...state,
        projectSelected: action.payload.projectUuid,
      };
    case getType(createProjectsSuccessCreator):
      return {
        ...state,
        map: {
          ...state.map,
          [action.payload.project.uuid]: action.payload.project,
        },
      };
    default:
      return state;
  }
};

// Sagas
export function* getProjectsSaga() {
  try {
    const token = yield select(selectToken);
    const { body } = yield call(getProjects, token);
    const { entities } = normalizedProjects(body);
    const projectMap = entities.hasOwnProperty('project') ? entities.project : {};
    yield put(getProjectsSuccessCreator({ projects: projectMap }));
  } catch (e) {
    Sentry.captureException(e);
    yield put(displayErrorToaster({ errorMessage: 'Could not get your projects' }));
  }
}

export function* createProjectSaga(action: ReturnType<typeof createProjectRequestCreator>) {
  const token = yield select(selectToken);
  try {
    const { body } = yield call(
      createProject,
      token,
      action.payload.name,
      action.payload.targetTechnology,
      action.payload.uiFramework,
      action.payload.designTool,
      action.payload.teamUuid,
    );
    yield put(
      createProjectsSuccessCreator({
        project: body,
      }),
    );
    yield put(
      selectProjectCreator({
        projectUuid: body.uuid,
      }),
    );
    yield put(dialogCloseCreator(DialogKeys.CREATE_PROJECT)());
  } catch (e) {
    Sentry.captureException(e);
    // The user don't have any project credit
    yield put(dialogCloseCreator(DialogKeys.CREATE_PROJECT)());
  }
}

// Saga Watchers
export function* watchCreateProject() {
  yield takeLatest(
    getType(createProjectRequestCreator),
    withLoader(withAuthentication(createProjectSaga), SimpleLoadingKeysEnum.createProject),
  );
}

export function* watchProjectSagas() {
  yield fork(watchCreateProject);
}
