import {
  isACustomShape,
  isAPolygonShape,
  isGroup,
  isShape,
  isSymbolInstance,
  isSymbolMaster,
  SketchGeneralLayer,
} from '@overlay-plugin/types/lib/SketchType';

export const optimize = (sketchLayer: SketchGeneralLayer): SketchGeneralLayer => {
  if (!isGroup(sketchLayer) && !isSymbolMaster(sketchLayer) && !isSymbolInstance(sketchLayer)) {
    return sketchLayer;
  }

  if (
    !sketchLayer.layers ||
    sketchLayer.layers.length === 0 ||
    sketchLayer.exportFormats.length > 0
  ) {
    return sketchLayer;
  }

  const vectorChildren = sketchLayer.layers.filter((child: SketchGeneralLayer) => {
    const isChildExportable = child.exportFormats.length > 0;
    return (
      (isACustomShape(child) || isAPolygonShape(child) || isShape(child)) && !isChildExportable
    );
  });

  // Make exportable
  const allTheChildrenAreVectors =
    vectorChildren.length === sketchLayer.layers.length && 1 !== vectorChildren.length;

  if (allTheChildrenAreVectors) {
    sketchLayer.exportFormats = [
      {
        fileFormat: 'svg',
        size: '1x',
      },
    ];
  }

  sketchLayer.layers = sketchLayer.layers.map(child => optimize(child));

  return sketchLayer;
};
