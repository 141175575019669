import React from 'react';
import styles from './Onboarding.module.scss';
import './welcomeTransition.scss';
import Button from 'components/Atoms/Button';

type Props = {
  title: string;
  description: React.ReactNode;
  stepNumber?: number;
  gotToNextStep: () => void;
  logo: React.ReactNode;
  isLoading?: boolean;
};

const Step: React.ComponentType<Props> = ({
  title,
  description,
  logo,
  stepNumber,
  gotToNextStep,
  isLoading,
}) => {
  return (
    <>
      {logo}
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      <div className={styles.slideBottomContainer}>
        <div className={styles.slideBottomInnerContainer}>
          <Button onClick={gotToNextStep} isLoading={isLoading}>
            Ok, let's go
          </Button>
          {stepNumber && <p className={styles.step}>Step {stepNumber}/7</p>}
        </div>
      </div>
    </>
  );
};

export default Step;
