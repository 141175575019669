import React from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import CircularProgress from '@material-ui/core/CircularProgress';

type Props = {
  onClick?: () => void;
  theme?: 'secondary';
  type?: 'submit' | 'reset' | 'button';
  className?: ClassValue;
  isLoading?: boolean;
  isDisabled?: boolean;
};

const Button: React.FunctionComponent<Props> = ({
  isLoading,
  isDisabled,
  onClick,
  children,
  type,
  theme,
  className,
}) => {
  const handleClick = () => {
    if (isLoading) return;
    if (isDisabled) return;
    if (onClick) onClick();
  };

  const renderLoader = () => <CircularProgress color={'inherit'} size={12} />;

  const renderButtonContent = () => <>{children}</>;

  return (
    <button
      type={type ? type : undefined}
      className={classNames(
        styles.button,
        !!theme && styles.secondary,
        !!isLoading && styles.disable,
        !!isDisabled && styles.disable,
        className,
      )}
      onClick={handleClick}
    >
      {isLoading ? renderLoader() : renderButtonContent()}
    </button>
  );
};

export default Button;
