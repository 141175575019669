import React from 'react';
import styles from './Preview.module.scss';
import exported from 'assets/rubiksCube/rubik-exported.png';
import Button from 'components/Atoms/Button';
import './previewTransition.scss';
import { openExternalLinkCreator } from 'modules/abstractDesignTool';
import Tips from 'components/Atoms/Tips';
import { DesignToolEnum } from '@overlay-plugin/types/lib/NativeClientInterface';
import { ProjectType } from 'modules/projects/types';

type Props = {
  selectedProject: ProjectType | null;
  componentId: string | null;
  openExternalLink: typeof openExternalLinkCreator;
  designTool: DesignToolEnum;
};

const Preview: React.ComponentType<Props> = ({
  selectedProject,
  componentId,
  openExternalLink,
  designTool,
}) => {
  const onPreviewClick = () => {
    if (!selectedProject) {
      openExternalLink({
        link: `${process.env.REACT_APP_WEB_URL}/project/`,
      });
      return;
    }

    openExternalLink({
      link: `${process.env.REACT_APP_WEB_URL}/project/${selectedProject.uuid}/component-sets/${componentId}`,
    });
  };

  return (
    <div className={styles.previewContainer}>
      <div className={styles.exportedComponent}>
        <div className={styles.innerContainer}>
          <img alt="" className={styles.illustration} src={exported} />
          <p className={styles.description}>Exported ! Well done :)</p>
          <Button onClick={onPreviewClick} className={styles.button}>
            See component
          </Button>
          <Tips className={styles.tipsContainer}>
            {designTool === DesignToolEnum.SKETCH && (
              <>
                Need to export quickly another component ? No need to close Overlay. Just select
                another component and tap <br />{' '}
                <strong className={styles.tipsShortcut}>^⇧O</strong> or{' '}
                <strong className={styles.tipsShortcut}>^⇧R</strong>
              </>
            )}
            {designTool === DesignToolEnum.FIGMA && (
              <>
                Need to export quickly another component ? No need to close Overlay. Just select
                another component and tap <br /> Mac:{' '}
                <strong className={styles.tipsShortcut}>⌘⌥P</strong> / Windows:{' '}
                <strong className={styles.tipsShortcut}>Alt+Ctrl+P</strong>
              </>
            )}
          </Tips>
        </div>
      </div>
    </div>
  );
};

export default Preview;
