import React from 'react';

import { ReactComponent as OverlayLogo } from 'assets/overlay-small-logo.svg';
import { ReactComponent as ComponentLogo } from 'assets/figma-component.svg';
import { ReactComponent as NamingLogo } from 'assets/naming.svg';
import { ReactComponent as LayoutLogo } from 'assets/layout.svg';
import { ReactComponent as AssetLogo } from 'assets/assets-figma.svg';
import { ReactComponent as StylesheetLogo } from 'assets/stylesheet.svg';
import { ReactComponent as ReadyLogo } from 'assets/ready.svg';
import { ReactComponent as ErrorLogo } from 'assets/error.svg';

import Step from './Step.component';
import styles from './Onboarding.module.scss';

type goToNextTypeFunction = () => void;

export const FigmaStepOne = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={'Welcome to Overlay'}
    description={
      <>
        Overlay is the only plugin to generate clean code from your{' '}
        <strong className={styles.sketchSymbol}>Component</strong>. Clean code also means clean
        design (good layout, correct naming etc..). If you agree, continue :)
      </>
    }
    stepNumber={1}
    gotToNextStep={goToNextStep}
    logo={<OverlayLogo className={styles.overlayLogo} />}
  />
);

export const FigmaStepTwo = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={'Components'}
    description={
      <>
        Overlay works only with <strong className={styles.sketchSymbol}>Components</strong>
        {'\n\n'}
        Why Components ? Because, maintainable code comes with an atomic design approach. Your
        components in Figma are your future components in production.
      </>
    }
    stepNumber={2}
    gotToNextStep={goToNextStep}
    logo={<ComponentLogo className={styles.symbolLogo} />}
  />
);

export const FigmaStepThree = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={'Naming'}
    description={
      <>
        Overlay uses Figma layers names to create CSS classes. Make sure your layers are well named.
        {'\n\n'}
        <strong className={styles.tips}>Tips</strong> : you don’t need to rename EVERYTHING.
        Identical layers (exact same style and margins) will be merged into a single class.
      </>
    }
    stepNumber={3}
    gotToNextStep={goToNextStep}
    logo={<NamingLogo className={styles.namingLogo} />}
  />
);

export const FigmaStepFour = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={'Layout'}
    description={
      <>
        Overlay wil convert your groups and layers into CSS classes. To create great components, you
        will have to think about layout.{'\n\n'}
        <strong className={styles.tips}>Tips</strong> : use Figma Auto layout and resizing options
        to test your component in real life before exporting it.
      </>
    }
    stepNumber={4}
    gotToNextStep={goToNextStep}
    logo={<LayoutLogo className={styles.namingLogo} />}
  />
);

export const FigmaStepFive = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={'Stylesheet'}
    description={
      'Overlay generates code using your style variables, not hardcoded values ! When exporting, store your variables into your Overlay project so it can generate maintainable code.'
    }
    stepNumber={5}
    gotToNextStep={goToNextStep}
    logo={<StylesheetLogo className={styles.namingLogo} />}
  />
);

export const FigmaStepSix = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={'“Export”'}
    description={
      <>
        Overlay generates {'<img>'} tags for all your assets (images, groups of vectors, even
        components instances). Make sure they are flagged as{' '}
        <strong className={styles.tips}>“exportable”</strong> in Figma.
      </>
    }
    stepNumber={6}
    gotToNextStep={goToNextStep}
    logo={<AssetLogo className={styles.assetLogo} />}
  />
);

export const FigmaStepSeven = (
  goToNextStep: goToNextTypeFunction,
  isGeneratingTemplate: boolean,
) => () => (
  <Step
    title={'Try a first component'}
    description={
      'Select a first component in your Figma file and open up the plugin to export it.\n' +
      '\n' +
      'Ready ?'
    }
    stepNumber={7}
    gotToNextStep={goToNextStep}
    logo={<ReadyLogo className={styles.namingLogo} />}
    isLoading={isGeneratingTemplate}
  />
);

export const FigmaStepOnBoardingFailed = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={"We couldn't generate the template component"}
    description={
      "You probably don't have write access in this file, but don't worry, we have made a special file just for you.\n" +
      '\n' +
      'Just duplicate it and export your first component.\n\n' +
      'After finishing this on-boarding, you will be able to use Overlay in EVERY Figma file.'
    }
    gotToNextStep={goToNextStep}
    logo={<ErrorLogo className={styles.namingLogo} />}
  />
);
