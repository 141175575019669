import { Reducer } from 'redux';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';
import { NormalizedApiLayer } from '@overlay-plugin/types/lib/ApiType';
import { mapComponentSuccessCreator } from 'modules/componentSet';
import { OverlayPluginStateType } from 'redux/type';

export type LayersStateType = {
  map: Record<string, NormalizedApiLayer>;
};

const initialState: LayersStateType = {
  map: {},
};

// Action creators
export const changeLayerNameCreator = createStandardAction('LAYER/CHANGE_LAYER_NAME')<{
  sketchId: string;
  newName: string;
}>();

declare type LayersActions = ActionType<
  typeof mapComponentSuccessCreator | typeof changeLayerNameCreator
>;

// Selectors
export const selectLayersMap = (
  state: OverlayPluginStateType,
): { [sketchId: string]: NormalizedApiLayer } => state.layers.map;

// Reducer
export const layersReducer: Reducer<LayersStateType, LayersActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getType(mapComponentSuccessCreator):
      return {
        ...state,
        map: {
          ...state.map,
          ...action.payload.layer,
        },
      };

    case getType(changeLayerNameCreator):
      return {
        ...state,
        map: {
          ...state.map,
          [action.payload.sketchId]: {
            ...state.map[action.payload.sketchId],
            name: action.payload.newName,
          },
        },
      };
    default:
      return state;
  }
};

// Sagas

// Saga exports
