import { call, put, select } from 'redux-saga/effects';
import { finishOnBoarding, getUserInfo, openPlugin } from 'services/Interfaces/apiClient';
import { User, UserStateType } from './types';
import { ActionType, createStandardAction, getType, Reducer } from 'typesafe-actions';
import { selectToken } from 'modules/authentication';
import { OverlayPluginStateType } from 'redux/type';
import { push } from 'connected-react-router';
import { selectDesignTool } from 'modules/abstractDesignTool';
import { DesignToolEnum } from '@overlay-plugin/types/lib/NativeClientInterface';
import * as Sentry from '@sentry/react';

const initialState: UserStateType = {
  currentUser: null,
};

// Actions Creators
export const updateUserRequestCreator = createStandardAction('USER/UPDATE.REQUEST')<{
  user: User;
}>();

export const meRequestCreator = createStandardAction('USER/ME.REQUEST')();

type UserActions = ActionType<typeof updateUserRequestCreator>;

// Selectors
export const selectCurrentUser = (store: OverlayPluginStateType) => store.user.currentUser;

// Reducer
export const userReducer: Reducer<UserStateType, UserActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(updateUserRequestCreator):
      return {
        ...state,
        currentUser: action.payload.user,
      };
    default:
      return state;
  }
};

// Sagas
export function* meSaga() {
  try {
    const token = yield select(selectToken);
    const designTool = yield select(selectDesignTool);
    const { body }: { body: User } = yield call(getUserInfo, token);
    yield put(updateUserRequestCreator({ user: body }));
    if (!body.hasAlreadyOpenPlugin) {
      yield call(openPlugin, token);
      body.hasAlreadyOpenPlugin = true;
    }

    if (!body.hasAlreadyUsedPlugin) {
      designTool === DesignToolEnum.FIGMA
        ? yield put(push('/onboarding/figma/welcome'))
        : yield put(push('/onboarding/sketch/welcome'));
    }

    Sentry.setUser({ email: body.email });
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'User logged in ' + body.email,
      level: Sentry.Severity.Info,
    });
  } catch (e) {
    Sentry.captureException(e);
  }
}

export function* finishOnBoardingSaga() {
  try {
    const token = yield select(selectToken);
    const { body }: { body: User } = yield call(finishOnBoarding, token);
    yield put(updateUserRequestCreator({ user: body }));
  } catch (e) {
    Sentry.captureException(e);
  }
}

// Saga Watchers

// Saga export
