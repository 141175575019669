import { flagComponentMarkedExportable } from 'services/API/apiMapper';
import { isSymbolMaster, SketchGeneralLayer } from '@overlay-plugin/types/lib/SketchType';

export const optimize = (sketchNode: SketchGeneralLayer): SketchGeneralLayer => {
  if (isSymbolMaster(sketchNode) && sketchNode.exportFormats.length > 0) {
    flagComponentMarkedExportable();
  }

  return sketchNode;
};
