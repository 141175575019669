import {
  ApiSharedStyleProperties,
  ApiSharedTextStyleProperties,
} from '@overlay-plugin/types/lib/ApiType';
import {
  getPaintBackgroundColor,
  getTextFontWeightStyle,
  getTextLineHeightStyle,
} from 'services/Figma/Helpers/styleHelpers';
import {
  isFontName,
  isFontSize,
  FigmaGeometryMixin,
  FigmaTextNode,
} from '@overlay-plugin/types/lib/FigmaType';

export const addSharedColorStyleProperties = (
  node: FigmaGeometryMixin,
): ApiSharedStyleProperties => {
  let sharedStyleProperties: ApiSharedStyleProperties = {
    colorStyles: [],
  };

  if (node.colorStyle.length > 0) {
    for (const colorStyle of node.colorStyle) {
      sharedStyleProperties.colorStyles.push({
        id: colorStyle.id,
        name: colorStyle.name,
        value: getPaintBackgroundColor(colorStyle.paints[0]),
      });
    }
  }

  return sharedStyleProperties;
};

export const addSharedTextStyleProperties = (node: FigmaTextNode): ApiSharedTextStyleProperties => {
  let sharedTextStyleProperties: ApiSharedTextStyleProperties = {
    typographyStyles: [],
  };

  if (node.typoStyle.length > 0) {
    for (const typoStyle of node.typoStyle) {
      sharedTextStyleProperties.typographyStyles.push({
        id: typoStyle.id,
        name: typoStyle.name,
        size: isFontSize(typoStyle.fontSize) ? Math.round(typoStyle.fontSize) : undefined,
        family: isFontName(typoStyle.fontName) ? typoStyle.fontName.family : undefined,
        weight: getTextFontWeightStyle(typoStyle).fontWeight,
        lineHeight: getTextLineHeightStyle(typoStyle),
      });
    }
  }

  return sharedTextStyleProperties;
};
