import { Reducer } from 'redux';
import { OverlayPluginStateType } from 'redux/type';
import { SketchGeneralLayer } from '@overlay-plugin/types/lib/SketchType';
import { FigmaNode } from '@overlay-plugin/types/lib/FigmaType';
import { DesignToolEnum } from '@overlay-plugin/types/lib/NativeClientInterface';
import { ApiWarningTypeEnum, Warning, WarningCollection } from 'types/warning';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { withLoader } from 'modules/loading';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { isAuthenticated, selectToken, withAuthentication } from 'modules/authentication';
import {
  exportVariant,
  getComponentByDesignToolIdAndProjectId,
  postValidateComponentSet,
} from 'services/Interfaces/apiClient';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';
import { denormalizedApiComponentSet, normalizedWarning } from 'services/API/apiNormalizer';
import { getProjectsSaga, selectProjectCreator, selectSelectedProject } from 'modules/projects';
import { setComponentIdInDbCreator } from 'modules/component';
import {
  ApiComponentSet,
  ApiLayerTypeEnum,
  NormalizedApiComponent,
  NormalizedApiComponentSet,
  NormalizedApiLayer,
} from '@overlay-plugin/types/lib/ApiType';
import { getTranslationContext, mapAndNormalizedDesignToolNode } from 'services/API/apiMapper';
import { validateAPILayer } from 'services/API/apiValidator';
import * as Sentry from '@sentry/react';
import { displayErrorToaster } from 'modules/apiError';
import {
  changeTranslationContextCreator,
  selectTranslationContext,
} from 'modules/translationContext';
import { selectLayersMap } from 'modules/layers';
import { meSaga, selectCurrentUser } from 'modules/user';
import { push } from 'connected-react-router';

export type ComponentSetStateType = {
  selectedComponentId?: string;
  componentNativeId: string | undefined;
  name: string;
  layersNameList: string[];
  isComponentValidated: boolean;
  map: Record<string, NormalizedApiComponentSet>;
  warnings: Record<string, Warning>;
};

const blockingWarnings = {
  [ApiWarningTypeEnum.INVALID_BORDER]: ApiWarningTypeEnum.INVALID_BORDER,
  [ApiWarningTypeEnum.MISSING_DEPENDENCY]: ApiWarningTypeEnum.MISSING_DEPENDENCY,
};

const initialState: ComponentSetStateType = {
  name: '',
  componentNativeId: undefined,
  layersNameList: [],
  isComponentValidated: false,
  map: {},
  warnings: {},
};

export const selectComponentCreator = createStandardAction('SELECT_COMPONENT/SELECT_COMPONENT')<{
  component: SketchGeneralLayer | FigmaNode;
  designTool: DesignToolEnum;
  componentNativeId: string | undefined;
}>();

export const mapComponentSuccessCreator = createStandardAction(
  'SELECT_COMPONENT/MAP_COMPONENT.SUCCESS',
)<{
  componentName: string;
  selectedComponentId: string;
  layer: Record<string, NormalizedApiLayer>;
  component: Record<string, NormalizedApiComponent>;
  componentSet: Record<string, NormalizedApiComponentSet>;
  componentNativeId: string | undefined;
}>();

export const transformLayerIntoImageLayerCreator = createStandardAction(
  'SELECT_COMPONENT/UPDATE_LAYER_FROM_NATIVE_LAYER',
)<{
  layerId: string;
  layer: SketchGeneralLayer | FigmaNode;
  designTool: DesignToolEnum;
}>();

export const initValidateComponentCreator = createStandardAction(
  'SELECT_COMPONENT/INIT_VALIDATE_COMPONENT',
)();

export const validateComponentCreator = createStandardAction(
  'SELECT_COMPONENT/VALIDATE_COMPONENT',
)();

export const removeWarningCreator = createStandardAction('SELECT_COMPONENT/REMOVE_WARNING')<{
  warningId: string;
}>();

export const addLayerNameCreator = createStandardAction('SELECT_COMPONENT/ADD_LAYER_NAME')<{
  layerName: string;
}>();

export const changeComponentNameCreator = createStandardAction(
  'SELECT_COMPONENT/CHANGE_COMPONENT_NAME',
)<{
  newName: string;
}>();

export const addWarningsCreator = createStandardAction('SELECT_COMPONENT/ADD_WARNINGS')<{
  warnings: Warning[];
  validationData: string[];
}>();

// Selectors
export const selectSelectedComponentSet = (
  state: OverlayPluginStateType,
): undefined | ApiComponentSet => {
  if (!state.componentSet.selectedComponentId) return undefined;
  return denormalizedApiComponentSet(
    state.componentSet.map[state.componentSet.selectedComponentId],
    state.componentSet.map,
    state.components.map,
    state.layers.map,
  );
};

export const selectComponentNativeId = (state: OverlayPluginStateType) =>
  state.componentSet.componentNativeId;

export const isComponentSelected = (state: OverlayPluginStateType) =>
  !!state.componentSet.selectedComponentId;

export const isComponentSelectedIsExportable = (state: OverlayPluginStateType) => {
  const componentSet: ApiComponentSet | undefined = selectSelectedComponentSet(state);
  const translationContext = selectTranslationContext(state);
  if (componentSet === undefined) return false;

  const defaultComponents = componentSet.children.filter(child => child.isDefaultComponent);

  if (!defaultComponents || defaultComponents.length !== 1) {
    return false;
  }

  const defaultComponent = defaultComponents[0];

  if (!defaultComponent) {
    return false;
  }

  return (
    defaultComponent.rootLayer.type === ApiLayerTypeEnum.IMAGE &&
    translationContext.isAComponentMarkedExportable
  );
};

export const isComponentValidated = (state: OverlayPluginStateType) =>
  state.componentSet.isComponentValidated;

export const selectSelectedComponentWarnings = (
  state: OverlayPluginStateType,
): WarningCollection => {
  const warningsArray: Warning[] = Object.values(state.componentSet.warnings);
  return warningsArray.reduce(
    (result: WarningCollection, item: Warning) => ({
      ...result,
      [item.message]: [...(result[item.message] || []), item],
    }),
    {},
  );
};

export const isComponentHasBlockingWarnings = (state: OverlayPluginStateType): boolean => {
  for (const warningId in state.componentSet.warnings) {
    if (blockingWarnings.hasOwnProperty(state.componentSet.warnings[warningId].message))
      return true;
  }
  return false;
};

export const selectLayerNameList = (state: OverlayPluginStateType) =>
  state.componentSet.layersNameList;

export const selectComponentName = (state: OverlayPluginStateType) => state.componentSet.name;

declare type ComponentSetActions = ActionType<
  | typeof selectComponentCreator
  | typeof addWarningsCreator
  | typeof removeWarningCreator
  | typeof removeWarningCreator
  | typeof addLayerNameCreator
  | typeof mapComponentSuccessCreator
  | typeof changeComponentNameCreator
>;

// Reducer
export const componentSetReducer: Reducer<ComponentSetStateType, ComponentSetActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getType(mapComponentSuccessCreator):
      return {
        ...state,
        name: action.payload.componentName,
        selectedComponentId: action.payload.selectedComponentId,
        componentNativeId: action.payload.componentNativeId,
        map: {
          ...state.map,
          ...action.payload.componentSet,
        },
      };

    case getType(addWarningsCreator):
      const warningNormalized = normalizedWarning(action.payload.warnings);
      return {
        ...state,
        warnings: warningNormalized.entities.warning || {},
        layersNameList: action.payload.validationData || [],
        isComponentValidated: true,
      };

    case getType(removeWarningCreator):
      const warningsRemaining = { ...state.warnings };
      delete warningsRemaining[action.payload.warningId];
      return {
        ...state,
        warnings: warningsRemaining,
      };

    case getType(addLayerNameCreator):
      return {
        ...state,
        layersNameList: [...state.layersNameList, action.payload.layerName],
      };

    case getType(changeComponentNameCreator):
      return {
        ...state,
        name: action.payload.newName,
      };

    default:
      return state;
  }
};

// Saga
export function* initValidateComponentSaga() {
  try {
    yield all([yield call(getProjectsSaga), yield call(meSaga)]);

    yield put(validateComponentCreator());
  } catch (e) {
    Sentry.captureException(e);
    yield put(displayErrorToaster({ errorMessage: 'Could not initialize the export' }));
  }
}

export function* validateComponentSaga() {
  const token = selectToken(yield select());
  const isUserAuthenticated = isAuthenticated(yield select());
  const currentUser = selectCurrentUser(yield select());
  const layerMap: Record<string, NormalizedApiLayer> = yield select(selectLayersMap);
  const selectedProject = selectSelectedProject(yield select());
  const componentSet = selectSelectedComponentSet(yield select());

  if (!token || !currentUser || !isUserAuthenticated || !componentSet || !selectedProject) {
    return null;
  }

  const defaultComponents = componentSet.children.filter(child => child.isDefaultComponent);

  if (!defaultComponents || defaultComponents.length !== 1) {
    return null;
  }

  const defaultComponent = defaultComponents[0];

  if (componentSet.type === 'VARIANT' && !currentUser.hasAlreadyExportVariant) {
    yield call(exportVariant, token);
    yield put(push('/variant/figma/welcome'));
    return;
  }

  try {
    const pluginWarnings = validateAPILayer(defaultComponent.rootLayer);
    const { body } = yield call(
      postValidateComponentSet,
      token,
      selectedProject.uuid,
      componentSet,
    );
    body.warnings = body.errors || [];
    const componentWarnings = [...body.warnings, ...pluginWarnings];
    body.validationData = body.validationData || [];
    componentWarnings.forEach((item: Warning) => {
      item.layers = item.data.map(sketchId => layerMap[sketchId]);
    }, {});
    yield put(
      addWarningsCreator({ warnings: componentWarnings, validationData: body.validationData }),
    );
  } catch (e) {
    Sentry.captureException(e);
    yield put(displayErrorToaster({ errorMessage: 'Could not validate your component' }));
  }
}

export function* selectComponentSaga(action: ReturnType<typeof selectComponentCreator>) {
  const layersNormalized = mapAndNormalizedDesignToolNode(
    action.payload.designTool,
    action.payload.component,
  );

  const translationContext = getTranslationContext();
  yield put(changeTranslationContextCreator({ translationContext }));
  yield put(
    mapComponentSuccessCreator({
      componentName: action.payload.component.name,
      selectedComponentId: action.payload.component.id,
      layer: layersNormalized.entities.layer,
      component: layersNormalized.entities.component,
      componentSet: layersNormalized.entities.componentSet,
      componentNativeId: action.payload.componentNativeId,
    }),
  );
}

export function* getExistingComponentSaga() {
  const token = selectToken(yield select());
  const selectedProject = selectSelectedProject(yield select());
  const isUserAuthenticated = isAuthenticated(yield select());
  const componentNativeId = selectComponentNativeId(yield select());

  if (!token || !isUserAuthenticated || !selectedProject || !componentNativeId) {
    return null;
  }

  try {
    const { body: matchingComponents } = yield call(
      getComponentByDesignToolIdAndProjectId,
      token,
      componentNativeId,
      selectedProject.uuid,
    );
    const hasAlreadyBeenExportedComponent = matchingComponents.length > 0;
    const componentUuidInDb = hasAlreadyBeenExportedComponent ? matchingComponents[0].uuid : null;
    const componentName = hasAlreadyBeenExportedComponent ? matchingComponents[0].name : null;
    if (null !== componentName) {
      yield put(changeComponentNameCreator({ newName: componentName }));
      Sentry.addBreadcrumb({
        category: 'Find an existing component',
        message: 'component : ' + componentUuidInDb,
        level: Sentry.Severity.Info,
      });
    }
    yield put(setComponentIdInDbCreator({ componentUuidInDb }));
  } catch (e) {
    Sentry.captureException(e);
    yield put(displayErrorToaster({ errorMessage: 'Could not fetch Overlay API' }));
  }
}

// Saga Watchers
export function* watchSelectComponent() {
  yield takeLatest(getType(selectComponentCreator), selectComponentSaga);
  yield takeLatest(
    getType(selectComponentCreator),
    withLoader(withAuthentication(validateComponentSaga), SimpleLoadingKeysEnum.validateComponent),
  );
}

export function* watchValidateComponent() {
  yield takeLatest(
    getType(validateComponentCreator),
    withLoader(withAuthentication(validateComponentSaga), SimpleLoadingKeysEnum.validateComponent),
  );
}

export function* watchInitValidateComponent() {
  yield takeLatest(
    getType(initValidateComponentCreator),
    withLoader(
      withAuthentication(initValidateComponentSaga),
      SimpleLoadingKeysEnum.initValidateComponent,
    ),
  );
}

export function* watchChangeProject() {
  yield takeLatest(
    getType(selectProjectCreator),
    withLoader(withAuthentication(validateComponentSaga), SimpleLoadingKeysEnum.validateComponent),
  );
}

// Saga exports
export function* watchSelectedComponentSagas() {
  yield fork(watchSelectComponent);
  yield fork(watchInitValidateComponent);
  yield fork(watchValidateComponent);
  yield fork(watchChangeProject);
}
