"use strict";

exports.__esModule = true;
exports.isAnSvg = exports.isSymbolMaster = exports.isSymbolInstance = exports.isGroup = exports.isContainer = exports.isText = exports.isImage = exports.isShape = exports.isShapePath = exports.isAPolygonShape = exports.isACustomShape = exports.SketchShapeTypeEnum = exports.SketchTypeEnum = void 0;
var SketchTypeEnum;

(function (SketchTypeEnum) {
  SketchTypeEnum["IMAGE"] = "Image";
  SketchTypeEnum["SHAPE"] = "Shape";
  SketchTypeEnum["SHAPE_PATH"] = "ShapePath";
  SketchTypeEnum["GROUP"] = "Group";
  SketchTypeEnum["SYMBOL_INSTANCE"] = "SymbolInstance";
  SketchTypeEnum["SYMBOL_MASTER"] = "SymbolMaster";
  SketchTypeEnum["TEXT"] = "Text";
  SketchTypeEnum["TEXT_ATTRIBUTE"] = "TextAttribute";
})(SketchTypeEnum = exports.SketchTypeEnum || (exports.SketchTypeEnum = {}));

var SketchShapeTypeEnum;

(function (SketchShapeTypeEnum) {
  SketchShapeTypeEnum["OVAL"] = "Oval";
  SketchShapeTypeEnum["CUSTOM"] = "Custom";
  SketchShapeTypeEnum["STAR"] = "Star";
  SketchShapeTypeEnum["POLYGON"] = "Polygon";
  SketchShapeTypeEnum["RECTANGLE"] = "Rectangle";
})(SketchShapeTypeEnum = exports.SketchShapeTypeEnum || (exports.SketchShapeTypeEnum = {}));

function isACustomShape(layer) {
  return layer.type === SketchTypeEnum.SHAPE_PATH && layer.shapeType === SketchShapeTypeEnum.CUSTOM;
}

exports.isACustomShape = isACustomShape;

function isAPolygonShape(layer) {
  return layer.type === SketchTypeEnum.SHAPE_PATH && layer.shapeType === SketchShapeTypeEnum.POLYGON;
}

exports.isAPolygonShape = isAPolygonShape;

function isShapePath(layer) {
  return layer.type === SketchTypeEnum.SHAPE_PATH;
}

exports.isShapePath = isShapePath;

function isShape(layer) {
  return layer.type === SketchTypeEnum.SHAPE;
}

exports.isShape = isShape;

function isImage(layer) {
  return layer.type === SketchTypeEnum.IMAGE;
}

exports.isImage = isImage;

function isText(layer) {
  return layer.type === SketchTypeEnum.TEXT;
}

exports.isText = isText;

function isContainer(layer) {
  return layer.type === SketchTypeEnum.GROUP || layer.type === SketchTypeEnum.SYMBOL_MASTER;
}

exports.isContainer = isContainer;

function isGroup(layer) {
  return layer.type === SketchTypeEnum.GROUP;
}

exports.isGroup = isGroup;

function isSymbolInstance(layer) {
  return layer.type === SketchTypeEnum.SYMBOL_INSTANCE;
}

exports.isSymbolInstance = isSymbolInstance;

function isSymbolMaster(layer) {
  return layer.type === SketchTypeEnum.SYMBOL_MASTER;
}

exports.isSymbolMaster = isSymbolMaster;

function isAnSvg(layer) {
  return layer.exportFormats.length > 0 && layer.exportFormats[0].fileFormat === 'svg';
}

exports.isAnSvg = isAnSvg;