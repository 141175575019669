import { ApiLayer, ApiTextBehaviorEnum, ApiLayerTypeEnum } from '@overlay-plugin/types/lib/ApiType';
import { createApiLayer } from 'services/Figma/Mapper/figmaMapper';
import {
  addBlurProperties,
  addConstraintProperties,
  addFlexChildProperties,
  addFontProperties,
  addOpacityProperties,
  addTextShadowProperties,
} from 'services/Figma/Mapper/styleMapper';
import {
  addSharedColorStyleProperties,
  addSharedTextStyleProperties,
} from 'services/Figma/Mapper/sharedStyleMapper';
import {
  FigmaComponentNode,
  FigmaFrameNode,
  FigmaGroupNode,
  FigmaInstanceNode,
  FigmaTextAttribute,
  FigmaTextNode,
} from '@overlay-plugin/types/lib/FigmaType';

const TEXT_BEHAVIOR_MAPPING: Record<string, ApiTextBehaviorEnum> = {
  HEIGHT: ApiTextBehaviorEnum.AUTO_HEIGHT,
  NONE: ApiTextBehaviorEnum.FIXED_SIZE,
  WIDTH_AND_HEIGHT: ApiTextBehaviorEnum.AUTO_WIDTH,
};

const TEXT_ALIGN_HORIZONTAL: Record<string, 'left' | 'center' | 'right' | 'justify'> = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  JUSTIFIED: 'justify',
};

const TEXT_ALIGN_VERTICAL: Record<string, 'top' | 'center' | 'bottom'> = {
  TOP: 'top',
  CENTER: 'center',
  BOTTOM: 'bottom',
};

export const mapFigmaTextToAPILayer = (
  figmaText: FigmaTextNode,
  previousGroupPosition: { left: number; top: number },
  parentContainer?: FigmaComponentNode | FigmaFrameNode | FigmaGroupNode | FigmaInstanceNode,
): ApiLayer => {
  let layer = createApiLayer(figmaText, previousGroupPosition);

  layer = {
    ...layer,
    ...addSharedColorStyleProperties(figmaText),
    ...addSharedTextStyleProperties(figmaText),
  };

  layer.text = figmaText.characters;
  layer.style = {
    ...layer.style,
    ...addFlexChildProperties(figmaText),
    ...addFontProperties(figmaText),
    ...addOpacityProperties(figmaText),
    ...addTextShadowProperties(figmaText),
    ...addBlurProperties(figmaText),
    ...addConstraintProperties(figmaText, parentContainer),
    textBehaviour: TEXT_BEHAVIOR_MAPPING[figmaText.textAutoResize],
  };

  layer.style.textAlign = TEXT_ALIGN_HORIZONTAL[figmaText.textAlignHorizontal];
  layer.style.textAlignVertical = TEXT_ALIGN_VERTICAL[figmaText.textAlignVertical];

  return layer;
};

export const mapFigmaTextWithAttributesToAPILayer = (
  figmaTextWithAttributes: FigmaTextNode,
  previousGroupPosition: { left: number; top: number },
  parentContainer?: FigmaComponentNode | FigmaFrameNode | FigmaGroupNode | FigmaInstanceNode,
): ApiLayer => {
  if (!figmaTextWithAttributes.textAttributes) {
    throw new Error("The text with attributes doesn't have any attribute");
  }

  let textWithAttributes = createApiLayer(figmaTextWithAttributes, previousGroupPosition);

  textWithAttributes = {
    ...textWithAttributes,
    ...addSharedColorStyleProperties(figmaTextWithAttributes),
    ...addSharedTextStyleProperties(figmaTextWithAttributes),
  };

  textWithAttributes.style = {
    ...textWithAttributes.style,
    ...addOpacityProperties(figmaTextWithAttributes),
    ...addFlexChildProperties(figmaTextWithAttributes),
    ...addTextShadowProperties(figmaTextWithAttributes),
    ...addBlurProperties(figmaTextWithAttributes),
    ...addConstraintProperties(figmaTextWithAttributes, parentContainer),
    textBehaviour: TEXT_BEHAVIOR_MAPPING[figmaTextWithAttributes.textAutoResize],
  };

  textWithAttributes.style.textAlign =
    TEXT_ALIGN_HORIZONTAL[figmaTextWithAttributes.textAlignHorizontal];

  textWithAttributes.style.textAlignVertical =
    TEXT_ALIGN_VERTICAL[figmaTextWithAttributes.textAlignVertical];

  const apiTextAttributes: ApiLayer[] = [];
  figmaTextWithAttributes.textAttributes.forEach((textAttribute, index) => {
    apiTextAttributes.push(
      mappedFigmaTextAttributesToApiTextAttribute(textWithAttributes, textAttribute, index),
    );
  });
  textWithAttributes.children = apiTextAttributes;

  return textWithAttributes;
};

export const mappedFigmaTextAttributesToApiTextAttribute = (
  parentTextLayer: ApiLayer,
  figmaTextAttribute: FigmaTextAttribute,
  index: number,
): ApiLayer => {
  const apiLayerName = figmaTextAttribute.name.substring(0, 40) + `_emphasis_${index}`;
  return {
    name: apiLayerName,
    text: figmaTextAttribute.text,
    type: ApiLayerTypeEnum.TEXT_ATTRIBUTES,
    style: {
      ...parentTextLayer.style,
      ...addFontProperties(figmaTextAttribute),
    },
    sketchId: apiLayerName + '_' + parentTextLayer.sketchId,
    asset: null,
    colorStyles: [],
    typographyStyles: [],
  };
};
