import { Reducer } from 'redux';
import { call, cancelled, put } from 'redux-saga/effects';
import { OverlayPluginStateType } from 'redux/type';
import {
  LoadingStateType,
  NestedLoadingKeysEnum,
  SimpleLoadingKeysEnum,
} from 'modules/loading/types';
import { ActionType, createAction, getType } from 'typesafe-actions';

const initialState: LoadingStateType = {
  ...Object.values(SimpleLoadingKeysEnum).reduce((state: any, value) => {
    state[value] = false;
    return state;
  }, {}),
  ...Object.values(NestedLoadingKeysEnum).reduce((state: any, value) => {
    state[value] = {};
    return state;
  }, {}),
};

// Action Creator
export const startLoadingActionCreator = createAction('LOADING/START', action => {
  return (key: SimpleLoadingKeysEnum) => action({ key });
});

export const stopLoadingActionCreator = createAction('LOADING/STOP', action => {
  return (key: SimpleLoadingKeysEnum) => action({ key });
});

export const startNestedLoadingActionCreator = createAction('LOADING/NESTED_START', action => {
  return (key: NestedLoadingKeysEnum, id: string) => action({ key, id });
});

export const stopNestedLoadingActionCreator = createAction('LOADING/NESTED_STOP', action => {
  return (key: NestedLoadingKeysEnum, id: string) => action({ key, id });
});

type LoadingActions = ActionType<
  | typeof startLoadingActionCreator
  | typeof startNestedLoadingActionCreator
  | typeof stopNestedLoadingActionCreator
  | typeof stopLoadingActionCreator
>;

// Selectors
export const isLoading = (store: OverlayPluginStateType, key: SimpleLoadingKeysEnum): boolean =>
  store.loading[key];

export const isNestedLoading = (
  store: OverlayPluginStateType,
  key: NestedLoadingKeysEnum,
  id: string,
): boolean => store.loading[key].hasOwnProperty(id) && store.loading[key][id];

export const isNestedLoadingActive = (
  store: OverlayPluginStateType,
  key: NestedLoadingKeysEnum,
): boolean =>
  Object.values(store.loading[key]).reduce((previousValue, key) => previousValue || key, false);

// Reducer
export const loadingReducer: Reducer<LoadingStateType, LoadingActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getType(startLoadingActionCreator):
      return {
        ...state,
        [action.payload.key]: true,
      };
    case getType(stopLoadingActionCreator):
      return {
        ...state,
        [action.payload.key]: false,
      };
    case getType(startNestedLoadingActionCreator):
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          [action.payload.id]: true,
        },
      };
    case getType(stopNestedLoadingActionCreator):
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          [action.payload.id]: false,
        },
      };
    default:
      return state;
  }
};

export const withLoader = (saga: any, key: SimpleLoadingKeysEnum) =>
  function*(...args: any[]) {
    yield put(startLoadingActionCreator(key));
    yield call(saga, args[0], args[1], args[2], args[3], args[4], args[5], args[6]);
    yield put(stopLoadingActionCreator(key));
  };

export const withNestedLoader = (saga: any, key: NestedLoadingKeysEnum, uids: string[]) =>
  function*(...args: any[]) {
    try {
      for (const uid of uids) {
        yield put(startNestedLoadingActionCreator(key, uid));
      }
      yield call(saga, args[0], args[1], args[2], args[3], args[4], args[5], args[6]);
      for (const uid of uids) {
        yield put(stopNestedLoadingActionCreator(key, uid));
      }
    } finally {
      // handle cancellations
      if (yield cancelled()) {
        for (const uid of uids) {
          yield put(stopNestedLoadingActionCreator(key, uid));
        }
      }
    }
  };
