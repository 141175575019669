export type LoadingStateType = Record<SimpleLoadingKeysEnum, boolean> &
  Record<NestedLoadingKeysEnum, Record<string, boolean>>;

export enum SimpleLoadingKeysEnum {
  initValidateComponent = 'initValidateComponent',
  validateComponent = 'validateComponent',
  generateTemplateComponent = 'generateTemplateComponent',
  extractBackground = 'extractBackground',
  getProjects = 'getProjects',
  createProject = 'createProject',
  uploadComponent = 'uploadComponent',
  validateStylesheet = 'validateStylesheet',
  updateStylesheet = 'updateStylesheet',
  getStylesheet = 'getStylesheet',
  login = 'login',
  pairing = 'pairing',
  getTeams = 'getTeams',
  gettingExistingComponent = 'gettingExistingComponent',
}

export enum NestedLoadingKeysEnum {
  addVariableToStylesheet = 'addVariableToStylesheet',
}
