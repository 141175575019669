/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux';
import { loadingReducer } from 'modules/loading/loading';
import { componentReducer } from 'modules/component';
import { componentSetReducer } from 'modules/componentSet';
import { abstractDesignToolReducer } from 'modules/abstractDesignTool';
import { dialogsReducer } from 'modules/dialogs';
import { projectsReducer } from 'modules/projects';
import { teamsReducer } from 'modules/teams';
import { stylesheetErrorReducer } from 'modules/stylesheetError';
import { layersReducer } from 'modules/layers';
import { authenticationReducer } from 'modules/authentication';
import { apiErrorReducer } from 'modules/apiError';
import { userReducer } from 'modules/user';
import { connectRouter } from 'connected-react-router';
import { pairingReducer } from 'modules/pairing';
import { translationContextReducer } from 'modules/translationContext';
import { History } from 'history';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
const createReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    loading: loadingReducer,
    user: userReducer,
    designTool: abstractDesignToolReducer,
    components: componentReducer,
    layers: layersReducer,
    stylesheetError: stylesheetErrorReducer,
    componentSet: componentSetReducer,
    projects: projectsReducer,
    teams: teamsReducer,
    authentication: authenticationReducer,
    pairing: pairingReducer,
    translationContext: translationContextReducer,
    apiError: apiErrorReducer,
    dialogs: dialogsReducer,
  });

export default createReducer;
