import React, { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { resetErrorsRequest } from 'modules/apiError';

interface IErrorToasterControllerProps {
  errorsToDisplay: string[];
  resetErrors: typeof resetErrorsRequest;
}

// This component is made for displaying error when the error state is changing
const ErrorToasterController: React.FunctionComponent<IErrorToasterControllerProps> = ({
  errorsToDisplay,
  resetErrors,
}) => {
  const { addToast } = useToasts();

  useEffect(() => {
    if (errorsToDisplay.length === 0) return;

    errorsToDisplay.forEach(error => {
      addToast(error);
    });

    resetErrors();
  }, [errorsToDisplay]);

  return null;
};

export default ErrorToasterController;
