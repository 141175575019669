import { connect } from 'react-redux';
import Playground from './Playground.component';
import { Dispatch } from 'redux';
import { openExternalLinkCreator, selectDesignTool } from 'modules/abstractDesignTool';
import { OverlayPluginStateType } from 'redux/type';

const mapStateToProps = (state: OverlayPluginStateType) => ({
  designTool: selectDesignTool(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openExternalLink: (link: string) => dispatch(openExternalLinkCreator({ link })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Playground);
