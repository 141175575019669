import { NormalizedTeamType, TeamStateType, TeamType } from 'modules/teams/types';
import { ActionType, createStandardAction, getType, Reducer } from 'typesafe-actions';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { withLoader } from 'modules/loading';
import { selectToken, withAuthentication } from 'modules/authentication';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { getMyTeams } from 'services/Interfaces/apiClient';
import { displayErrorToaster } from 'modules/apiError';
import * as Sentry from '@sentry/react';
import { denormalizeTeams, normalizeTeams } from 'services/API/apiNormalizer';
import { OverlayPluginStateType } from 'redux/type';

const initialState: TeamStateType = {
  map: {},
};

export const getMyTeamsRequestCreator = createStandardAction('TEAMS/GET_TEAMS.REQUEST')();

export const getMyTeamsSuccessCreator = createStandardAction('TEAMS/GET_TEAMS.SUCCESS')<{
  team: Record<string, NormalizedTeamType>;
}>();

export const selectTeams = (state: OverlayPluginStateType) =>
  denormalizeTeams(Object.values(state.teams.map), state.teams.map).team;

type TeamActions = ActionType<typeof getMyTeamsSuccessCreator>;

export const teamsReducer: Reducer<TeamStateType, TeamActions> = (
  state = initialState,
  action,
): TeamStateType => {
  switch (action.type) {
    case getType(getMyTeamsSuccessCreator):
      return {
        ...state,
        map: {
          ...state.map,
          ...action.payload.team,
        },
      };
    default:
      return state;
  }
};

// Sagas
export function* getMyTeamsSaga(action: ReturnType<typeof getMyTeamsRequestCreator>) {
  try {
    const token = yield select(selectToken);
    const { body }: { body: TeamType[] } = yield call(getMyTeams, token);
    const { entities } = normalizeTeams(body);
    yield put(
      getMyTeamsSuccessCreator({
        team: entities.team,
      }),
    );
  } catch (e) {
    yield put(
      displayErrorToaster({
        errorMessage: 'An error occurred while getting your teams.',
      }),
    );
    Sentry.captureException(e);
  }
}

// Saga watchers
function* watchGetMyTeams() {
  yield takeLatest(
    getType(getMyTeamsRequestCreator),
    withLoader(withAuthentication(getMyTeamsSaga), SimpleLoadingKeysEnum.getTeams),
  );
}

// Saga export
export function* watchTeamSagas() {
  yield fork(watchGetMyTeams);
}
