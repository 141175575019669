import { ApiLayer } from '@overlay-plugin/types/lib/ApiType';
import { createApiLayer, mapFigmaLayerToAPILayer } from 'services/Figma/Mapper/figmaMapper';
import {
  addFlexContainerProperties,
  addOpacityProperties,
  addShadowProperties,
} from 'services/Figma/Mapper/styleMapper';
import { isSingleBorder, setSingleBorder } from 'services/API/singleBorderOptimizer';
import { recomputeChildrenPositionAccordingToBorder } from 'services/API/borderOptimizer';
import {
  FigmaComponentNode,
  FigmaFrameNode,
  FigmaGroupNode,
  FigmaInstanceNode,
} from '@overlay-plugin/types/lib/FigmaType';

export const mapFigmaGroupToAPILayer = (
  figmaContainer: FigmaGroupNode,
  previousGroupPosition: { left: number; top: number },
  parentContainer?: FigmaComponentNode | FigmaFrameNode | FigmaGroupNode | FigmaInstanceNode,
): ApiLayer => {
  let layer = createApiLayer(figmaContainer, previousGroupPosition);

  layer.style = {
    ...layer.style,
    ...addOpacityProperties(figmaContainer),
    ...addShadowProperties(figmaContainer),
    ...addFlexContainerProperties(figmaContainer, parentContainer),
    ...addFlexContainerProperties(figmaContainer, parentContainer),
    ...{
      primaryAxisSizingMode: 'AUTO',
      counterAxisSizingMode: 'AUTO',
    },
  };

  if (!(figmaContainer.children.length > 0)) {
    return layer;
  }

  let totalWidthToRemove = 0;
  let totalHeightToRemove = 0;

  // Only group keep relative position from the component
  const previousPosition = {
    left: figmaContainer.x,
    top: figmaContainer.y,
  };

  const layerChildren = figmaContainer.children.reduce((layerChildren: ApiLayer[], child) => {
    if (!child.visible) {
      return layerChildren;
    }

    const childLayer = mapFigmaLayerToAPILayer(child, previousPosition, figmaContainer);
    const borderToSet = isSingleBorder(childLayer, layer);

    if (borderToSet) {
      let { widthToRemove, heightToRemove } = setSingleBorder(childLayer.style, layer, borderToSet);
      totalWidthToRemove += widthToRemove;
      totalHeightToRemove += heightToRemove;
    } else {
      layerChildren.push(childLayer);
    }

    return layerChildren;
  }, []);

  // We remove width after to allow multiple single borders
  layer.style.width -= totalWidthToRemove;
  layer.style.height -= totalHeightToRemove;

  if (layerChildren) {
    layer.children = layerChildren;
  }

  // We re-compute top / left / height / width
  recomputeChildrenPositionAccordingToBorder(layer);

  return layer;
};
