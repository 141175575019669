import { ApiLayer, ApiLayerTypeEnum } from '@overlay-plugin/types/lib/ApiType';
import { mapFigmaContainerToAPILayer } from 'services/Figma/Mapper/containerMapper';
import {
  mapFigmaTextToAPILayer,
  mapFigmaTextWithAttributesToAPILayer,
} from 'services/Figma/Mapper/textMapper';
import { mapFigmaShapeToAPILayer } from 'services/Figma/Mapper/shapeMapper';
import {
  mapFigmaImageWithoutStylePropertiesToApiLayer,
  mapFigmaImageWithStylePropertiesToApiLayer,
  mapFigmaSVGToApiLayer,
} from 'services/Figma/Mapper/imageMapper';
import {
  hasPaint,
  isAnImagePaint,
  FigmaNode,
  FigmaNodeType,
  FigmaComponentNode,
  FigmaFrameNode,
  FigmaGroupNode,
  FigmaInstanceNode,
  isSliceNode,
} from '@overlay-plugin/types/lib/FigmaType';
import { mapFigmaLineToAPILayer } from 'services/Figma/Mapper/lineMapper';
import { mapFigmaGroupToAPILayer } from 'services/Figma/Mapper/groupMapper';

export const convertFigmaTypeToApiLayerType = (figmaType: FigmaNodeType): ApiLayerTypeEnum => {
  switch (figmaType) {
    case 'COMPONENT':
      return ApiLayerTypeEnum.SYMBOL_MASTER;
    case 'ELLIPSE':
      return ApiLayerTypeEnum.SHAPE;
    case 'FRAME':
      return ApiLayerTypeEnum.GROUP;
    case 'GROUP':
      return ApiLayerTypeEnum.GROUP;
    case 'INSTANCE':
      return ApiLayerTypeEnum.SYMBOL_INSTANCE;
    case 'LINE':
      return ApiLayerTypeEnum.SHAPE;
    case 'RECTANGLE':
      return ApiLayerTypeEnum.SHAPE;
    case 'TEXT':
      return ApiLayerTypeEnum.TEXT;
    case 'SLICE':
      return ApiLayerTypeEnum.SHAPE;
    case 'BOOLEAN_OPERATION':
      return ApiLayerTypeEnum.SHAPE;
    case 'VECTOR':
      return ApiLayerTypeEnum.SHAPE;
    case 'STAR':
      return ApiLayerTypeEnum.SHAPE;
    case 'POLYGON':
      return ApiLayerTypeEnum.SHAPE;
    case 'COMPONENT_SET':
      return ApiLayerTypeEnum.SHAPE;
    default:
      return ApiLayerTypeEnum.SHAPE;
  }
};

export const createApiLayer = (
  component: FigmaNode,
  previousGroupPosition: { left: number; top: number },
  type?: ApiLayerTypeEnum,
): ApiLayer => {
  const style = {
    height: Math.round((component.height + Number.EPSILON) * 100) / 100,
    width: Math.round((component.width + Number.EPSILON) * 100) / 100,
    left: 0,
    top: 0,
  };

  const left = component.x - previousGroupPosition.left;
  const top = component.y - previousGroupPosition.top;
  style.left = Math.round((left + Number.EPSILON) * 100) / 100;
  style.top = Math.round((top + Number.EPSILON) * 100) / 100;

  return {
    name: component.name.substring(0, 40),
    type: type ? type : convertFigmaTypeToApiLayerType(component.type),
    sketchId: component.id,
    style,
    asset: null,
    colorStyles: [],
    typographyStyles: [],
  };
};

export const mapFigmaLayerToAPILayer = (
  figmaNode: FigmaNode,
  previousGroupPosition: { left: number; top: number },
  parentContainer?: FigmaComponentNode | FigmaFrameNode | FigmaGroupNode | FigmaInstanceNode,
): ApiLayer => {
  const figmaNodeIsFlaggedAsExportable = figmaNode.exportSettings.length > 0;

  const figmaNodeIsFlaggedAsMask = !isSliceNode(figmaNode) && figmaNode.isMask;

  if (
    figmaNodeIsFlaggedAsExportable &&
    (figmaNode.exportSettings[0].format === 'SVG' ||
      (figmaNode.type !== 'RECTANGLE' && figmaNode.type !== 'ELLIPSE'))
  ) {
    return mapFigmaImageWithoutStylePropertiesToApiLayer(
      figmaNode,
      previousGroupPosition,
      parentContainer,
      figmaNode.exportSettings,
    );
  }

  if (figmaNodeIsFlaggedAsMask) {
    return mapFigmaImageWithoutStylePropertiesToApiLayer(
      figmaNode,
      previousGroupPosition,
      parentContainer,
      [
        {
          format: 'PNG',
        },
      ],
    );
  }

  switch (figmaNode.type) {
    case 'COMPONENT':
    case 'FRAME':
    case 'INSTANCE':
      return mapFigmaContainerToAPILayer(figmaNode, previousGroupPosition, parentContainer);
    case 'GROUP':
      return mapFigmaGroupToAPILayer(figmaNode, previousGroupPosition, parentContainer);
    case 'VECTOR':
    case 'BOOLEAN_OPERATION':
    case 'STAR':
    case 'POLYGON':
      return mapFigmaSVGToApiLayer(figmaNode, previousGroupPosition, parentContainer);
    case 'TEXT':
      return figmaNode.textAttributes.length > 0
        ? mapFigmaTextWithAttributesToAPILayer(figmaNode, previousGroupPosition, parentContainer)
        : mapFigmaTextToAPILayer(figmaNode, previousGroupPosition, parentContainer);
    case 'LINE':
      return mapFigmaLineToAPILayer(figmaNode, previousGroupPosition, parentContainer);
    case 'RECTANGLE':
    case 'ELLIPSE':
      // Image are rectangles with image fill in Figma file
      const layer =
        (hasPaint(figmaNode.fills) &&
          figmaNode.fills.length === 1 &&
          isAnImagePaint(figmaNode.fills[figmaNode.fills.length - 1])) ||
        figmaNodeIsFlaggedAsExportable
          ? mapFigmaImageWithStylePropertiesToApiLayer(
              figmaNode,
              previousGroupPosition,
              parentContainer,
              figmaNode.exportSettings,
            )
          : mapFigmaShapeToAPILayer(figmaNode, previousGroupPosition, parentContainer);

      if (figmaNode.type === 'ELLIPSE') {
        layer.style.borderRadiusTopLeft = '50%';
        layer.style.borderRadiusTopRight = '50%';
        layer.style.borderRadiusBottomRight = '50%';
        layer.style.borderRadiusBottomLeft = '50%';
      }

      return layer;
    default:
      // tslint:disable no-console
      console.log(`Node of type ${figmaNode.type} not handled`);
      return createApiLayer(figmaNode, previousGroupPosition);
  }
};
