import { connect } from 'react-redux';
import Welcome from './Variant.component';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import { OverlayPluginStateType } from 'redux/type';

const mapStateToProps = (state: OverlayPluginStateType) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  goToPage: (pageName: string) => () => dispatch(push(pageName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
