import React from 'react';
import styles from './Variant.module.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './welcomeTransition.scss';
import PrivateRoute from 'components/HightOrderComponents/PrivateRoute';
import { withRouter, RouteComponentProps, Switch } from 'react-router-dom';

import { VariantStepOne, VariantStepTwo, VariantStepThree } from './VariantSteps';

interface IProps extends RouteComponentProps<any, any, any> {
  goToPage: (path: string) => () => void;
}

const Variant: React.FunctionComponent<IProps> = ({ goToPage, location }) => {
  return (
    <div className={styles.welcomeContainer}>
      <TransitionGroup className="transition-group">
        <CSSTransition key={location.key} timeout={{ enter: 800, exit: 10 }} classNames="fade">
          <section className={styles.transitionSection}>
            <Switch>
              <PrivateRoute
                path="/variant/figma/welcome"
                component={VariantStepOne(goToPage('/variant/figma/explanation'))}
              />
              <PrivateRoute
                path="/variant/figma/explanation"
                component={VariantStepTwo(goToPage('/variant/figma/ready'))}
              />
              <PrivateRoute
                path="/variant/figma/ready"
                component={VariantStepThree(goToPage('/'))}
              />
            </Switch>
          </section>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default withRouter(Variant);
