import { colorToHex } from './conversion';
import { FONT_STYLE_ITALIC } from './constants';
import {
  isFontName,
  hasPaint,
  isLineHeightValue,
  FigmaTextAttribute,
  FigmaTextNode,
  FigmaTypographyStyle,
  FigmaSolidPaint,
} from '@overlay-plugin/types/lib/FigmaType';

export const getFontWeightFromFontStyle = (fontStyle: string) => {
  switch (fontStyle) {
    case 'thin':
    case 'hairline':
      return 100;
    case 'extralight':
    case 'ultralight':
      return 200;
    case 'light':
      return 300;
    case 'normal':
    case 'regular':
      return 400;
    case 'medium':
      return 500;
    case 'semibold':
    case 'demibold':
      return 600;
    case 'bold':
      return 700;
    case 'extrabold':
    case 'ultrabold':
    case 'heavy':
      return 800;
    case 'black':
      return 900;
    case 'extrablack':
    case 'ultrablack':
      return 950;
    default:
      return 400;
  }
};

export const getTextFontColorStyle = (component: FigmaTextNode | FigmaTextAttribute) => {
  const defaultStyle = '#000000';
  if (!hasPaint(component.fills)) return defaultStyle;

  const paint = component.fills[0];
  if (paint.type !== 'SOLID') return defaultStyle;

  return colorToHex(paint.color, paint.opacity);
};

export const getTextFontWeightStyle = (
  component: FigmaTextNode | FigmaTextAttribute | FigmaTypographyStyle,
): { fontWeight: number; fontStyle?: 'italic' } => {
  if (!isFontName(component.fontName)) return { fontWeight: 400 };

  const textStyle = component.fontName.style;

  // No italic style
  if (textStyle.split('Italic').length === 1)
    return { fontWeight: getFontWeightFromFontStyle(textStyle.replace(/ /g, '').toLowerCase()) };

  const explodedTextStyle = textStyle.split(' Italic');

  if (explodedTextStyle.length === 1) return { fontWeight: 400, fontStyle: FONT_STYLE_ITALIC };

  return {
    fontWeight: getFontWeightFromFontStyle(explodedTextStyle[0].replace(/ /g, '').toLowerCase()),
    fontStyle: FONT_STYLE_ITALIC,
  };
};

export const getTextLineHeightStyle = (
  component: FigmaTextNode | FigmaTextAttribute | FigmaTypographyStyle,
) => {
  if (!isLineHeightValue(component.lineHeight)) return null;

  if (component.lineHeight.unit === 'PIXELS') return Math.round(component.lineHeight.value);

  // Percentage font size
  return Math.round((component.lineHeight.value / 100) * component.fontSize);
};

export const getLetterSpacingStyle = (component: FigmaTextNode | FigmaTextAttribute) => {
  if (!isLineHeightValue(component.letterSpacing)) return undefined;

  if (component.letterSpacing.unit === 'PIXELS')
    return Math.round((component.letterSpacing.value + Number.EPSILON) * 100) / 100;

  // Percentage font size
  return (
    Math.round(
      ((component.letterSpacing.value / 100) * component.fontSize + Number.EPSILON) * 100,
    ) / 100
  );
};

export const getPaintBackgroundColor = (paint: FigmaSolidPaint) => {
  return colorToHex(paint.color, paint.opacity);
};
