import mapValues from 'lodash/mapValues';
import { Reducer } from 'redux';
import { OverlayPluginStateType } from 'redux/type';

export enum DialogKeys {
  UPDATE = 'UPDATE',
  VALIDATION = 'VALIDATION',
  CREATE_PROJECT = 'CREATE_PROJECT',
  SUBSCRIBE = 'SUBSCRIBE',
  STYLE_LIMITATION_VARIABLE = 'STYLE_LIMITATION_VARIABLE',
}

export type DialogsStateType = {
  isOpen: Record<DialogKeys, boolean>;
};

const initialState: DialogsStateType = {
  isOpen: mapValues(DialogKeys, () => false) as Record<DialogKeys, boolean>,
};

const actionTypes = {
  DIALOGS: {
    CLOSE: 'DIALOGS.CLOSE',
    OPEN: 'DIALOGS.OPEN',
  },
};

// Actions Creators
export const dialogCloseCreator = (dialog: DialogKeys) => () => ({
  type: actionTypes.DIALOGS.CLOSE,
  dialog,
});

export const dialogOpenCreator = (dialog: DialogKeys) => () => ({
  type: actionTypes.DIALOGS.OPEN,
  dialog,
});

// Selectors
export const isDialogOpen = (state: OverlayPluginStateType, dialog: DialogKeys) =>
  state.dialogs.isOpen[dialog];

export const isAnyDialogOpen = (state: OverlayPluginStateType) =>
  Object.values(state.dialogs.isOpen).some(isOpen => isOpen);

// Reducer
export const dialogsReducer: Reducer<DialogsStateType> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DIALOGS.CLOSE:
      return {
        ...state,
        isOpen: {
          ...state.isOpen,
          [action.dialog]: false,
        },
      };
    case actionTypes.DIALOGS.OPEN:
      return {
        ...state,
        isOpen: {
          ...state.isOpen,
          [action.dialog]: true,
        },
      };
    default:
      return state;
  }
};
