"use strict"; // Object type for the Overlay API

exports.__esModule = true;
exports.ApiLinterErrorTypeEnum = exports.ApiStylesheetValidationErrorEnum = exports.ApiTextDecorationEnum = exports.ApiTextTransformEnum = exports.ApiTextBehaviorEnum = exports.ApiExportFormatEnum = exports.ApiDesignToolEnum = exports.ApiLayerTypeEnum = void 0;
var ApiLayerTypeEnum;

(function (ApiLayerTypeEnum) {
  ApiLayerTypeEnum["IMAGE"] = "Image";
  ApiLayerTypeEnum["SHAPE"] = "Shape";
  ApiLayerTypeEnum["GROUP"] = "Group";
  ApiLayerTypeEnum["SYMBOL_INSTANCE"] = "SymbolInstance";
  ApiLayerTypeEnum["SYMBOL_MASTER"] = "SymbolMaster";
  ApiLayerTypeEnum["TEXT_ATTRIBUTES"] = "TextAttributes";
  ApiLayerTypeEnum["TEXT"] = "Text";
})(ApiLayerTypeEnum = exports.ApiLayerTypeEnum || (exports.ApiLayerTypeEnum = {}));

var ApiDesignToolEnum;

(function (ApiDesignToolEnum) {
  ApiDesignToolEnum["SKETCH"] = "SKETCH";
  ApiDesignToolEnum["FIGMA"] = "FIGMA";
})(ApiDesignToolEnum = exports.ApiDesignToolEnum || (exports.ApiDesignToolEnum = {}));

var ApiExportFormatEnum;

(function (ApiExportFormatEnum) {
  ApiExportFormatEnum["SVG"] = "SVG";
  ApiExportFormatEnum["PNG"] = "PNG";
  ApiExportFormatEnum["JPG"] = "JPG";
})(ApiExportFormatEnum = exports.ApiExportFormatEnum || (exports.ApiExportFormatEnum = {}));

var ApiTextBehaviorEnum;

(function (ApiTextBehaviorEnum) {
  ApiTextBehaviorEnum["AUTO_WIDTH"] = "Auto Width";
  ApiTextBehaviorEnum["AUTO_HEIGHT"] = "Auto Height";
  ApiTextBehaviorEnum["FIXED_SIZE"] = "Fixed Size";
})(ApiTextBehaviorEnum = exports.ApiTextBehaviorEnum || (exports.ApiTextBehaviorEnum = {}));

var ApiTextTransformEnum;

(function (ApiTextTransformEnum) {
  ApiTextTransformEnum["UPPERCASE"] = "uppercase";
  ApiTextTransformEnum["LOWERCASE"] = "lowercase";
})(ApiTextTransformEnum = exports.ApiTextTransformEnum || (exports.ApiTextTransformEnum = {}));

var ApiTextDecorationEnum;

(function (ApiTextDecorationEnum) {
  ApiTextDecorationEnum["LINE_THROUGH"] = "line-through";
  ApiTextDecorationEnum["UNDERLINE"] = "underline";
})(ApiTextDecorationEnum = exports.ApiTextDecorationEnum || (exports.ApiTextDecorationEnum = {}));

var ApiStylesheetValidationErrorEnum;

(function (ApiStylesheetValidationErrorEnum) {
  ApiStylesheetValidationErrorEnum["UNKNOWN_COLOR"] = "UNKNOWN_COLOR";
  ApiStylesheetValidationErrorEnum["UNKNOWN_TYPOGRAPHY"] = "UNKNOWN_TYPOGRAPHY";
})(ApiStylesheetValidationErrorEnum = exports.ApiStylesheetValidationErrorEnum || (exports.ApiStylesheetValidationErrorEnum = {}));

var ApiLinterErrorTypeEnum;

(function (ApiLinterErrorTypeEnum) {
  ApiLinterErrorTypeEnum["TEXT_UNSUPPORTED_TEXT_BEHAVIOR"] = "TEXT_UNSUPPORTED_TEXT_BEHAVIOR";
  ApiLinterErrorTypeEnum["UNSUPPORTED_BORDER_ALIGN"] = "UNSUPPORTED_BORDER_ALIGN";
})(ApiLinterErrorTypeEnum = exports.ApiLinterErrorTypeEnum || (exports.ApiLinterErrorTypeEnum = {}));