import {
  ApiBackground,
  ApiComponent,
  ApiLayer,
  ApiLayerTypeEnum,
} from '@overlay-plugin/types/lib/ApiType';
import {
  PluginImageResponse,
  ExportCommand,
  Base64Image,
} from '@overlay-plugin/types/lib/NativeClientInterface';
import { v4 as uuidv4 } from 'uuid';
import { uploadAssets } from 'services/Interfaces/apiClient';

export const findNativeImageLayerAndUploadThemOnServer = async (
  apiComponents: ApiComponent[],
  projectId: string,
  token: string,
  getBase64Images: (nativeLayerExportCommands: ExportCommand[]) => Promise<PluginImageResponse[]>,
) => {
  let exportCommands: ExportCommand[] = [];
  apiComponents.forEach(apiComponent => {
    exportCommands = [
      ...exportCommands,
      ...extractExportCommandFromApiLayer(apiComponent.rootLayer),
    ];
  });

  if (exportCommands.length === 0) {
    return;
  }

  const imageMap: Record<number, PluginImageResponse> = await getBase64Images(exportCommands);

  if (Object.values(imageMap).length > 0) {
    const apiPayload: Record<string, Base64Image> = Object.values(imageMap).reduce(
      (map: Record<string, Base64Image>, imageResponse) => {
        map[imageResponse.command.uuid] = imageResponse.image;
        return map;
      },
      {},
    );

    const { body: assetsURI }: { body: Record<string, number> } = await uploadAssets(
      apiPayload,
      projectId,
      token,
    );

    const exportCommandMappedByLayerId: Record<
      string,
      Array<{ command: ExportCommand; assetUri: string }>
    > = Object.values(imageMap).reduce(
      (map: Record<string, Array<{ command: ExportCommand; assetUri: string }>>, imageResponse) => {
        if (!assetsURI.hasOwnProperty(imageResponse.command.uuid)) {
          return map;
        }

        if (!map.hasOwnProperty(imageResponse.command.layerId)) {
          map[imageResponse.command.layerId] = [];
        }

        map[imageResponse.command.layerId].push({
          command: imageResponse.command,
          assetUri: `/api/assets/${assetsURI[imageResponse.command.uuid]}`,
        });
        return map;
      },
      {},
    );

    apiComponents.forEach(apiComponent => {
      addAssetId(exportCommandMappedByLayerId, apiComponent.rootLayer);
    });
  }

  return;
};

const extractExportCommandFromApiLayer = (layer: ApiLayer): ExportCommand[] => {
  let exportCommands: ExportCommand[] = [];

  if (layer.type === ApiLayerTypeEnum.IMAGE && layer.exportSetting) {
    exportCommands.push({
      uuid: uuidv4(),
      layerId: layer.sketchId,
      type: 'image',
      exportSetting: layer.exportSetting,
    });
  }

  if (layer.style.backgrounds) {
    layer.style.backgrounds.forEach(background => {
      if ('image' !== background.type) {
        return;
      }

      exportCommands.push({
        uuid: uuidv4(),
        layerId: layer.sketchId,
        type: 'backgroundImage',
        backgroundImageId: background.backgroundImageNativeId,
        exportSetting: background.exportSetting,
      });
    });
  }

  if (!layer.children || layer.children.length === 0) {
    return exportCommands;
  }

  for (const child of layer.children) {
    exportCommands = [...exportCommands, ...extractExportCommandFromApiLayer(child)];
  }

  return exportCommands;
};

const addAssetId = (
  assetsURI: Record<string, Array<{ command: ExportCommand; assetUri: string }>>,
  layer: ApiLayer,
) => {
  if (layer.children) {
    for (const child of layer.children) {
      addAssetId(assetsURI, child);
    }
  }

  // Image layer
  if (
    layer.type === ApiLayerTypeEnum.IMAGE &&
    assetsURI.hasOwnProperty(layer.sketchId) &&
    assetsURI[layer.sketchId].length === 1
  ) {
    layer.asset = assetsURI[layer.sketchId][0].assetUri;
  }

  // Background Image
  if (
    layer.type !== ApiLayerTypeEnum.IMAGE &&
    layer.style.backgrounds &&
    assetsURI.hasOwnProperty(layer.sketchId) &&
    assetsURI[layer.sketchId].length > 0
  ) {
    layer.style.backgrounds.forEach((background: ApiBackground) => {
      if ('image' !== background.type) {
        return;
      }

      const foundAsset = assetsURI[layer.sketchId].find(
        asset =>
          'backgroundImage' === asset.command.type &&
          asset.command.backgroundImageId === background.backgroundImageNativeId,
      );

      if (!foundAsset) {
        return;
      }

      background.backgroundImage = foundAsset.assetUri;
    });
  }
};
