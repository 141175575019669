import { connect } from 'react-redux';
import PrivateRoute from './PrivateRoute.component';
import { isAuthenticated } from 'modules/authentication';
import { OverlayPluginStateType } from 'redux/type';

const mapStateToProps = (state: OverlayPluginStateType) => ({
  isAuthenticated: isAuthenticated(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
