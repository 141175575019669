import { SketchGeneralLayer, SketchImage, SketchShape } from '@overlay-plugin/types/lib/SketchType';
import { ApiExportFormatEnum, ApiLayer, ApiLayerTypeEnum } from '@overlay-plugin/types/lib/ApiType';
import { createApiLayer } from 'services/Sketch/Mapper/sketchMapper';
import {
  addBlurProperties,
  addBorderProperties,
  addBorderRadiusProperties,
  addOpacityProperties,
  addShadowProperties,
  addTransformProperties,
} from 'services/Sketch/Mapper/styleMapper';

const formatMappingForAPI: Record<string, ApiExportFormatEnum> = {
  png: ApiExportFormatEnum.PNG,
  jpg: ApiExportFormatEnum.PNG,
  webp: ApiExportFormatEnum.PNG,
  eps: ApiExportFormatEnum.PNG,
  tiff: ApiExportFormatEnum.PNG,
  pdf: ApiExportFormatEnum.PNG,
  svg: ApiExportFormatEnum.SVG,
};

export const mapSketchImageWithStylePropertiesToApiLayer = (
  sketchImage: SketchImage | SketchShape,
): ApiLayer => {
  const layer = createApiLayer(sketchImage, ApiLayerTypeEnum.IMAGE);
  const hasExportSettings = sketchImage.exportFormats && sketchImage.exportFormats.length > 0;

  layer.exportSetting = {
    format: hasExportSettings
      ? formatMappingForAPI[sketchImage.exportFormats[0].fileFormat]
      : ApiExportFormatEnum.PNG,
    snapshotIsWithStyleProperties: false,
    snapshotWithChildren: true,
  };

  layer.style = {
    ...layer.style,
    ...addTransformProperties(sketchImage.transform),
    ...addOpacityProperties(sketchImage.style),
    ...addBorderProperties(sketchImage.style, layer.style),
    ...addShadowProperties(sketchImage.style),
    ...addBorderRadiusProperties(sketchImage.style),
    ...addBlurProperties(sketchImage.style),
  };

  return layer;
};

export const mapSketchImageWithoutStylePropertiesToApiLayer = (
  sketchImage: SketchGeneralLayer,
): ApiLayer => {
  const layer = createApiLayer(sketchImage, ApiLayerTypeEnum.IMAGE);

  layer.style = {
    ...layer.style,
    ...addTransformProperties(sketchImage.transform),
  };

  const hasExportSettings = sketchImage.exportFormats && sketchImage.exportFormats.length > 0;
  layer.exportSetting = {
    format: hasExportSettings
      ? formatMappingForAPI[sketchImage.exportFormats[0].fileFormat]
      : ApiExportFormatEnum.PNG,
    snapshotIsWithStyleProperties: true,
    snapshotWithChildren: true,
  };

  return layer;
};

export const mapSketchSVGToApiLayer = (sketchCustomShape: SketchShape): ApiLayer => {
  const layer = createApiLayer(sketchCustomShape, ApiLayerTypeEnum.IMAGE);

  layer.exportSetting = {
    format: ApiExportFormatEnum.SVG,
    snapshotIsWithStyleProperties: true,
    snapshotWithChildren: true,
  };

  layer.style = {
    ...layer.style,
    ...addTransformProperties(sketchCustomShape.transform),
  };

  return layer;
};
