import React from 'react';
import { SketchGeneralLayer } from '@overlay-plugin/types/lib/SketchType';
import { DesignToolEnum } from '@overlay-plugin/types/lib/NativeClientInterface';
import { selectComponentCreator } from 'modules/componentSet/componentSet';
import * as Sentry from '@sentry/react';

type Props = {
  selectComponent: typeof selectComponentCreator;
};

declare var window: {
  sketchSelectElement: (component: SketchGeneralLayer, componentBase64Preview: string) => void;
};

class SketchBridge extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    window.sketchSelectElement = (component: SketchGeneralLayer) => {
      // tslint:disable no-console
      console.log(`
          const overlayElement = ${JSON.stringify(component)}
          window.sketchSelectElement(overlayElement)
        `);

      Sentry.addBreadcrumb({
        category: 'Select sketch symbol',
        message: 'Select sketch symbol',
        data: {
          component,
        },
        level: Sentry.Severity.Info,
      });
      this.props.selectComponent({
        component,
        designTool: DesignToolEnum.SKETCH,
        componentNativeId: component.symbolId,
      });
    };
  }

  render = () => null; // This component is a high order component, it renders nothing
}

export default SketchBridge;
