import { Reducer } from 'redux';
import { withLoader } from 'modules/loading';
import { call, takeLatest, put, select, fork, race, take } from 'redux-saga/effects';
import {
  checkPairing,
  refreshToken as refreshTokenRequest,
  startPairing,
} from 'services/Interfaces/apiClient';
import { parseJwt } from 'services/jwtParser';
import { OverlayPluginStateType } from 'redux/type';
import { SimpleLoadingKeysEnum } from 'modules/loading/types';
import { createStandardAction, getType } from 'typesafe-actions';
import {
  pairingErrorCreator,
  loginErrorsMapper,
  cleanPairingErrorRequestCreator,
} from 'modules/pairing';
import { delay } from 'redux-saga';
import { openExternalLinkCreator, selectDesignTool } from 'modules/abstractDesignTool';
import { DesignToolEnum } from '@overlay-plugin/types/lib/NativeClientInterface';

export type AuthenticationStateType = {
  token?: string;
  refreshToken?: string;
  email?: string;
  expire?: number;
};

const initialState: AuthenticationStateType = {};

const actionTypes = {
  LOGOUT: {
    REQUEST: 'LOGOUT.REQUEST',
  },
};

// Actions Creators
export const pairingRequestCreator = createStandardAction('AUTHENTICATION/PAIRING.REQUEST')();

export const checkPairingRequestCreator = createStandardAction(
  'AUTHENTICATION/CHECK_PAIRING.REQUEST',
)<{
  pairingToken: string;
}>();

export const checkPairingResponseCreator = createStandardAction(
  'AUTHENTICATION/CHECK_PAIRING.RESPONSE',
)<{
  status: 'pending' | 'ok';
  token: string;
  refreshToken: string;
}>();

export const loginSuccessCreator = createStandardAction('AUTHENTICATION/LOGIN.SUCCESS')<{
  token: string;
  refreshToken: string;
  email: string;
  expire: number;
}>();

export const logoutRequestCreator = () => ({
  type: actionTypes.LOGOUT.REQUEST,
});

// Selectors
export const isAuthenticated = (store: OverlayPluginStateType) => !!store.authentication.token;
export const selectToken = (store: OverlayPluginStateType) => store.authentication.token;
export const selectRefreshToken = (store: OverlayPluginStateType) =>
  store.authentication.refreshToken;
export const selectIsTokenExpired = (store: OverlayPluginStateType) => {
  // We add one minutes to don't had the token expire during the calls
  return store.authentication.expire ? store.authentication.expire < Date.now() + 60000 : false;
};

// Reducer
export const authenticationReducer: Reducer<AuthenticationStateType> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getType(loginSuccessCreator):
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        email: action.payload.email,
        expire: action.payload.expire,
      };
    case actionTypes.LOGOUT.REQUEST:
      return initialState;
    default:
      return state;
  }
};

// Sagas
export function* pairingSaga(action: ReturnType<typeof pairingRequestCreator>) {
  try {
    const { body }: any = yield call(startPairing);
    const designTool: DesignToolEnum = yield select(selectDesignTool);
    const pairingUrl = `${process.env.REACT_APP_WEB_URL}/pairing/${body.uuid}/${designTool}`;
    yield put(cleanPairingErrorRequestCreator({ pairingUrl }));
    yield put(
      openExternalLinkCreator({
        link: pairingUrl,
      }),
    );
    yield call(startPollingPairingSaga, body.uuid);
  } catch (error) {
    yield put(
      pairingErrorCreator({
        pairingError: loginErrorsMapper.default,
      }),
    );
  }
}

export function* checkPairingSaga(action: ReturnType<typeof checkPairingRequestCreator>) {
  try {
    const { body }: any = yield call(checkPairing, action.payload.pairingToken);
    const refreshToken = body.refreshToken ? body.refreshToken : '';
    const token = body.token ? body.token : '';
    yield put(checkPairingResponseCreator({ status: body.status, refreshToken, token }));
  } catch (error) {
    yield put(
      pairingErrorCreator({
        pairingError: loginErrorsMapper.default,
      }),
    );
  }
}

function* startPollingPairingSaga(pairingToken: string) {
  const { timeout } = yield race({
    response: call(checkJobStatus, pairingToken),
    timeout: call(delay, 30000),
  });

  // handle failure scenario
  if (timeout) {
    yield put(
      pairingErrorCreator({
        pairingError: loginErrorsMapper.timeout,
      }),
    );
  }
}

function* checkJobStatus(pairingToken: string) {
  let jobSucceeded = false;
  while (!jobSucceeded) {
    yield put(checkPairingRequestCreator({ pairingToken }));
    const pollingAction: ReturnType<typeof checkPairingResponseCreator> = yield take(
      getType(checkPairingResponseCreator),
    );
    const pollingStatus = pollingAction.payload.status;
    switch (pollingStatus) {
      case 'ok':
        jobSucceeded = true;
        const token = pollingAction.payload.token;
        const refreshToken = pollingAction.payload.refreshToken;
        let { email, exp } = parseJwt(token);
        exp = exp * 1000; // Convert Unix timestamps in milliseconds timestamp
        yield put(loginSuccessCreator({ token, refreshToken, email, expire: exp }));
        break;
      case 'pending':
        break;
      default:
        break;
    }

    // delay the next polling request in 2 second
    yield call(delay, 2000);
  }
}

export function* refreshTokenSaga() {
  try {
    const refreshToken = yield select(selectRefreshToken);
    const { body } = yield call(refreshTokenRequest, refreshToken);
    const token = body.token;
    let { email, exp } = parseJwt(token);
    exp = exp * 1000; // Convert Unix timestamps in milliseconds timestamp
    yield put(loginSuccessCreator({ token, refreshToken, email, expire: exp }));
  } catch (e) {
    yield put(logoutRequestCreator());
  }
}

// Sagas Decorator

export const withAuthentication = (saga: any) =>
  function*(...args: any[]) {
    const isTokenExpired = yield select(selectIsTokenExpired);
    if (isTokenExpired) {
      try {
        yield call(refreshTokenSaga);
        yield call(saga, args[0], args[1], args[2], args[3], args[4], args[5], args[6]);
      } catch {
        yield put(logoutRequestCreator());
      }
    } else {
      yield call(saga, args[0], args[1], args[2], args[3], args[4], args[5], args[6]);
    }
  };

// Saga Watchers
function* watchStartPairing() {
  yield takeLatest(
    getType(pairingRequestCreator),
    withLoader(pairingSaga, SimpleLoadingKeysEnum.pairing),
  );
}

function* watchCheckPairing() {
  yield takeLatest(getType(checkPairingRequestCreator), checkPairingSaga);
}

// Saga exports
export function* watchAuthenticationSagas() {
  yield fork(watchStartPairing);
  yield fork(watchCheckPairing);
}
