import { Reducer } from 'redux';
import { OverlayPluginStateType } from 'redux/type';
import { TranslationContextStateType } from './types';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';

const initialState: TranslationContextStateType = {
  isAComponentMarkedExportable: false,
};

// Action Creator
export const changeTranslationContextCreator = createStandardAction('TRANSLATION_CONTEXT/START')<{
  translationContext: TranslationContextStateType;
}>();

type LoadingActions = ActionType<typeof changeTranslationContextCreator>;

// Selectors
export const selectTranslationContext = (store: OverlayPluginStateType) => store.translationContext;

// Reducer
export const translationContextReducer: Reducer<TranslationContextStateType, LoadingActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getType(changeTranslationContextCreator):
      return {
        ...state,
        ...action.payload.translationContext,
      };
    default:
      return state;
  }
};
