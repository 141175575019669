import React from 'react';

import { ReactComponent as OverlayLogo } from 'assets/overlay-small-logo.svg';
import { ReactComponent as SymbolLogo } from 'assets/symbol.svg';
import { ReactComponent as NamingLogo } from 'assets/naming.svg';
import { ReactComponent as LayoutLogo } from 'assets/layout.svg';
import { ReactComponent as AssetLogo } from 'assets/assets-sketch.svg';
import { ReactComponent as StylesheetLogo } from 'assets/stylesheet.svg';
import { ReactComponent as ReadyLogo } from 'assets/ready.svg';
import { ReactComponent as ErrorLogo } from 'assets/error.svg';

import Step from './Step.component';
import styles from './Onboarding.module.scss';

type goToNextTypeFunction = () => void;

export const SketchStepOne = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={'Welcome to Overlay'}
    description={
      <>
        Overlay is the only plugin to generate clean code from your{' '}
        <strong className={styles.sketchSymbol}>Symbols</strong>. Clean code also means clean design
        (good layout, correct naming etc..). If you agree, continue :)
      </>
    }
    stepNumber={1}
    gotToNextStep={goToNextStep}
    logo={<OverlayLogo className={styles.overlayLogo} />}
  />
);

export const SketchStepTwo = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={'Symbols'}
    description={
      <>
        Overlay works only with <strong className={styles.sketchSymbol}>Symbols</strong>
        {'\n\n'}
        Why Symbols ? Because, maintainable code comes with an atomic design approach. Your Symbols
        in Sketch are your future components in production.
      </>
    }
    stepNumber={2}
    gotToNextStep={goToNextStep}
    logo={<SymbolLogo className={styles.symbolLogo} />}
  />
);

export const SketchStepThree = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={'Naming'}
    description={
      <>
        Overlay uses Sketch layers names to create CSS classes. Make sure your layers are well
        named.{'\n\n'}
        <strong className={styles.tips}>Tips</strong> : you don’t need to rename EVERYTHING.
        Identical layers (exact same style and margins) will be merged into a single class.
      </>
    }
    stepNumber={3}
    gotToNextStep={goToNextStep}
    logo={<NamingLogo className={styles.namingLogo} />}
  />
);

export const SketchStepFour = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={'Layout'}
    description={
      <>
        Overlay will convert your groups and layers into CSS classes. To create great components,
        you will have to think about layout.{'\n\n'}
        <strong className={styles.tips}>Tips</strong> : use Sketch Smart layout and resizing options
        to test your component in real life before exporting it.
      </>
    }
    stepNumber={4}
    gotToNextStep={goToNextStep}
    logo={<LayoutLogo className={styles.namingLogo} />}
  />
);

export const SketchStepFive = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={'Stylesheet'}
    description={
      'Overlay generates code using your style variables, not hardcoded values ! When exporting, store your variables into your Overlay project so it can generate maintainable code.'
    }
    stepNumber={5}
    gotToNextStep={goToNextStep}
    logo={<StylesheetLogo className={styles.namingLogo} />}
  />
);

export const SketchStepSix = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={'“Make exportable”'}
    description={
      <>
        Overlay generates {'<img>'} tags for all your assets (images, groups of vectors, even
        components instances). Make sure they are flagged as{' '}
        <strong className={styles.tips}>“Make exportable”</strong> in Sketch.
      </>
    }
    stepNumber={6}
    gotToNextStep={goToNextStep}
    logo={<AssetLogo className={styles.assetLogo} />}
  />
);

export const SketchStepSeven = (
  goToNextStep: goToNextTypeFunction,
  isGeneratingTemplate: boolean,
) => () => (
  <Step
    title={'Try a first component'}
    description={
      'Select a first component in your Sketch file and open up the plugin to export it.\n' +
      '\n' +
      'Ready ?'
    }
    stepNumber={7}
    gotToNextStep={goToNextStep}
    logo={<ReadyLogo className={styles.namingLogo} />}
    isLoading={isGeneratingTemplate}
  />
);

export const SketchStepOnBoardingFailed = (goToNextStep: goToNextTypeFunction) => () => (
  <Step
    title={"We couldn't generate the template component"}
    description={
      "Don't worry, we have made a special file just for you.\n" +
      '\n' +
      'After finishing this on-boarding, you will be able to use Overlay in EVERY Sketch file.'
    }
    gotToNextStep={goToNextStep}
    logo={<ErrorLogo className={styles.namingLogo} />}
  />
);
