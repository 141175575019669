import {
  SketchFill,
  SketchFrame,
  SketchShadow,
  SketchStyle,
  SketchTextAttributeStyle,
  SketchTextStyle,
  SketchTransform,
} from '@overlay-plugin/types/lib/SketchType';
import {
  ApiStyle,
  ApiTextDecorationEnum,
  ApiBorderProperties,
  ApiBorderRadiusProperties,
  ApiFontProperties,
  ApiOpacityProperties,
  ApiShadow,
  ApiShadowProperties,
  ApiTextShadowProperties,
  ApiTransformProperties,
  ApiBlurProperties,
  ApiBackgroundProperties,
  ApiBackground,
  ApiGradientBackground,
  ApiColorBackground,
  ApiImageBackground,
  ApiExportFormatEnum,
} from '@overlay-plugin/types/lib/ApiType';
import { computeLinearGradient } from 'services/API/linearGradientComputer';

export const addBackgroundsProperties = (sketchStyle: SketchStyle, sketchFrame: SketchFrame) => {
  let backgroundProperties: ApiBackgroundProperties = {};

  if (!sketchStyle.fills || sketchStyle.fills.length === 0) return {};

  const filteredFills: SketchFill[] = sketchStyle.fills.filter(
    (fill: SketchFill) =>
      (fill.fillType === 'Gradient' || fill.fillType === 'Color' || fill.fillType === 'Pattern') &&
      fill.enabled,
  );

  if (0 === filteredFills.length) {
    return {};
  }

  backgroundProperties.backgrounds = filteredFills
    .map((fill, index) => {
      switch (fill.fillType) {
        case 'Gradient':
          return createLinearGradientProperty(fill, index, sketchFrame);
        case 'Color':
          return createBackgroundColorProperty(fill, index);
        case 'Pattern':
          return createBackgroundImageProperty(fill, index);
        default:
          return null;
      }
    })
    .filter((background: ApiBackground | null): background is any => null !== background);

  return backgroundProperties;
};

export const createBackgroundColorProperty = (
  fill: SketchFill,
  order: number,
): ApiColorBackground | null => {
  // Sketch v60.1
  if (!fill || !fill.enabled || fill.fillType !== 'Color' || !fill.color) return null;

  return {
    type: 'color',
    color: fill.color,
    order,
  };
};

export const createBackgroundImageProperty = (
  fill: SketchFill,
  order: number,
): ApiImageBackground | null => {
  // Sketch v60.1
  if (
    !fill ||
    !fill.enabled ||
    fill.fillType !== 'Pattern' ||
    !fill.pattern ||
    !fill.pattern.image ||
    !fill.pattern.image.id
  )
    return null;

  return {
    type: 'image',
    backgroundImage: null,
    backgroundImageNativeId: fill.pattern.image.id.toString(),
    order,
    exportSetting: {
      format: ApiExportFormatEnum.PNG,
      snapshotIsWithStyleProperties: false,
      snapshotWithChildren: false,
    },
  };
};

export const createLinearGradientProperty = (
  fill: SketchFill,
  order: number,
  sketchFrame: SketchFrame,
): ApiGradientBackground | null => {
  if (!fill || !fill.enabled || !fill.gradient || fill.fillType !== 'Gradient') return null;

  const startingPoint = {
    x: fill.gradient.from.x,
    y: fill.gradient.from.y,
  };

  const endingPoint = {
    x: fill.gradient.to.x,
    y: fill.gradient.to.y,
  };

  const apiGradientStops = fill.gradient.stops.map((gradientStop, index) => ({
    position: gradientStop.position,
    color: gradientStop.color,
    order: index,
  }));

  return {
    order,
    ...computeLinearGradient(
      startingPoint,
      endingPoint,
      sketchFrame.width,
      sketchFrame.height,
      apiGradientStops,
    ),
  };
};

export const addShadowProperties = (sketchStyle: SketchStyle): ApiShadowProperties => {
  let shadowProperties: ApiShadowProperties = {};
  let shadows: ApiShadow[] = [];
  let innerShadows: ApiShadow[] = [];

  if (!sketchStyle.shadows && !sketchStyle.innerShadows) {
    return {};
  }

  const visibleShadows: SketchShadow[] = !!sketchStyle.shadows
    ? sketchStyle.shadows.filter(shadow => shadow.enabled)
    : [];

  const visibleInnerShadows: SketchShadow[] = !!sketchStyle.innerShadows
    ? sketchStyle.innerShadows.filter(shadow => shadow.enabled)
    : [];

  if (visibleShadows.length === 0 && visibleInnerShadows.length === 0) return {};

  shadows = visibleShadows.map(shadow => {
    return {
      type: 'default',
      shadowColor: shadow.color,
      shadowSpread: shadow.spread,
      shadowVerticalOffset: shadow.y,
      shadowHorizontalOffset: shadow.x,
      shadowBlur: shadow.blur,
    };
  });

  innerShadows = visibleInnerShadows.map(shadow => {
    return {
      type: 'inset',
      shadowColor: shadow.color,
      shadowSpread: shadow.spread,
      shadowVerticalOffset: shadow.y,
      shadowHorizontalOffset: shadow.x,
      shadowBlur: shadow.blur,
    };
  });

  shadowProperties.boxShadows = [...shadows, ...innerShadows];
  return shadowProperties;
};

export const addBlurProperties = (sketchStyle: SketchStyle): ApiBlurProperties => {
  let blurProperties: ApiBlurProperties = {};

  if (!sketchStyle.blur) return blurProperties;

  const sketchBlur = sketchStyle.blur;

  if (!sketchBlur.enabled) return blurProperties;

  if (sketchBlur.blurType === 'Background') {
    blurProperties.backgroundBlur = sketchBlur.radius;
  }

  return blurProperties;
};

export const addBorderProperties = (
  sketchStyle: SketchStyle,
  apiStyle: ApiStyle,
): ApiBorderProperties => {
  let borderProperties: ApiBorderProperties = {};

  if (!sketchStyle.borders || sketchStyle.borders.length === 0) return borderProperties;

  const border = sketchStyle.borders[0];

  if (!border.enabled) return borderProperties;

  const borderMap: Record<'Inside' | 'Outside' | 'Center', 'INSIDE' | 'CENTER' | 'OUTSIDE'> = {
    Inside: 'INSIDE',
    Outside: 'OUTSIDE',
    Center: 'CENTER',
  };

  borderProperties.borderPosition = borderMap[border.position];

  if (border.position !== 'Inside') return borderProperties;

  const style: Record<string, any> = {};

  const borderThickness = Math.round(border.thickness);
  borderProperties.borderTopThickness = borderThickness;
  borderProperties.borderBottomThickness = borderThickness;
  borderProperties.borderLeftThickness = borderThickness;
  borderProperties.borderRightThickness = borderThickness;
  borderProperties.borderTopColor = border.color;
  borderProperties.borderLeftColor = border.color;
  borderProperties.borderBottomColor = border.color;
  borderProperties.borderRightColor = border.color;
  style.width = Math.max(apiStyle.width - 2 * borderThickness, 0);
  style.height = Math.max(apiStyle.height - 2 * borderThickness, 0);

  return {
    ...style,
    ...borderProperties,
  };
};

export const addBorderRadiusProperties = (sketchStyle: SketchStyle): ApiBorderRadiusProperties => {
  let borderRadiusProperties: ApiBorderRadiusProperties = {};
  if (!sketchStyle.borderRadius) return borderRadiusProperties;

  const borderRadius = sketchStyle.borderRadius;

  const assignBorderProperty = (property: number | string) =>
    typeof property === 'number' ? Math.round(property) + 'px' : property;

  if (borderRadius.bottomLeft)
    borderRadiusProperties.borderRadiusBottomLeft = assignBorderProperty(borderRadius.bottomLeft);
  if (borderRadius.bottomRight)
    borderRadiusProperties.borderRadiusBottomRight = assignBorderProperty(borderRadius.bottomRight);
  if (borderRadius.topLeft)
    borderRadiusProperties.borderRadiusTopLeft = assignBorderProperty(borderRadius.topLeft);
  if (borderRadius.topRight)
    borderRadiusProperties.borderRadiusTopRight = assignBorderProperty(borderRadius.topRight);

  return borderRadiusProperties;
};

export const addOpacityProperties = (sketchStyle: SketchStyle): ApiOpacityProperties => {
  let opacityProperties: ApiOpacityProperties = {};

  if (sketchStyle.opacity) {
    opacityProperties.opacity = Number.parseFloat(sketchStyle.opacity.toPrecision(2));
  }

  return opacityProperties;
};

export const addTextShadowProperties = ({ shadows }: SketchTextStyle): ApiTextShadowProperties => {
  let textShadowProperties: ApiTextShadowProperties = {};

  if (!shadows || !shadows[0] || !shadows[0].enabled) return textShadowProperties;

  return {
    textShadowColor: shadows[0].color,
    textShadowBlur: shadows[0].blur,
    textShadowVerticalOffset: shadows[0].y,
    textShadowHorizontalOffset: shadows[0].x,
  };
};

export const addTransformProperties = (transformStyle: SketchTransform): ApiTransformProperties => {
  return {
    flippedHorizontally: transformStyle.flippedHorizontally,
    flippedVertically: transformStyle.flippedVertically,
  };
};

export const addFontProperties = (sketchStyle: SketchTextStyle): ApiFontProperties => {
  let fontProperties: ApiFontProperties = {};

  fontProperties.fontFamily = sketchStyle.fontFamily;
  fontProperties.fontSize = Math.round(sketchStyle.fontSize);
  fontProperties.fontWeight = sketchStyle.fontWeight;
  fontProperties.color = sketchStyle.textColor;
  fontProperties.lineHeight = sketchStyle.lineHeight ? Math.round(sketchStyle.lineHeight) : null;
  fontProperties.letterSpacing = sketchStyle.kerning
    ? Math.round((sketchStyle.kerning + Number.EPSILON) * 100) / 100
    : 0;
  fontProperties.textTransform =
    sketchStyle.textTransform !== 'none' ? sketchStyle.textTransform : null;

  if (sketchStyle.fontStyle) fontProperties.fontStyle = sketchStyle.fontStyle;

  if (sketchStyle.textStrikethrough)
    fontProperties.textDecoration = ApiTextDecorationEnum.LINE_THROUGH;
  if (sketchStyle.textUnderline) fontProperties.textDecoration = ApiTextDecorationEnum.UNDERLINE;

  return fontProperties;
};

export const addTextAttributeFontProperties = (
  sketchTextAttributesStyle: SketchTextAttributeStyle,
): ApiFontProperties => {
  let fontProperties: ApiFontProperties = {};

  fontProperties.fontFamily = sketchTextAttributesStyle.fontFamily;
  fontProperties.fontSize = Math.round(sketchTextAttributesStyle.fontSize);
  fontProperties.fontWeight = sketchTextAttributesStyle.fontWeight;
  fontProperties.color = sketchTextAttributesStyle.textColor;
  fontProperties.lineHeight = sketchTextAttributesStyle.lineHeight
    ? Math.round(sketchTextAttributesStyle.lineHeight)
    : null;
  fontProperties.letterSpacing = sketchTextAttributesStyle.kerning
    ? Math.round(sketchTextAttributesStyle.kerning)
    : 0;

  return fontProperties;
};
