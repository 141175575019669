import { Reducer } from 'redux';
import {
  ApiValidationErrorColor,
  ApiValidationErrorTypography,
} from '@overlay-plugin/types/lib/ApiType';
import { getType } from 'typesafe-actions';
import keyBy from 'lodash/keyBy';
import {
  FrontVariableErrorEnum,
  IFrontColorValidationError,
  IFrontTypographyValidationError,
  StylesheetErrorStateType,
} from './types';
import {
  changeColorNameCreator,
  changeTypoNameCreator,
  getStylesheetSuccessCreator,
  ignoreAllColorErrorsCreator,
  ignoreAllTypoErrorsCreator,
  ignoreColorErrorCreator,
  ignoreTypoErrorCreator,
  saveStylesheetErrorCreator,
  StylesheetErrorActions,
  updateColorsValidationStateCreator,
  updateTyposValidationStateCreator,
} from 'modules/stylesheetError/actions';

const initialState = {
  colors: {},
  typos: {},
  variableNames: [],
};

// Factories
const createIFrontColorValidationErrorFromApiValidationError = (
  color: ApiValidationErrorColor,
): IFrontColorValidationError => ({
  validationState: FrontVariableErrorEnum.NOT_MODIFIED,
  ...color,
});

const createFrontTyposValidationErrorFromApiValidationError = (
  typo: ApiValidationErrorTypography,
): IFrontTypographyValidationError => ({
  validationState: FrontVariableErrorEnum.NOT_MODIFIED,
  ...typo,
});

// Validators
const getValidationStateNewVariableName = (
  newName: string,
  state: StylesheetErrorStateType,
): FrontVariableErrorEnum => {
  if (newName === '') return FrontVariableErrorEnum.NOT_MODIFIED;

  // 1. Check if the name is right formatted
  const camelCaseOrKebabCaseRegex = new RegExp('^[a-z][a-zA-Z0-9]*([-_]?[a-zA-Z0-9])*$');
  if (!camelCaseOrKebabCaseRegex.test(newName.trim()))
    return FrontVariableErrorEnum.INVALID_NAME_ERROR;

  // 2. Check with existing stylesheet name
  if (state.variableNames.includes(newName)) return FrontVariableErrorEnum.DUPLICATE_NAME_ERROR;

  // 3. Check with new variables name
  const newColorNames = Object.values(state.colors).map(color => color.data.color.name);
  const newTypoNames = Object.values(state.typos).map(typo => typo.data.typography.name);

  if ([...newColorNames, ...newTypoNames].includes(newName))
    return FrontVariableErrorEnum.DUPLICATE_NAME_ERROR;

  return FrontVariableErrorEnum.VALID;
};

// Reducer
export const stylesheetErrorReducer: Reducer<StylesheetErrorStateType> = (
  state = initialState,
  action: StylesheetErrorActions,
) => {
  switch (action.type) {
    case getType(saveStylesheetErrorCreator):
      const frontColorsValidationErrors = action.payload.colors.map(
        (color: ApiValidationErrorColor) =>
          createIFrontColorValidationErrorFromApiValidationError(color),
      );
      const frontTyposValidationErrors = action.payload.typos.map(
        (typo: ApiValidationErrorTypography) =>
          createFrontTyposValidationErrorFromApiValidationError(typo),
      );
      const frontColorsValidationErrorsMappedByUid = keyBy(
        frontColorsValidationErrors,
        (validationError: IFrontColorValidationError) => validationError.uid,
      );
      const frontTyposValidationErrorsMappedByUid = keyBy(
        frontTyposValidationErrors,
        (validationError: IFrontTypographyValidationError) => validationError.uid,
      );
      return {
        ...state,
        colors: frontColorsValidationErrorsMappedByUid,
        typos: frontTyposValidationErrorsMappedByUid,
      };
    case getType(changeColorNameCreator):
      const colorValidationState = getValidationStateNewVariableName(action.payload.newName, state);
      const colorWithNewName = { ...state.colors[action.payload.uid] };
      colorWithNewName.data.color.name = action.payload.newName;
      return {
        ...state,
        colors: {
          ...state.colors,
          [action.payload.uid]: {
            ...colorWithNewName,
            validationState: colorValidationState,
          },
        },
      };
    case getType(changeTypoNameCreator):
      const typoValidationState = getValidationStateNewVariableName(action.payload.newName, state);
      const typoWithNewName = { ...state.typos[action.payload.uid] };
      typoWithNewName.data.typography.name = action.payload.newName;
      return {
        ...state,
        typos: {
          ...state.typos,
          [action.payload.uid]: {
            ...typoWithNewName,
            validationState: typoValidationState,
          },
        },
      };
    case getType(ignoreTypoErrorCreator):
      return {
        ...state,
        typos: {
          ...state.typos,
          [action.payload.uid]: {
            ...state.typos[action.payload.uid],
            validationState: FrontVariableErrorEnum.IGNORED,
          },
        },
      };
    case getType(ignoreAllTypoErrorsCreator):
      let typoErrorsFlagAsIgnored: Record<string, IFrontTypographyValidationError> = {};
      for (const typoUid in state.typos) {
        if (!state.typos.hasOwnProperty(typoUid)) continue;
        const typo = state.typos[typoUid];

        if (typo.validationState === FrontVariableErrorEnum.SAVED) {
          typoErrorsFlagAsIgnored[typoUid] = typo;
          continue;
        }

        typoErrorsFlagAsIgnored[typoUid] = {
          ...typo,
          validationState: FrontVariableErrorEnum.IGNORED,
        };
      }

      return {
        ...state,
        typos: typoErrorsFlagAsIgnored,
      };
    case getType(ignoreAllColorErrorsCreator):
      let colorErrorsFlagAsIgnored: Record<string, IFrontColorValidationError> = {};
      for (const colorUid in state.colors) {
        if (!state.colors.hasOwnProperty(colorUid)) continue;
        const color = state.colors[colorUid];

        if (color.validationState === FrontVariableErrorEnum.SAVED) {
          colorErrorsFlagAsIgnored[colorUid] = color;
          continue;
        }

        colorErrorsFlagAsIgnored[colorUid] = {
          ...color,
          validationState: FrontVariableErrorEnum.IGNORED,
        };
      }

      return {
        ...state,
        colors: colorErrorsFlagAsIgnored,
      };
    case getType(ignoreColorErrorCreator):
      return {
        ...state,
        colors: {
          ...state.colors,
          [action.payload.uid]: {
            ...state.colors[action.payload.uid],
            validationState: FrontVariableErrorEnum.IGNORED,
          },
        },
      };
    case getType(getStylesheetSuccessCreator):
      return {
        ...state,
        variableNames: action.payload.variableNames,
      };
    case getType(updateTyposValidationStateCreator):
      const newTypoState = { ...state };
      for (const typoUid in action.payload.validationStateMap) {
        if (!newTypoState.typos.hasOwnProperty(typoUid)) continue;
        newTypoState.typos[typoUid] = {
          ...newTypoState.typos[typoUid],
          validationState: action.payload.validationStateMap[typoUid],
        };
      }
      return newTypoState;
    case getType(updateColorsValidationStateCreator):
      const newColorState = { ...state };
      for (const colorUid in action.payload.validationStateMap) {
        if (!newColorState.colors.hasOwnProperty(colorUid)) continue;
        newColorState.colors[colorUid] = {
          ...newColorState.colors[colorUid],
          validationState: action.payload.validationStateMap[colorUid],
        };
      }
      return newColorState;
    default:
      return state;
  }
};
