import { ApiExportFormatEnum, ApiLayer, ApiLayerTypeEnum } from '@overlay-plugin/types/lib/ApiType';
import { createApiLayer } from 'services/Figma/Mapper/figmaMapper';
import {
  addOpacityProperties,
  addBorderProperties,
  addConstraintProperties,
  addShadowProperties,
  addTransformProperties,
  addBorderRadiusProperties,
  addFlexChildProperties,
  addBlurProperties,
} from 'services/Figma/Mapper/styleMapper';
import {
  FigmaExportSettings,
  FigmaNode,
  hasBorders,
  hasConstraints,
  hasOpacity,
  hasEffects,
  isRectangleNode,
  FigmaComponentNode,
  FigmaFrameNode,
  FigmaGroupNode,
  FigmaInstanceNode,
} from '@overlay-plugin/types/lib/FigmaType';

const formatMappingForAPI: Record<string, ApiExportFormatEnum> = {
  PNG: ApiExportFormatEnum.PNG,
  JPG: ApiExportFormatEnum.PNG,
  SVG: ApiExportFormatEnum.SVG,
  PDF: ApiExportFormatEnum.PNG,
};

export const mapFigmaImageWithoutStylePropertiesToApiLayer = (
  figmaImage: FigmaNode,
  previousGroupPosition: { left: number; top: number },
  parentContainer?: FigmaComponentNode | FigmaFrameNode | FigmaGroupNode | FigmaInstanceNode,
  exportSettings?: ReadonlyArray<FigmaExportSettings>,
): ApiLayer => {
  const layer: ApiLayer = createApiLayer(figmaImage, previousGroupPosition, ApiLayerTypeEnum.IMAGE);

  layer.exportSetting = {
    format: exportSettings
      ? formatMappingForAPI[exportSettings[0].format]
      : ApiExportFormatEnum.PNG,
    snapshotIsWithStyleProperties: true,
    snapshotWithChildren: true,
  };

  layer.style = {
    ...layer.style,
    ...addTransformProperties(figmaImage),
    ...addFlexChildProperties(figmaImage),
    ...(hasConstraints(figmaImage) ? addConstraintProperties(figmaImage, parentContainer) : {}),
  };

  return layer;
};

export const mapFigmaImageWithStylePropertiesToApiLayer = (
  figmaImage: FigmaNode,
  previousGroupPosition: { left: number; top: number },
  parentContainer?: FigmaComponentNode | FigmaFrameNode | FigmaGroupNode | FigmaInstanceNode,
  exportSettings?: ReadonlyArray<FigmaExportSettings>,
): ApiLayer => {
  const layer: ApiLayer = createApiLayer(figmaImage, previousGroupPosition, ApiLayerTypeEnum.IMAGE);

  layer.exportSetting = {
    format:
      exportSettings && exportSettings.length > 0
        ? formatMappingForAPI[exportSettings[0].format]
        : ApiExportFormatEnum.PNG,
    snapshotIsWithStyleProperties: false,
    snapshotWithChildren: true,
  };

  layer.style = {
    ...layer.style,
    ...addTransformProperties(figmaImage),
    ...addFlexChildProperties(figmaImage),
    ...(hasOpacity(figmaImage) ? addOpacityProperties(figmaImage) : {}),
    ...(hasConstraints(figmaImage) ? addConstraintProperties(figmaImage, parentContainer) : {}),
    ...(hasBorders(figmaImage) ? addBorderProperties(figmaImage, layer.style) : {}),
    ...(isRectangleNode(figmaImage) ? addBorderRadiusProperties(figmaImage) : {}),
    ...(hasEffects(figmaImage) ? addShadowProperties(figmaImage) : {}),
    ...(hasEffects(figmaImage) ? addBlurProperties(figmaImage) : {}),
  };

  return layer;
};

export const mapFigmaSVGToApiLayer = (
  figmaImage: FigmaNode,
  previousGroupPosition: { left: number; top: number },
  parentContainer?: FigmaComponentNode | FigmaFrameNode | FigmaGroupNode | FigmaInstanceNode,
): ApiLayer => {
  const layer: ApiLayer = createApiLayer(figmaImage, previousGroupPosition, ApiLayerTypeEnum.IMAGE);
  layer.exportSetting = {
    format: ApiExportFormatEnum.SVG,
    snapshotIsWithStyleProperties: true,
    snapshotWithChildren: true,
  };

  layer.style = {
    ...layer.style,
    ...addTransformProperties(figmaImage),
    ...addFlexChildProperties(figmaImage),
    ...(hasConstraints(figmaImage) ? addConstraintProperties(figmaImage, parentContainer) : {}),
  };

  return layer;
};
