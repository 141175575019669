import {
  SketchFrame,
  SketchGeneralLayer,
  SketchShapeTypeEnum,
  SketchTypeEnum,
} from '@overlay-plugin/types/lib/SketchType';
import { ApiLayer, ApiLayerTypeEnum, ApiBaseApiStyle } from '@overlay-plugin/types/lib/ApiType';

import { mapSketchTextToAPILayer, mapSketchTextWithAttributesToAPILayer } from './textMapper';
import {
  mapSketchImageWithStylePropertiesToApiLayer,
  mapSketchImageWithoutStylePropertiesToApiLayer,
  mapSketchSVGToApiLayer,
} from './imageMapper';
import { mapSketchContainerToAPILayer } from './containerMapper';
import { mapSketchContentToAPILayer } from './contentMapper';

export const convertSketchTypeToApiLayerType = (sketchType: SketchTypeEnum): ApiLayerTypeEnum => {
  switch (sketchType) {
    case SketchTypeEnum.IMAGE:
      return ApiLayerTypeEnum.IMAGE;
    case SketchTypeEnum.GROUP:
      return ApiLayerTypeEnum.GROUP;
    case SketchTypeEnum.SHAPE_PATH:
      return ApiLayerTypeEnum.SHAPE;
    case SketchTypeEnum.SHAPE:
      return ApiLayerTypeEnum.SHAPE;
    case SketchTypeEnum.SYMBOL_INSTANCE:
      return ApiLayerTypeEnum.SYMBOL_INSTANCE;
    case SketchTypeEnum.SYMBOL_MASTER:
      return ApiLayerTypeEnum.SYMBOL_MASTER;
    case SketchTypeEnum.TEXT:
      return ApiLayerTypeEnum.TEXT;
    default:
      return ApiLayerTypeEnum.SHAPE;
  }
};

export const createApiLayer = (
  sketchLayer: SketchGeneralLayer,
  apiLayerType?: ApiLayerTypeEnum,
): ApiLayer => ({
  name: sketchLayer.name,
  type: apiLayerType ? apiLayerType : convertSketchTypeToApiLayerType(sketchLayer.type),
  sketchId: sketchLayer.id,
  asset: null,
  style: {
    ...mapFrameToStyle(sketchLayer.frame),
    ...sketchLayer.constraints,
  },
  colorStyles: [],
  typographyStyles: [],
});

export const mapSketchLayerToAPILayer = (sketchLayer: SketchGeneralLayer) => {
  const componentIsFlaggedAsExportable = sketchLayer.exportFormats.length > 0;

  if (
    componentIsFlaggedAsExportable &&
    (sketchLayer.exportFormats[0].fileFormat === 'svg' ||
      sketchLayer.type !== SketchTypeEnum.SHAPE_PATH ||
      (sketchLayer.shapeType !== SketchShapeTypeEnum.RECTANGLE &&
        sketchLayer.shapeType !== SketchShapeTypeEnum.OVAL))
  ) {
    return mapSketchImageWithoutStylePropertiesToApiLayer(sketchLayer);
  }

  switch (sketchLayer.type) {
    case SketchTypeEnum.SYMBOL_MASTER:
    case SketchTypeEnum.SYMBOL_INSTANCE:
    case SketchTypeEnum.GROUP:
      return mapSketchContainerToAPILayer(sketchLayer);
    case SketchTypeEnum.TEXT:
      return sketchLayer.textAttributes
        ? mapSketchTextWithAttributesToAPILayer(sketchLayer)
        : mapSketchTextToAPILayer(sketchLayer);
    case SketchTypeEnum.IMAGE:
      return mapSketchImageWithStylePropertiesToApiLayer(sketchLayer);
    case SketchTypeEnum.SHAPE:
      return mapSketchImageWithStylePropertiesToApiLayer(sketchLayer);
    case SketchTypeEnum.SHAPE_PATH:
      const sketchStyle = sketchLayer.style;
      switch (sketchLayer.shapeType) {
        case SketchShapeTypeEnum.OVAL:
          sketchStyle.borderRadius.topLeft = '50%';
          sketchStyle.borderRadius.topRight = '50%';
          sketchStyle.borderRadius.bottomRight = '50%';
          sketchStyle.borderRadius.bottomLeft = '50%';
          break;
        case SketchShapeTypeEnum.CUSTOM:
        case SketchShapeTypeEnum.STAR:
        case SketchShapeTypeEnum.POLYGON:
          return mapSketchSVGToApiLayer(sketchLayer);
        default:
          break;
      }
      const isLayerWithAnUniqueImageBackground =
        sketchStyle.fills &&
        sketchStyle.fills.length === 1 &&
        sketchStyle.fills[0].fillType === 'Pattern';

      if (isLayerWithAnUniqueImageBackground)
        return mapSketchImageWithStylePropertiesToApiLayer(sketchLayer);

      return mapSketchContentToAPILayer(sketchLayer);

    default:
      // tslint:disable no-console
      console.log(`Node of type ${sketchLayer} not handled`);
      return createApiLayer(sketchLayer);
  }
};

export const mapFrameToStyle = (frame: SketchFrame): ApiBaseApiStyle => ({
  height: Math.round((frame.height + Number.EPSILON) * 100) / 100,
  width: Math.round((frame.width + Number.EPSILON) * 100) / 100,
  top: Math.round((frame.y + Number.EPSILON) * 100) / 100,
  left: Math.round((frame.x + Number.EPSILON) * 100) / 100,
});
