export const convertToKebabCase = (stringToConvert: string) => {
  return stringToConvert
    .trim()
    .replace(/\(|\/|\.|-|_|\)/g, ' ')
    .replace(/[^A-Za-z0-9\-\s]/g, '')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase();
};

const rgbaRegex = /^rgba\((\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d*(?:\.\d+)?)\)$/;

export const rgba2hexWithoutAlpha = (rgba: string) => {
  const rgbaMatch = rgba.match(rgbaRegex);
  return rgbaMatch && rgbaMatch.length === 5
    ? '#' +
        ('0' + parseInt(rgbaMatch[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgbaMatch[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgbaMatch[3], 10).toString(16)).slice(-2)
    : '';
};

export const rgba2hexWithAlpha = (rgba: string) => {
  const rgbaMatch = rgba.match(rgbaRegex);
  return rgbaMatch && rgbaMatch.length === 5
    ? '#' +
        ('0' + parseInt(rgbaMatch[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgbaMatch[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgbaMatch[3], 10).toString(16)).slice(-2) +
        ('0' + (parseFloat(rgbaMatch[4]) * 255).toString(16)).slice(-2)
    : '';
};

export const getAlphaPercentageFromRgbaColor = (rgba: string): number => {
  const rgbaMatch = rgba.match(rgbaRegex);
  return rgbaMatch && rgbaMatch.length === 5 ? Math.round(parseFloat(rgbaMatch[4]) * 100) : 100;
};
