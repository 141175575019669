import './reset.css';

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import SketchBridge from 'components/HightOrderComponents/SketchBridge';
import FigmaBridge from 'components/HightOrderComponents/FigmaBridge';
import { Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './redux/store';
import Routes from './routes';
import ReactGA from 'react-ga';
import { checkVersionCreator } from 'modules/abstractDesignTool';
import ErrorToasterController from 'components/HightOrderComponents/ErrorToasterController';
import ErrorToaster from 'components/Atoms/ErrorToaster';

import {
  DefaultToastContainer,
  ToastContainerProps,
  ToastProvider,
} from 'react-toast-notifications';

ReactGA.initialize(
  process.env.REACT_APP_GOOGLE_ANALYTICS_ID
    ? process.env.REACT_APP_GOOGLE_ANALYTICS_ID
    : 'UA-139719671-4',
);
ReactGA.pageview(window.location.pathname + window.location.search);
history.listen(location => {
  ReactGA.pageview(window.location.pathname + window.location.search);
});

const style = {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
};

const initialState = {};
const { store, persistor } = configureStore(initialState);

export const ZIndexModifiedToastContainer = (props: ToastContainerProps) => (
  // @ts-ignore
  <DefaultToastContainer {...props} style={{ zIndex: 1400 }} />
);

class App extends Component {
  async componentDidMount() {
    store.dispatch(checkVersionCreator());
  }

  render() {
    return (
      <ToastProvider
        components={{ ToastContainer: ZIndexModifiedToastContainer, Toast: ErrorToaster }}
        autoDismiss
        autoDismissTimeout={4000}
        placement={'bottom-center'}
      >
        <Provider store={store}>
          <div style={style.root as any}>
            <PersistGate loading={null} persistor={persistor}>
              <ConnectedRouter history={history}>
                <Switch>
                  <Routes />
                </Switch>
              </ConnectedRouter>
            </PersistGate>
            <ErrorToasterController />
            <SketchBridge store={store} />
            <FigmaBridge store={store} />
          </div>
        </Provider>
      </ToastProvider>
    );
  }
}

export default App;
