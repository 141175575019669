import { denormalize, normalize, NormalizedSchema, schema } from 'normalizr';
import { ProjectType } from 'modules/projects/types';
import { Warning } from 'types/warning';
import {
  ApiComponent,
  ApiComponentSet,
  ApiLayer,
  NormalizedApiComponent,
  NormalizedApiComponentSet,
  NormalizedApiLayer,
} from '@overlay-plugin/types/lib/ApiType';
import { NormalizedTeamType, TeamType } from 'modules/teams/types';

type NormalizedWarning = {
  entities: {
    warning: { [id: string]: Warning };
  };
};

const warning = new schema.Entity('warning');

const projectSchema = new schema.Entity(
  'project',
  {},
  {
    idAttribute: 'uuid',
  },
);

const teamSchema = new schema.Entity(
  'team',
  {},
  {
    idAttribute: 'uuid',
  },
);

const apiLayerSchema = new schema.Entity(
  'layer',
  {},
  {
    idAttribute: 'sketchId',
  },
);
const apiLayersSchema = new schema.Array(apiLayerSchema);
apiLayerSchema.define({ children: apiLayersSchema });

const apiComponentSchema = new schema.Entity(
  'component',
  {
    rootLayer: apiLayerSchema,
  },
  {
    idAttribute: 'sketchId',
  },
);

const apiComponentSetSchema = new schema.Entity(
  'componentSet',
  {
    rootLayer: apiLayerSchema,
    children: [apiComponentSchema],
    defaultComponent: apiComponentSchema,
  },
  {
    idAttribute: 'designToolId',
  },
);

export const normalizeTeams = (
  teams: TeamType[],
): NormalizedSchema<
  {
    team: Record<string, NormalizedTeamType>;
  },
  any
> => normalize(teams, [teamSchema]);

export const normalizedProjects = (
  data: ProjectType[],
): NormalizedSchema<
  {
    project: Record<string, ProjectType>;
  },
  any
> => normalize(data, [projectSchema]);

export const normalizedWarning = (data: Warning[]): NormalizedWarning => normalize(data, [warning]);

export const normalizedAPILayer = (
  data: ApiLayer,
): {
  entities: {
    layer: Record<string, NormalizedApiLayer>;
  };
} => normalize(data, apiLayerSchema);

export const normalizedAPIComponent = (
  data: ApiComponent,
): {
  entities: {
    component: Record<string, NormalizedApiComponent>;
  };
} => normalize(data, apiComponentSchema);

export const normalizedAPIComponentSet = (
  data: ApiComponentSet,
): {
  entities: {
    component: Record<string, NormalizedApiComponent>;
    componentSet: Record<string, NormalizedApiComponentSet>;
  };
} => normalize(data, apiComponentSetSchema);

export const denormalizedProject = (
  projectData: ProjectType,
  projectMap: Record<string, ProjectType>,
): ProjectType =>
  denormalize(projectData, projectSchema, {
    project: projectMap,
  });

export const denormalizedApiLayer = (
  layerData: NormalizedApiLayer,
  layerMap: Record<string, NormalizedApiLayer>,
): ApiLayer => denormalize(layerData, apiLayerSchema, { layer: layerMap });

export const denormalizeTeams = (
  teamsNormalized: NormalizedTeamType[],
  teamMap: Record<string, NormalizedTeamType>,
): {
  team: TeamType[];
} =>
  denormalize({ team: teamsNormalized }, [teamSchema], {
    team: teamMap,
  });

export const denormalizedApiComponent = (
  componentData: NormalizedApiComponent,
  componentMap: Record<string, NormalizedApiComponent>,
  layerMap: Record<string, NormalizedApiLayer>,
): ApiComponent =>
  denormalize(componentData, apiComponentSchema, {
    component: componentMap,
    layer: layerMap,
  });

export const denormalizedApiComponentSet = (
  componentSetData: NormalizedApiComponentSet,
  componentSetMap: Record<string, NormalizedApiComponentSet>,
  componentMap: Record<string, NormalizedApiComponent>,
  layerMap: Record<string, NormalizedApiLayer>,
): ApiComponentSet =>
  denormalize(componentSetData, apiComponentSetSchema, {
    componentSet: componentSetMap,
    component: componentMap,
    layer: layerMap,
  });
