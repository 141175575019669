import {
  SketchConstraints,
  SketchExportFormat,
  SketchFrame,
  SketchShape,
  SketchShapeTypeEnum,
  SketchStyle,
  SketchTypeEnum,
} from '@overlay-plugin/types/lib/SketchType';

export const createSketchShapePath = (
  id: string,
  name: string,
  frame: SketchFrame,
  shapeType: SketchShapeTypeEnum,
  constraints: SketchConstraints,
  exportFormats: SketchExportFormat[],
  hasClippingMask: boolean,
  style: SketchStyle,
): SketchShape => ({
  id,
  name,
  shapeType,
  type: SketchTypeEnum.SHAPE_PATH,
  colorSharedStyles: [],
  textSharedStyle: null,
  constraints,
  exportFormats,
  hasClippingMask,
  frame,
  style,
  transform: {
    rotation: 0,
    flippedVertically: false,
    flippedHorizontally: false,
  },
});
