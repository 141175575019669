import { SketchGeneralLayer } from '@overlay-plugin/types/lib/SketchType';
import { optimize as maskOptimize } from './maskOptimizer';
import { optimize as iconOptimize } from './iconOptimizer';
import { optimize as componentExportableOptimize } from './componentExportableOptimizer';

export const optimize = (sketchNode: SketchGeneralLayer): SketchGeneralLayer => {
  sketchNode = componentExportableOptimize(sketchNode);
  sketchNode = maskOptimize(sketchNode);
  return iconOptimize(sketchNode);
};
