import { isSymbolInstance, SketchContainer } from '@overlay-plugin/types/lib/SketchType';
import { ApiLayer, ApiLayerTypeEnum } from '@overlay-plugin/types/lib/ApiType';
import { createApiLayer, mapSketchLayerToAPILayer } from 'services/Sketch/Mapper/sketchMapper';
import { isSingleBorder, setSingleBorder } from 'services/API/singleBorderOptimizer';
import { addOpacityProperties, addShadowProperties } from 'services/Sketch/Mapper/styleMapper';
import { recomputeChildrenPositionAccordingToBorder } from 'services/API/borderOptimizer';

export const mapSketchContainerToAPILayer = (sketchLayer: SketchContainer): ApiLayer => {
  const layer: ApiLayer = createApiLayer(sketchLayer);

  if (isSymbolInstance(sketchLayer)) {
    layer.componentDependencyId = sketchLayer.symbolId;
  }

  if (!(sketchLayer.layers.length > 0)) {
    return layer;
  }

  if (layer.type === ApiLayerTypeEnum.GROUP) {
    layer.style = {
      ...layer.style,
      ...addOpacityProperties(sketchLayer.style),
      ...addShadowProperties(sketchLayer.style),
    };
  }

  let totalWidthToRemove = 0;
  let totalHeightToRemove = 0;

  const layerChildren = sketchLayer.layers.reduce((layerChildren: ApiLayer[], child) => {
    const childLayer = mapSketchLayerToAPILayer(child);

    if (!childLayer) {
      return layerChildren;
    }

    const borderToSet = isSingleBorder(childLayer, layer);

    if (borderToSet) {
      let { widthToRemove, heightToRemove } = setSingleBorder(childLayer.style, layer, borderToSet);
      totalWidthToRemove += widthToRemove;
      totalHeightToRemove += heightToRemove;
    } else {
      layerChildren.push(childLayer);
    }

    return layerChildren;
  }, []);

  // We remove width after to allow multiple single borders
  layer.style.width -= totalWidthToRemove;
  layer.style.height -= totalHeightToRemove;

  if (layerChildren) {
    layer.children = layerChildren;
  }

  // We re-compute top / left / height / width
  recomputeChildrenPositionAccordingToBorder(layer);

  return layer;
};
